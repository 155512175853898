import { Layout } from 'antd';
import { FC, PropsWithChildren } from 'react';

import { useScrollToTopOnRouteChange } from '../../common';
import { RouteData, useAppQueryEffect } from '../../utils';
import AuthHeader from './auth-header.component';
import { useInitStore } from '../../stores';
import Init from '../../routes/app/init/init.component';

const { Content } = Layout;

export const AuthLayout: FC<
  PropsWithChildren<{ withInit?: boolean; routeData: RouteData }>
> = ({ withInit, routeData, children }) => {
  useAppQueryEffect();
  useScrollToTopOnRouteChange();

  const initialized = useInitStore((state) => state.initialized);

  let layoutChildren = children;

  if (withInit && !initialized) layoutChildren = <Init />;

  return (
    <Layout>
      <Layout>
        <AuthHeader
          width="100%"
          maxWidth="100%"
          routeData={routeData}
          backPath="/"
        />

        <Content className="layout-content-offset">{layoutChildren}</Content>
      </Layout>
    </Layout>
  );
};
