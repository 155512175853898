import { FC, useEffect } from 'react';
import { Space } from 'antd';
import { PlayCircleFilled } from '@ant-design/icons';

import {
  ChatMediaRequest,
  MediaType,
  MessageMedia,
  useAPIRequest,
} from '../../../../../api';
import { If, Loader } from '../../../../../common';

import './media-gallery.component.css';
import { useAuthStore } from '../../../../../stores';

const MediaGallery: FC<{
  chatId?: string;
  setViewedMedia?: (value: MessageMedia) => void;
}> = ({ chatId, setViewedMedia }) => {
  const token = useAuthStore((state) => state.token);

  const { request, response, error, isLoading } = useAPIRequest<{
    data: MessageMedia[];
  }>(() => ChatMediaRequest(chatId!), { immediate: false });

  useEffect(() => {
    if (token) request();
  }, [token]);

  return (
    <Loader isLoading={isLoading} error={error} style={{ width: '100%' }}>
      <div className="media-gallery-container">
        <If condition={token && response && response.data.length > 0}>
          <Space
            direction="horizontal"
            size="middle"
            wrap
            className="media-gallery-wrapper"
          >
            {response?.data?.map((media) => {
              return (
                <div
                  className="media-wrapper"
                  onClick={() => setViewedMedia?.(media)}
                  key={media.id}
                >
                  <div
                    style={{ backgroundImage: `url('${media.previewUrl}')` }}
                    className="media"
                  />

                  <If condition={media.type === MediaType.Video}>
                    <PlayCircleFilled
                      className="center icon"
                      style={{ fontSize: '50px' }}
                    />

                    <div className="top-right unlock duration">
                      {media.duration}
                    </div>
                  </If>
                </div>
              );
            })}
          </Space>
        </If>

        <If condition={!token || (response && response.data.length === 0)}>
          <div style={{ color: 'var(--grey6)', marginTop: '20px' }}>
            No media available
          </div>
        </If>
      </div>
    </Loader>
  );
};

export default MediaGallery;
