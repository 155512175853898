import { Input } from 'antd';
import Icon from '@ant-design/icons';
import { useCallback, useEffect, useRef, useState } from 'react';

import { MicrophoneSvg, SendSvg, StopSvg } from './chat-input-svg.component';
import { PermissionStatus } from './permission-status.enum';
import { useSpeechRecorder } from './use-speech-recorder.hook';
import { AuthDrawerState, If } from '../../../../common';
import { useAuthStore, useChatStore, useMiscStore } from '../../../../stores';
import { cssObjectToString } from '../../../../utils';

import './chat-input.component.css';
import { MenuSvg } from '../../../layout-header-svg.component';

const ChatInput: React.FC<{
  onSubmitText?: (value: string) => void;
  onSubmitAudio?: (base64: string, mimeType: string) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}> = ({ onSubmitText, onSubmitAudio, disabled = false, style }) => {
  const [value, setValue] = useState<string>('');
  const ref = useRef<any>();

  const token = useAuthStore((state) => state.token);
  const setAuthOpen = useMiscStore((state) => state.setAuthOpen);
  const setChatMenuOpen = useChatStore((state) => state.setChatMenuOpen);

  const submitText = useCallback(
    (text: string) => {
      if (text) {
        setValue('');
        onSubmitText?.(text);
      }
    },
    [onSubmitText],
  );

  const submit = useCallback(() => {
    if (token) submitText(value);
    else setAuthOpen(true, AuthDrawerState.SignUp);
  }, [value, submitText, token]);

  const onInputClick = useCallback(() => {
    if (!token) setAuthOpen(true, AuthDrawerState.SignUp);
  }, [token]);

  const onSettingsClick = useCallback(() => {
    if (disabled) return;

    if (token) setChatMenuOpen(true);
    else setAuthOpen(true, AuthDrawerState.SignUp);
  }, [token, disabled]);

  const { toggleRecording, recording, micPermissionStatus } =
    useSpeechRecorder(onSubmitAudio);

  useEffect(() => {
    function onKeyUp({ keyCode }: { keyCode: number }) {
      if (keyCode === 13) submit();
    }

    window.addEventListener('keyup', onKeyUp);

    return () => window.removeEventListener('keyup', onKeyUp);
  }, [submit]);

  useEffect(() => {
    if (!disabled && !recording) ref.current?.focus();
  }, [disabled, recording]);

  return (
    <div className="chat-input-container" style={style}>
      <Icon
        component={MenuSvg}
        style={{
          color: disabled ? 'var(--grey3)' : 'var(--white)',
          marginRight: '10px',
          width: '50px',
        }}
        disabled={disabled}
        onClick={onSettingsClick}
      />

      <Input
        placeholder={disabled || recording ? '' : 'Type a message'}
        className="chat-input rounded"
        status={recording ? 'error' : undefined}
        value={value}
        maxLength={150}
        onChange={(e) => setValue(e.target.value)}
        onClick={onInputClick}
        disabled={disabled || recording}
        style={{ marginRight: '10px' }}
        ref={ref}
      />

      <>
        <If condition={value !== '' && !recording}>
          <button
            className="chat-button-circle active"
            onClick={submit}
            disabled={disabled}
            style={{ visibility: recording ? 'hidden' : 'visible' }}
          >
            <Icon component={SendSvg} />
          </button>
        </If>

        <If condition={value === '' || recording}>
          <button
            className={cssObjectToString({
              'chat-button-circle': true,
              active: recording,
            })}
            onClick={toggleRecording}
            disabled={disabled}
            style={{
              backgroundColor:
                micPermissionStatus === PermissionStatus.Blocked
                  ? '#F2F2F7'
                  : undefined,
            }}
          >
            <Icon
              component={recording ? StopSvg : MicrophoneSvg}
              style={{ position: 'relative', top: '1px' }}
            />
          </button>
        </If>
      </>
    </div>
  );
};

export default ChatInput;
