import { Drawer } from 'antd';
import Icon from '@ant-design/icons';
import { FC } from 'react';

import { useLayoutDisplay, CloseSvg, Features } from '../../../../../common';
import { useChatStore } from '../../../../../stores';

import './pricing-drawer.component.css';

export const PricingDrawer: FC = () => {
  const { isMobile } = useLayoutDisplay();

  const { pricingMenuOpen, setPricingMenuOpen } = useChatStore();

  const close = () => setPricingMenuOpen(false);

  return (
    <Drawer
      open={pricingMenuOpen}
      placement={isMobile ? 'bottom' : 'right'}
      closeIcon={null}
      onClose={close}
      height={340}
    >
      <div className="pricing-drawer-container">
        <div className="button-circle" onClick={close}>
          <Icon component={CloseSvg} />
        </div>

        <div className="title">Pricing</div>

        <Features
          style={{
            padding: '0px 20px 20px 20px',
            justifyContent: 'center',
          }}
        />
      </div>
    </Drawer>
  );
};
