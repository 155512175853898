import { Form, Input, Select } from 'antd';
import { countries } from 'countries-list';

import { StepProps } from '../step-props.interface';
import { SelectAutoClose } from '../../../../../common';

import '../../../../../common/form.css';

const { Option } = Select;

const countriesNames = Object.entries(countries).map(
  ([, country]) => country.name,
);

const PersonalInformation: React.FC<StepProps> = () => {
  return (
    <>
      <Form.Item
        name="firstName"
        label="Legal First Name"
        rules={[
          { required: true, message: 'Legal first name is required' },
          {
            max: 30,
            message: 'Legal first name must be maximum 30 characters',
          },
        ]}
      >
        <Input placeholder="Jane" />
      </Form.Item>

      <Form.Item
        name="lastName"
        label="Legal Last Name"
        rules={[
          { required: true, message: 'Legal last name is required' },
          {
            max: 30,
            message: 'Last last name must be maximum 30 characters',
          },
        ]}
      >
        <Input placeholder="Doe" />
      </Form.Item>

      <Form.Item
        name="country"
        label="Country of your legal residence"
        rules={[{ required: true, message: 'Country is required' }]}
      >
        <SelectAutoClose placeholder="Select country" showSearch>
          {countriesNames.map((country) => (
            <Option value={country} key={country}>
              {country}
            </Option>
          ))}
        </SelectAutoClose>
      </Form.Item>
    </>
  );
};

export default PersonalInformation;
