import { Avatar, Space } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { ProductParams } from '../../../../api';
import { limitTextLength } from '../../../../utils';

const ProductItem: React.FC<{ characterId: string; data: ProductParams }> = ({
  characterId,
  data,
}) => {
  return (
    <Link
      to={`/creator/character/${characterId}/product/${data.id}`}
      key={data.id}
      style={{ maxWidth: '100%' }}
    >
      <Space size="middle" direction="vertical" align="center">
        <Avatar
          shape="square"
          size={140}
          src={data.thumbnailUrl}
          icon={<QuestionOutlined />}
        />

        <div style={{ color: 'var(--grey6)' }}>
          {limitTextLength(data.name, 18)}
        </div>
      </Space>
    </Link>
  );
};

export default ProductItem;
