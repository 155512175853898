import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Space } from 'antd';

import {
  CharacterParams,
  useAPIRequest,
  ProductParams,
  ProductListRequest,
  ReadCharacterRequest,
} from '../../../../api';
import { Loader } from '../../../../common';
import CreateProduct from './create-product.component';
import ProductItem from './product-item.component';

const Products: React.FC = () => {
  const params = useParams();

  const { response: characterResponse } = useAPIRequest<CharacterParams>(() =>
    ReadCharacterRequest(params.characterId!),
  );

  const { request, response, error, isLoading } = useAPIRequest<{
    data: ProductParams[];
  }>(ProductListRequest, { immediate: false });

  useEffect(() => {
    if (characterResponse) request(characterResponse.id);
  }, [characterResponse]);

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', padding: '20px' }}
      size={0}
    >
      <Loader error={error} isLoading={isLoading}>
        <CreateProduct
          characterId={characterResponse?.id}
          style={{ marginBottom: '20px' }}
        />

        <Space size="large" style={{ width: '100%' }} wrap>
          {response?.data?.map((item) => (
            <ProductItem
              characterId={params.characterId!}
              data={item}
              key={item.id}
            />
          ))}
        </Space>
      </Loader>
    </Space>
  );
};

export default Products;
