import { AxiosRequestConfig } from 'axios';

export const TransloaditAssemblyListRequest = (
  characterId: string,
): AxiosRequestConfig => {
  return {
    url: `v1/media/transloadit/assembly/list`,
    method: 'get',
    params: { characterId },
  };
};
