import { Navigate } from 'react-router-dom';

import { RouteData } from './interfaces';

// keeps routes but navigates them to default path (* is a route that navigates to default path, can be found in RoutesRenderer)
// in order to not be matched by accident by handle route which accepts anything as first param
// for example, the handle route can match /signin after already authenticated, and subsequently call setDefaultPath('/')
// which will override the defaultPath that was saved before signing in
export function navigateToDefaultPath(routes: RouteData[]): RouteData[] {
  return routes.map((route) => ({
    ...route,
    component: () => <Navigate to="*" replace />,
  }));
}
