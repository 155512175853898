import { Message } from './message.interface';

export function transformMessageResponse(response: string) {
  if (response.includes('"error"')) return JSON.parse(response);

  const { message, balance }: { message: Message; balance: number } =
    JSON.parse(response);

  return {
    message: { ...message, sent: new Date(message.sent) },
    balance,
  };
}
