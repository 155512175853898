import { Form } from 'antd';

import { If, WideButton } from '../../../../common';
import { StepProps } from './step-props.interface';

import './creator-onboarding.component.css';
import '../../../../common/form.css';

const CreatorOnboardingSteps: React.FC<StepProps> = (props) => {
  const {
    form,
    response,
    isLoading,
    submit,
    currentStepObject,
    previous,
    hasPrevious,
    hasNext,
  } = props;

  const StepComponent = currentStepObject.component;

  return (
    <>
      <div className="title">{currentStepObject.title}</div>

      <If condition={currentStepObject.description}>
        <div className="description" style={{ marginTop: '8px' }}>
          {currentStepObject.description}
        </div>
      </If>

      <div className="form" style={{ width: '100%', padding: 0 }}>
        <Form
          form={form}
          onFinish={
            currentStepObject.staticSubmitValues
              ? () => submit(currentStepObject.staticSubmitValues)
              : submit
          }
          initialValues={response}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          disabled={isLoading}
          style={{ width: '100%' }}
        >
          <StepComponent {...props} />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '16px',
            }}
          >
            {hasPrevious && (
              <WideButton
                label="Previous"
                light
                disabled={isLoading}
                onClick={previous}
                style={{ marginRight: hasNext ? '16px' : undefined }}
              />
            )}

            {hasNext && (
              <WideButton
                label={currentStepObject.nextText || 'Next'}
                accent
                disabled={isLoading}
                onClick={form.submit}
              />
            )}
          </div>
        </Form>
      </div>
    </>
  );
};

export default CreatorOnboardingSteps;
