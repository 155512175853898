import { FC } from 'react';
import { Space } from 'antd';

import { Feature } from './feature.component';
import { PictureSvg, VideoSvg } from './feature-svg.component';
import { CallSvg, TextSvg, VoiceSvg } from '../common-svg.component';

import './features.component.css';

export const Features: FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <Space
      direction="horizontal"
      size={16}
      className="features-container"
      style={style}
      wrap
    >
      <Feature icon={TextSvg} label="Text Messages" price="$0.1 / message" />

      <Feature icon={VoiceSvg} label="Voice Messages" price="$0.2 / message" />

      <Feature icon={CallSvg} label="Voice Calls" price="$1 / minute" />

      <Feature icon={PictureSvg} label="Pictures" price="Pay per view" />

      <Feature icon={VideoSvg} label="Videos" price="Pay per view" />
    </Space>
  );
};
