import { AxiosRequestConfig } from 'axios';

import { ResponseType } from './response-type.enum';

export const UpdateResponseType = (value: ResponseType): AxiosRequestConfig => {
  return {
    url: 'v1/user/response-type',
    method: 'put',
    data: { responseType: value },
  };
};
