import { useState } from 'react';
import { Space } from 'antd';

import FAQItem from './faq-item.component';

import './faq.component.css';

const items: { question: string; answer: string }[] = [
  {
    question: 'What is Swoons AI?',
    answer:
      'Swoons AI is an innovative AI platform that allows fans to interact with ultra-realistic AI clones of well-known celebrities and content creators. We use proprietary cutting-edge technology which allows your AI clone to interact with millions of fans 24/7 in 30+ languages.',
  },
  {
    question: 'What is an AI clone?',
    answer:
      'Your AI clone is a digital version of you which closely resembles your personality and voice. Your AI clone can independently exchange messages, pictures, videos, and calls with your fans without you lifting a finger.',
  },
  {
    question: 'Who can become a creator on Swoons?',
    answer: `Swoons is a home for all creators - you could be an influencer, athlete, artist, and so much more. We've created a safe space where you can express yourself freely.`,
  },
  {
    question: 'How much money can my AI clone make me?',
    answer:
      'Your earnings on Swoons are unlimited - the more you promote your AI clone, the more you will earn. You take home 70% of your earnings.',
  },
];

const FAQ: React.FC<{
  style?: React.CSSProperties;
}> = ({ style }) => {
  const [openIndex, setOpenIndex] = useState<number>(-1);

  const toggle = (index: number) => {
    if (openIndex !== index) setOpenIndex(index);
    else setOpenIndex(-1);
  };

  return (
    <div className="faq-container" style={style}>
      <div className="title" style={{ marginBottom: '10px' }}>
        FAQ
      </div>

      <div className="description" style={{ marginBottom: '20px' }}>
        Answers to Your Burning Questions
      </div>

      <Space
        direction="vertical"
        size="middle"
        style={{ width: '90%', maxWidth: '672px' }}
      >
        {items.map((item, index) => (
          <FAQItem
            question={item.question}
            answer={item.answer}
            isOpen={index === openIndex}
            toggle={() => toggle(index)}
            key={`faq-item-${index}`}
          />
        ))}
      </Space>
    </div>
  );
};

export default FAQ;
