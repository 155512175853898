import { AxiosRequestConfig } from 'axios';

import { CreatorOnboardingParams } from './creator-onboarding-params.interface';

export const UpdateCreatorOnboardingRequest = (
  params: CreatorOnboardingParams,
): AxiosRequestConfig => {
  return {
    url: 'v1/creator/onboarding',
    method: 'put',
    data: params,
  };
};
