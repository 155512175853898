import { useCallback, useEffect, useState } from 'react';
import { Avatar } from 'antd';
import Icon from '@ant-design/icons';

import {
  useAPIRequest,
  MessageMedia,
  MessagesParams,
  MessagesRequest,
  Message,
  CharacterChatParams,
} from '../../../../api';
import { If, Loader } from '../../../../common';
import MessageList from '../../character/chat/message-list/message-list.component';
import { BackSvg } from '../../../../layouts/layout-header-svg.component';

import './character-chat.component.css';

const PER_PAGE = 50;

const CharacterChat: React.FC<{
  chat?: CharacterChatParams;
  viewMedia?: (media: MessageMedia) => void;
  resetChat?: () => void;
  style?: React.CSSProperties;
}> = ({ chat, viewMedia, resetChat, style }) => {
  const [currentChatId, setCurrentChatId] = useState<string>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [firstPageLoaded, setFirstPageLoaded] = useState<boolean>(false);
  const [paginationToken, setPaginationToken] = useState<string>();

  const { request, isLoading, error } = useAPIRequest<MessagesParams>(
    () => MessagesRequest(chat!.id, PER_PAGE, paginationToken),
    {
      immediate: false,
      onSuccess: (result) => {
        setMessages([...result.messages, ...messages]);
        setFirstPageLoaded(true);
        setPaginationToken(result.paginationToken);
      },
    },
  );

  const loadMoreMessages = useCallback(() => {
    if (!isLoading && chat?.id && (!firstPageLoaded || paginationToken)) {
      request();

      return messages?.[0]?.id; // returns top message before more message are loaded
    }
  }, [isLoading, chat, firstPageLoaded, paginationToken]);

  useEffect(() => {
    if (chat?.id !== currentChatId) {
      if (chat) {
        if (firstPageLoaded) {
          // reset
          setFirstPageLoaded(false);
          setPaginationToken(undefined);
          setMessages([]);
        } else {
          // makes sure to only request on first load or after the above reset has been processed
          request();
          setCurrentChatId(chat?.id);
        }
      } else {
        // reset
        setFirstPageLoaded(false);
        setPaginationToken(undefined);
        setMessages([]);
        setCurrentChatId(undefined);
      }
    }
  }, [chat, currentChatId, firstPageLoaded]);

  return (
    <div style={style}>
      <If condition={!!chat}>
        <div
          className="character-chat-details-wrapper"
          style={{ marginBottom: '10px' }}
        >
          <div className="button-circle" style={{ marginRight: '10px' }}>
            <Icon component={BackSvg} onClick={resetChat} />
          </div>

          <Avatar src={chat?.user.avatarUrl} style={{ marginRight: '10px' }} />

          <div className="character-chat-details">
            <div>{chat?.user.name}</div>
            <div style={{ color: 'var(--grey6)' }}>{chat?.user.email}</div>
          </div>
        </div>
      </If>

      <Loader error={error} isLoading={isLoading}>
        <MessageList
          messages={messages}
          loadMoreMessages={loadMoreMessages}
          isLoadingMessages={isLoading}
          unlockMessageMedia={(item) => viewMedia?.(item.media!)}
          viewMedia={(item) => viewMedia?.(item.media!)}
          alwaysShowText
          style={{ height: 'calc(100svh - 96px - 96px - 50px)' }}
        />
      </Loader>
    </div>
  );
};

export default CharacterChat;
