import { FC, useEffect, useRef } from 'react';

import { MessageMedia } from '../../../../../api';
import { useFitSize } from '../../../../../common';

const Video: FC<{ media?: MessageMedia }> = ({ media }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const { width, height } = useFitSize({
    width: media?.width || 0,
    height: media?.height || 0,
  });

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
    }
  }, [videoRef]);

  return (
    <video
      autoPlay
      controls
      controlsList="nodownload"
      ref={videoRef}
      style={{ width: width + 'px', height: height + 'px' }}
    >
      <source src={media?.url} />
      Your browser does not support the video tag.
    </video>
  );
};

export default Video;
