import { FormInstance } from 'antd';
import { AxiosRequestConfig } from 'axios';

import {
  useAPIRequest,
  AuthResponse,
  formRequestErrorHandler,
  GoogleAuthRequest,
  requestErrorHandler,
} from '../../../api';
import { useAuthStore } from '../../../stores';

export function useAuthRequest({
  form,
  formConfigFactory,
  onSuccess,
}: {
  form: FormInstance<any>;
  formConfigFactory: (...rest: any[]) => AxiosRequestConfig<any>;
  onSuccess?: (response: AuthResponse) => void;
}) {
  const setToken = useAuthStore((state) => state.setToken);

  const { request: requestForm, isLoading: isLoadingForm } =
    useAPIRequest<AuthResponse>(formConfigFactory, {
      immediate: false,
      onSuccess: (response) => {
        setToken(response.token);
        onSuccess?.(response);
      },
      onError: formRequestErrorHandler(form),
    });

  const { request: requestGoogle, isLoading: isLoadingGoogle } =
    useAPIRequest<AuthResponse>(GoogleAuthRequest, {
      immediate: false,
      onSuccess: (response) => {
        setToken(response.token);
        onSuccess?.(response);
      },
      onError: requestErrorHandler(),
    });

  return {
    requestForm,
    requestGoogle,
    isLoading: isLoadingForm || isLoadingGoogle,
  };
}
