import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';

import { apiRequestLifecycle } from '../api-request';
import { RequestError } from '../interfaces';

type RequestFactory = (...rest: any[]) => AxiosRequestConfig;
type RequestParam<T> = RequestFactory | RequestOptions<T> | undefined;
export type Requests<T> = { [key: string]: RequestParam<T> };

interface Callbacks<T> {
  onSuccess?: (data: T, key: string) => void;
  onError?: (error: RequestError, key: string) => void;
}

interface RequestOptions<T> {
  requestFactory: RequestFactory;
  onSuccess?: (data: T) => void;
  onError?: (error: RequestError) => void;
  immediate?: boolean;
}

export function ImmediateRequest(requestFactory: RequestFactory) {
  return { requestFactory, immediate: true };
}

export function useRequests<T>({
  requests,
  callbacks,
}: {
  requests?: Requests<T>;
  callbacks?: Callbacks<T>;
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [response, setResponse] = useState<T | undefined>(undefined);
  const [requestKey, setRequestKey] = useState<string | undefined>(undefined);

  function requestFactory(
    key: string,
    { requestFactory, onSuccess, onError }: RequestOptions<T>,
  ) {
    return (...rest: any[]) =>
      apiRequestLifecycle<T>({
        config: requestFactory(...rest),
        onStart: () => setLoading(true),
        onComplete: () => setLoading(false),
        onSuccess: (data) => {
          setError(undefined);
          setResponse(data);
          setRequestKey(key);

          onSuccess?.(data);
          callbacks?.onSuccess?.(data, key);
        },
        onError: (e: RequestError) => {
          setError((e.message as string) || e.error);
          setRequestKey(key);

          onError?.(e);
          callbacks?.onError?.(e, key);
        },
      });
  }

  const request: {
    [key: string]: (...rest: any[]) => Promise<unknown>;
  } = {};

  const immediateRequests: ((...rest: any[]) => Promise<unknown>)[] = [];

  if (requests) {
    for (const [requestKey, requestValue] of Object.entries(requests)) {
      if (requestValue !== undefined) {
        const options =
          typeof requestValue === 'function'
            ? { requestFactory: requestValue }
            : requestValue;

        request[requestKey] = requestFactory(requestKey, options);

        if (options.immediate) immediateRequests.push(request[requestKey]);
      }
    }
  }

  useEffect(() => {
    immediateRequests.forEach((r) => r());
  }, []);

  return {
    response,
    setResponse,
    error,
    isLoading,
    request,
    requestKey,
  };
}
