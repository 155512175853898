import { Layout } from 'antd';
import { FC, PropsWithChildren, useState } from 'react';

import { useScrollToTopOnRouteChange } from '../../common';
import { RouteData, useAppQueryEffect } from '../../utils';
import { useLayoutChildren } from '../use-layout-children.hook';
import Global from '../../global.component';
import AppHeader from '../app/app-header.component';

const { Content } = Layout;

export const IndexLayout: FC<
  PropsWithChildren<{ withInit?: boolean; routeData: RouteData }>
> = ({ children, routeData, withInit = false }) => {
  const layoutChildren = useLayoutChildren(children);

  useAppQueryEffect();
  useScrollToTopOnRouteChange();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isHeaderVisible, setHeaderVisible] = useState<boolean>(false);

  const LayoutHeader = routeData.header || AppHeader;

  const getBackPath = () => '/';

  return (
    <Layout>
      <Layout className="inner-layout">
        <LayoutHeader
          routeData={routeData}
          backPath={getBackPath()}
          width="100%"
          maxWidth="100%"
          setVisible={setHeaderVisible}
        />

        <Content className="layout-content-offset-x">
          {withInit ? layoutChildren : children}
        </Content>
      </Layout>

      <Global />
    </Layout>
  );
};
