import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Typography } from 'antd';
import { jwtDecode } from 'jwt-decode';

import {
  AuthResponse,
  formRequestErrorHandler,
  useAPIRequest,
  AcceptInvitationRequest,
  requestErrorHandler,
  AcceptInvitationGoogleRequest,
  SignInRequest,
} from '../../../../api';
import { useAuthStore, useInitStore } from '../../../../stores';
import { If, PoliciesLinks, WideButton } from '../../../../common';
import GoogleAuth from '../../../../common/components/auth-drawer/google-auth.component';
import { AuthType } from '../../../../common/components/auth-drawer/auth-type.enum';
import { useAuthRequest } from '../../../../common/components/auth-drawer/use-auth-request.hook';

import '../../../../common/form.css';

const { Text, Title } = Typography;

const Invitation: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { setToken, clearToken } = useAuthStore();
  const { user, queryValues, forwardedToken, resetInitData } = useInitStore();

  useEffect(() => {
    if (queryValues) {
      if (!forwardedToken) navigate('/');
    }
  }, [queryValues, forwardedToken]);

  const { email, name, userId } = forwardedToken
    ? (jwtDecode(forwardedToken) as any)
    : ({} as any);

  const {
    request: requestAcceptInvitation,
    isLoading: isLoadingAcceptInvitation,
  } = useAPIRequest<AuthResponse>(
    (params: { email: string; password: string; confirmPassword: string }) =>
      AcceptInvitationRequest({
        token: forwardedToken as string,
        email: params.email,
        password: params.password,
      }),
    {
      immediate: false,
      onSuccess: (response) => {
        setToken(response.token);
        navigate('/creator/onboarding');
      },
      onError: formRequestErrorHandler(form),
    },
  );

  const {
    requestForm: requestSignIn,
    requestGoogle: requestGoogleSignIn,
    isLoading: isLoadingSignIn,
  } = useAuthRequest({
    form,
    formConfigFactory: SignInRequest,
    onSuccess: (response) => {
      setToken(response.token);
      navigate('/creator/onboarding');
    },
  });

  const { request: requestGoogleAcceptInvitation, isLoading: isLoadingGoogle } =
    useAPIRequest<AuthResponse>(
      (token: string) =>
        AcceptInvitationGoogleRequest({
          token: forwardedToken as string,
          oAuthToken: token,
        }),
      {
        immediate: false,
        onSuccess: (response) => {
          setToken(response.token);
          navigate('/creator/onboarding');
        },
        onError: requestErrorHandler(),
      },
    );

  useEffect(() => {
    if (email) form.setFieldsValue({ email });
  }, [email]);

  useEffect(() => {
    if (user && email && user.email !== email) {
      resetInitData();
      clearToken();
    }
  }, [user, email]);

  const isLoading =
    isLoadingAcceptInvitation || isLoadingSignIn || isLoadingGoogle;

  return (
    <div
      className="form"
      style={{ width: '100%', marginTop: '96px', paddingTop: 0 }}
    >
      <Title
        style={{
          marginTop: '10px',
          marginBottom: '0.2em',
          textAlign: 'center',
        }}
      >
        {name}, you've been invited to create an AI clone on SWOONS!
      </Title>

      <If condition={user}>
        <div
          style={{
            width: '100%',
            maxWidth: '500px',
            paddingLeft: '10px',
            paddingRight: '10px',
            marginTop: '20px',
          }}
        >
          <WideButton label="Get Started" to="/creator/onboarding" accent />
        </div>
      </If>

      <If condition={!user}>
        <If condition={!userId}>
          <div style={{ marginBottom: '1em', textAlign: 'center' }}>
            <Text>Please sign up to get started...</Text>
          </div>

          <div
            style={{
              width: '100%',
              maxWidth: '500px',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            <GoogleAuth
              authType={AuthType.SignUp}
              onSuccess={requestGoogleAcceptInvitation}
              loading={isLoading}
            />

            <div className="divider" />

            <Form
              form={form}
              onFinish={(params) =>
                requestAcceptInvitation({ ...params, path: location.pathname })
              }
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
              disabled={isLoading}
              style={{ width: '100%' }}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email must not be empty',
                  },
                  {
                    type: 'email',
                    message: 'Email is invalid',
                  },
                ]}
              >
                <Input placeholder="Email" disabled={!!userId} />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Password must not be empty' },
                ]}
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>

              <Form.Item
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: 'Confirm password must not be empty',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value)
                        return Promise.resolve();

                      return Promise.reject(new Error("Passwords don't match"));
                    },
                  }),
                ]}
              >
                <Input type="password" placeholder="Confirm password" />
              </Form.Item>

              <Form.Item style={{ marginTop: '8px', marginBottom: '0px' }}>
                <WideButton
                  label="Sign Up"
                  onClick={form.submit}
                  accent
                  disabled={isLoading || isLoadingGoogle}
                />
              </Form.Item>
            </Form>

            <PoliciesLinks
              disabled={isLoading}
              style={{ textAlign: 'center' }}
            />
          </div>
        </If>

        <If condition={userId}>
          <div style={{ marginBottom: '1em', textAlign: 'center' }}>
            <Text>Please sign in with {email} to get started...</Text>
          </div>

          <div
            style={{
              width: '100%',
              maxWidth: '500px',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            <GoogleAuth
              authType={AuthType.SignIn}
              onSuccess={(token) => requestGoogleSignIn(token, email)}
              loading={isLoading}
            />

            <div className="divider" />

            <Form
              form={form}
              onFinish={requestSignIn}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
              disabled={isLoading}
              style={{ width: '100%' }}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email must not be empty',
                  },
                  {
                    type: 'email',
                    message: 'Email is invalid',
                  },
                ]}
              >
                <Input placeholder="Email" disabled={!!userId} />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Password must not be empty' },
                ]}
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>

              <Form.Item style={{ marginTop: '8px', marginBottom: '0px' }}>
                <WideButton
                  label="Sign In"
                  onClick={form.submit}
                  accent
                  disabled={isLoading}
                />
              </Form.Item>
            </Form>
          </div>
        </If>
      </If>
    </div>
  );
};

export default Invitation;
