import { AxiosRequestConfig } from 'axios';

export const CreateKnowledgeEntryTransloaditAssembliesRequest = (
  characterId: string,
  assemblyIds: string[],
): AxiosRequestConfig => {
  return {
    url: `v1/knowledge/transloadit/assemblies`,
    method: 'post',
    data: { characterId, assemblyIds },
  };
};
