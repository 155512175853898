export const WalletSvg = () => (
  <svg
    width="100%"
    height="auto"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4 22.875H15.6V24.4375C15.6 24.8519 15.4314 25.2493 15.1314 25.5424C14.8313 25.8354 14.4243 26 14 26C13.5757 26 13.1687 25.8354 12.8686 25.5424C12.5686 25.2493 12.4 24.8519 12.4 24.4375V22.875H9.2C8.77565 22.875 8.36869 22.7104 8.06863 22.4174C7.76857 22.1243 7.6 21.7269 7.6 21.3125C7.6 20.8981 7.76857 20.5007 8.06863 20.2076C8.36869 19.9146 8.77565 19.75 9.2 19.75H16.4C17.0365 19.75 17.647 19.5031 18.0971 19.0635C18.5471 18.624 18.8 18.0279 18.8 17.4062C18.8 16.7846 18.5471 16.1885 18.0971 15.749C17.647 15.3094 17.0365 15.0625 16.4 15.0625H11.6C10.1148 15.0625 8.69041 14.4863 7.6402 13.4607C6.59 12.4352 6 11.0442 6 9.59375C6 8.14335 6.59 6.75235 7.6402 5.72676C8.69041 4.70117 10.1148 4.125 11.6 4.125H12.4V2.5625C12.4 2.1481 12.5686 1.75067 12.8686 1.45765C13.1687 1.16462 13.5757 1 14 1C14.4243 1 14.8313 1.16462 15.1314 1.45765C15.4314 1.75067 15.6 2.1481 15.6 2.5625V4.125H18.8C19.2243 4.125 19.6313 4.28962 19.9314 4.58265C20.2314 4.87567 20.4 5.2731 20.4 5.6875C20.4 6.1019 20.2314 6.49933 19.9314 6.79235C19.6313 7.08538 19.2243 7.25 18.8 7.25H11.6C10.9635 7.25 10.353 7.49693 9.90294 7.93647C9.45286 8.37601 9.2 8.97215 9.2 9.59375C9.2 10.2154 9.45286 10.8115 9.90294 11.251C10.353 11.6906 10.9635 11.9375 11.6 11.9375H16.4C17.8852 11.9375 19.3096 12.5137 20.3598 13.5393C21.41 14.5648 22 15.9558 22 17.4062C22 18.8567 21.41 20.2477 20.3598 21.2732C19.3096 22.2988 17.8852 22.875 16.4 22.875Z"
      fill="currentColor"
    />
  </svg>
);

export const ChatSvg = () => (
  <svg
    width="100%"
    height="auto"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.375 14.0002C25.3754 15.9641 24.8674 17.8946 23.9004 19.6039C22.9333 21.3132 21.5403 22.7429 19.8567 23.7541C18.1732 24.7652 16.2565 25.3233 14.2933 25.3739C12.3301 25.4246 10.3872 24.966 8.65375 24.043L4.92953 25.2844C4.62119 25.3873 4.2903 25.4022 3.97395 25.3275C3.6576 25.2529 3.3683 25.0916 3.13846 24.8618C2.90863 24.6319 2.74734 24.3426 2.67268 24.0263C2.59803 23.7099 2.61295 23.379 2.71578 23.0707L3.95719 19.3465C3.14579 17.8209 2.69259 16.1306 2.632 14.4038C2.5714 12.677 2.90501 10.959 3.60748 9.38036C4.30996 7.80171 5.36284 6.40385 6.68622 5.29286C8.0096 4.18188 9.56869 3.38699 11.2451 2.96852C12.9216 2.55005 14.6713 2.519 16.3616 2.87773C18.0518 3.23646 19.6381 3.97554 21.0001 5.03887C22.3621 6.1022 23.4639 7.46184 24.2219 9.01457C24.98 10.5673 25.3743 12.2723 25.375 14.0002Z"
      fill="currentColor"
    />
  </svg>
);

export const MoreSvg = () => (
  <svg
    width="100%"
    height="auto"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75 11C4.2375 11 3 12.2375 3 13.75C3 15.2625 4.2375 16.5 5.75 16.5C7.2625 16.5 8.5 15.2625 8.5 13.75C8.5 12.2375 7.2625 11 5.75 11ZM22.25 11C20.7375 11 19.5 12.2375 19.5 13.75C19.5 15.2625 20.7375 16.5 22.25 16.5C23.7625 16.5 25 15.2625 25 13.75C25 12.2375 23.7625 11 22.25 11ZM14 11C12.4875 11 11.25 12.2375 11.25 13.75C11.25 15.2625 12.4875 16.5 14 16.5C15.5125 16.5 16.75 15.2625 16.75 13.75C16.75 12.2375 15.5125 11 14 11Z"
      fill="currentColor"
    />
  </svg>
);

export const ExploreSvg = () => (
  <svg
    width="100%"
    height="auto"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g id="Layer_2">
      <g id="invisible_box">
        <rect width="48" height="48" fill="none" />
      </g>
      <g id="icons_Q2">
        <path d="M24,2A22,22,0,1,0,46,24,21.9,21.9,0,0,0,24,2ZM34.7,14.7,28,28,14.7,34.7a1.1,1.1,0,0,1-1.4-1.4L20,20l13.3-6.7A1.1,1.1,0,0,1,34.7,14.7ZM24,22a2,2,0,1,0,2,2A2,2,0,0,0,24,22Z" />
        <path d="M24,22a2,2,0,1,0,2,2A2,2,0,0,0,24,22Zm0,0a2,2,0,1,0,2,2A2,2,0,0,0,24,22Z" />
      </g>
    </g>
  </svg>
);
