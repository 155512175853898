import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Avatar, Layout, Space } from 'antd';
import Icon from '@ant-design/icons';

import { useChatStore, useInitStore } from '../../../stores';
import { CallSvg, If, useCall, VerifiedSvg } from '../../../common';
import { ResponseType } from '../../../api';
import { RouteData, cssObjectToString } from '../../../utils';
import { BackSvg } from '../../layout-header-svg.component';

import './chat-header.component.css';

const { Header } = Layout;

const ChatHeader: FC<{
  routeData: RouteData;
  backPath: string;
  width?: string;
  maxWidth?: string;
  setVisible: (value: boolean) => void;
  style?: React.CSSProperties;
  [key: string]: any;
}> = ({
  routeData,
  backPath,
  width,
  maxWidth,
  setVisible,
  style,
  ...props
}) => {
  // const token = useAuthStore((state) => state.token);
  const user = useInitStore((state) => state.user);

  // const { setSubscriptionModalOpen } = useMiscStore();

  const { character, sendingMessage, tab, setTab } = useChatStore();

  const { start: startCall } = useCall(character);

  useEffect(() => {
    setVisible(true);
  }, [routeData]);

  let status = 'Online';

  if (sendingMessage) {
    status =
      user?.responseType === ResponseType.Voice ? 'recording...' : 'typing...';
  }

  let tabHeight = '96px';

  if (character?.hasMedias) {
    tabHeight = `calc(${tabHeight} + 38px)`;
  }

  return (
    <Header
      style={{
        width,
        maxWidth,
        borderRadius: routeData.flatHeader ? 0 : undefined,
        height: tabHeight,
        padding: 0,
        ...style,
      }}
      {...props}
    >
      <Helmet>
        <title>{character ? character?.name + ' - Swoons' : 'Swoons'} </title>
      </Helmet>

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          className="header-container"
          style={{ height: '96px', padding: '16px', position: 'relative' }}
        >
          <Space direction="horizontal" size={12} style={{ cursor: 'pointer' }}>
            <Link to={backPath}>
              <div className="button-circle">
                <Icon component={BackSvg} />
              </div>
            </Link>

            <Link to={`/${character?.handle}`}>
              <Avatar
                size={48}
                src={character?.avatarUrl}
                style={{ cursor: 'pointer' }}
              />
            </Link>
          </Space>

          <If condition={!!character}>
            <Link to={`/${character?.handle}`} style={{ cursor: 'pointer' }}>
              <Space
                direction="horizontal"
                align="start"
                size={4}
                className="character-title"
              >
                <Space direction="vertical" size={2}>
                  <div className="character-name">{character?.name}</div>

                  <div
                    className="character-status"
                    style={{
                      visibility: status !== '.' ? 'visible' : 'hidden',
                    }}
                  >
                    {status}
                  </div>
                </Space>

                {character?.name && <Icon component={VerifiedSvg} />}
              </Space>
            </Link>
          </If>

          <Space direction="horizontal" size={4}>
            {/*<If condition={token && !user?.subscription.active}>
            <button
              className="subscribe-button"
              onClick={() => setSubscriptionModalOpen(true)}
            >
              Subscribe
            </button>
          </If>*/}

            <button
              className="header-button-circle"
              onClick={startCall}
              style={{ marginRight: '10px' }}
            >
              <Icon component={CallSvg} style={{ width: '20px' }} />
            </button>

            {/*<If condition={false}>
            <button
              className={
                'button-response-type left' + (!voiceActive ? ' active' : '')
              }
              onClick={() => setVoiceActive(false)}
              disabled={sendingMessage}
            >
              <Icon component={TextSvg} style={{ position: 'relative' }} />
            </button>
          </If>

          <If condition={false}>
            <button
              className={
                'button-response-type right' + (voiceActive ? ' active' : '')
              }
              onClick={() => setVoiceActive(true)}
              disabled={sendingMessage}
            >
              <Icon
                component={VoiceSvg}
                style={{ position: 'relative', right: '2px' }}
              />
            </button>
            </If>*/}
          </Space>
        </div>

        <If condition={character?.hasMedias}>
          <div className="chat-tabs">
            <div
              className={cssObjectToString({
                tab: true,
                active: tab === 'chat',
              })}
              onClick={() => setTab('chat')}
            >
              Chat
            </div>

            <div
              className={cssObjectToString({
                tab: true,
                active: tab === 'media',
              })}
              onClick={() => setTab('media')}
            >
              Media
            </div>
          </div>
        </If>
      </div>
    </Header>
  );
};

export default ChatHeader;
