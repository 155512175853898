import { FC } from 'react';

import { TextType } from './text-type.enum';
import { CodeBlock } from './code-block';

import './message-text-items.component.css';

const MessageTextItems: FC<{
  items?: { language?: string; text: string; type: TextType }[];
  style?: React.CSSProperties;
}> = ({ items, style }) => {
  return (
    <div style={style}>
      {items?.map((item, index) => {
        const key = index + '-' + item.text;

        if (item.type === TextType.Code) {
          return (
            <CodeBlock
              key={key}
              code={item.text}
              language={item.language}
              className="code-block"
              style={{ padding: '16px' }}
            />
          );
        } else if (item.type === TextType.InlineCode) {
          return (
            <code
              key={key}
              className="code-block"
              style={{
                padding: '1px 4px',
                margin: '4px',
                backgroundColor: 'var(--grey3)',
              }}
            >
              {item.text}
            </code>
          );
        }

        if (item.type === TextType.Bold) {
          return (
            <span
              key={key}
              style={{ fontWeight: 'bold', textDecoration: 'underline' }}
            >
              {item.text}
            </span>
          );
        }

        return <span key={key}>{item.text}</span>;
      })}
    </div>
  );
};

export default MessageTextItems;
