import { Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  useAPIRequest,
  ChatListSort,
  CharacterChatListRequest,
  CharacterChatParams,
} from '../../../../api';
import { cssObjectToString, formatDate } from '../../../../utils';
import { SelectAutoClose } from '../../../../common';

import './character-chat-list.component.css';

const { Option } = Select;

const PER_PAGE = 50;

const ChatList: React.FC<{
  value?: CharacterChatParams;
  onChange?: (chat?: CharacterChatParams) => void;
  style?: React.CSSProperties;
}> = ({ value, onChange, style }) => {
  const params = useParams();

  const lastSortRef = useRef<ChatListSort>();
  const pageRef = useRef<number>(1);
  const pagesRef = useRef<number>(1);
  const chatsRef = useRef<CharacterChatParams[]>([]);

  const [sort, setSort] = useState<ChatListSort>(ChatListSort.RecentMessage);
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(1);
  const [chats, setChats] = useState<CharacterChatParams[]>([]);

  const { request, setResponse, isLoading } = useAPIRequest<{
    chats: CharacterChatParams[];
    perPage: number;
    page: number;
    pages: number;
  }>(
    (page: number) =>
      CharacterChatListRequest(params.characterId!, sort, PER_PAGE, page),
    {
      immediate: false,
      onSuccess: (result) => {
        const newChats = [...chatsRef.current, ...result.chats];

        setChats(newChats);
        setPage(result.page);
        setPages(result.pages);
        pageRef.current = result.page;
        pagesRef.current = result.pages;
        chatsRef.current = newChats;
      },
    },
  );

  useEffect(() => {
    if (params.characterId && sort !== lastSortRef.current) {
      const newChats: CharacterChatParams[] = [];

      lastSortRef.current = sort;
      pageRef.current = 1;
      pagesRef.current = 1;
      chatsRef.current = newChats;
      setChats(newChats);
      setPage(1);
      setPages(1);
      setResponse(undefined);
      onChange?.(undefined);
      request(pageRef.current);
    }
  }, [params.characterId, sort]);

  const isLoadingRef = useRef<boolean>(isLoading);

  const loadMoreChats = useCallback(() => {
    if (!isLoading && params.characterId && page < pages) {
      request(page + 1);

      if (chats) {
        return chats[chats.length - 1].id; // returns bottom chat before more chats are loaded
      }
    }
  }, [isLoading, params.characterId, page, pages]);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  return (
    <div style={style}>
      <div
        className="character-chat-list-sort-container"
        style={{ marginBottom: '20px' }}
      >
        <div style={{ color: 'var(--white)', marginRight: '10px' }}>
          Sort by:
        </div>

        <SelectAutoClose
          placeholder="Sort by..."
          value={sort}
          onChange={setSort}
          style={{ width: '200px' }}
        >
          <Option value={ChatListSort.Messages}>Messages</Option>
          <Option value={ChatListSort.Media}>Media</Option>
          <Option value={ChatListSort.Spendings}>Spendings</Option>
          <Option value={ChatListSort.RecentMessage}>Recent Message</Option>
        </SelectAutoClose>
      </div>

      <div
        id="scrollableDiv"
        className="character-chat-list-container"
        style={{ height: 'calc(100svh - 96px - 96px)', overflow: 'auto' }}
      >
        <InfiniteScroll
          dataLength={chats.length || 0}
          next={loadMoreChats}
          hasMore={isLoading || page < pages}
          loader={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <LoadingOutlined
                style={{ fontSize: '40px', color: 'var(--white)' }}
                spin
              />
            </div>
          }
          scrollableTarget="scrollableDiv"
          style={{ overflowY: 'hidden' }}
        >
          {chats.map((item) => {
            return (
              <div
                className={cssObjectToString({
                  chat: true,
                  selected: value === item,
                })}
                onClick={() => onChange?.(item)}
                key={item.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    flex: 1,
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    marginRight: '10px',
                  }}
                >
                  <div
                    style={{ color: 'var(--white)', overflowWrap: 'anywhere' }}
                  >
                    {item.user.name}
                  </div>
                  <div
                    style={{ color: 'var(--grey6)', overflowWrap: 'anywhere' }}
                  >
                    {item.user.email}
                  </div>
                </div>

                <div style={{ width: '200px' }}>
                  <div>Media: {item.mediaPurchased}</div>
                  <div>Messages: {item.totalUserMessages}</div>
                  <div>Spendings: ${item.balanceSpent.toFixed(2)}</div>
                  <div>Balance: ${item.user.balance?.toFixed(2)}</div>
                  <div>
                    Last message: {formatDate(item.lastUserMessageDate)}
                  </div>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ChatList;
