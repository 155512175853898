import { Avatar, Space } from 'antd';
import { QuestionOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { MediaParams, MediaType } from '../../../../api';
import { If } from '../../../../common';
import MediaItemNote from './media-item-note.component';

const MediaItem: React.FC<{ characterId: string; data: MediaParams }> = ({
  characterId,
  data,
}) => {
  return (
    <Link
      to={`/creator/character/${characterId}/media/${data.id}`}
      key={data.id}
      style={{ maxWidth: '100%' }}
    >
      <div style={{ position: 'relative' }}>
        <Avatar
          shape="square"
          size={140}
          src={data.thumbnailUrl}
          icon={<QuestionOutlined />}
        />

        <If condition={data.type === MediaType.Video}>
          <div style={{ position: 'absolute', top: '5%', right: '5%' }}>
            <VideoCameraOutlined style={{ fontSize: '20px', color: 'white' }} />
          </div>
        </If>

        <Space
          direction="vertical"
          style={{
            position: 'absolute',
            bottom: '5%',
            left: '5%',
          }}
        >
          <If condition={data.welcome}>
            <MediaItemNote text="Welcome" />
          </If>
        </Space>
      </div>
    </Link>
  );
};

export default MediaItem;
