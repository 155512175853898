import create from 'zustand';

import { ChatCharacter, Message, MessageMedia } from '../api';

type ChatTabType = 'chat' | 'media';

interface ChatStoreState {
  id?: string;
  firstPageLoaded: boolean;
  chatMenuOpen: boolean;
  pricingMenuOpen: boolean;
  paginationToken?: string;
  sendingMessage: boolean;
  sendWelcomeMessageFlag: boolean;
  bundlesOpen: boolean;
  viewedMedia?: MessageMedia;
  character?: ChatCharacter;
  messages: Message[];
  loadingMessages: boolean;
  tab: ChatTabType;
  reset: () => void;
  clear: () => void;
  setId: (value: string) => void;
  setPaginationToken: (value?: string) => void;
  setFirstPageLoaded: (value: boolean) => void;
  setLoadingMessages: (value: boolean) => void;
  setChatMenuOpen: (value: boolean) => void;
  setPricingMenuOpen: (value: boolean) => void;
  setSendingMessage: (value: boolean) => void;
  setSendWelcomeMessageFlag: (value: boolean) => void;
  setBundlesOpen: (value: boolean) => void;
  setViewedMedia: (value?: MessageMedia) => void;
  setCharacter: (value?: ChatCharacter) => void;
  setMessages: (value: Message[]) => void;
  setTab: (value: ChatTabType) => void;
}

export const useChatStore = create<ChatStoreState>((set) => ({
  id: undefined,
  firstPageLoaded: false,
  chatMenuOpen: false,
  pricingMenuOpen: false,
  paginationToken: undefined,
  sendingMessage: false,
  sendWelcomeMessageFlag: false,
  bundlesOpen: false,
  viewedMedia: undefined,
  character: undefined,
  messages: [],
  loadingMessages: false,
  tab: 'chat',
  // when completely changing a chat
  reset: () => {
    set({
      id: undefined,
      paginationToken: undefined,
      firstPageLoaded: false,
      sendingMessage: false,
      character: undefined,
      viewedMedia: undefined,
      messages: [],
      tab: 'chat',
    });
  },
  // when clearing the current chat
  clear: () => {
    set({
      paginationToken: undefined,
      sendingMessage: false,
      viewedMedia: undefined,
      messages: [],
    });
  },
  setId: (value: string) => set({ id: value }),
  setPaginationToken: (value?: string) => set({ paginationToken: value }),
  setFirstPageLoaded: (value: boolean) => set({ firstPageLoaded: value }),
  setLoadingMessages: (value: boolean) => set({ loadingMessages: value }),
  setChatMenuOpen: (value: boolean) => set({ chatMenuOpen: value }),
  setPricingMenuOpen: (value: boolean) => set({ pricingMenuOpen: value }),
  setSendingMessage: (value: boolean) => set({ sendingMessage: value }),
  setSendWelcomeMessageFlag: (value: boolean) => {
    set({ sendWelcomeMessageFlag: value });
  },
  setBundlesOpen: (value: boolean) => set({ bundlesOpen: value }),
  setCharacter: (value?: ChatCharacter) => set({ character: value }),
  setViewedMedia: (value?: MessageMedia) => set({ viewedMedia: value }),
  setMessages: (value: Message[]) => set({ messages: value }),
  setTab: (value: ChatTabType) => set({ tab: value }),
}));
