import { Col, Form, Input, InputNumber, Row, Space, Typography } from 'antd';
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useRequests,
  formRequestErrorHandler,
  getProductRequests,
} from '../../../../api';
import {
  Loader,
  CRUDKey,
  WideButton,
  AvatarUploader,
} from '../../../../common';
import {
  confirmDangerousAction,
  createNotification,
  NotificationType,
} from '../../../../utils';

const { TextArea } = Input;
const { Title } = Typography;

const Product: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { request, requestKey, response, isLoading, error } = useRequests({
    requests: getProductRequests(params.productId!),
    callbacks: {
      onSuccess: (data, key) => {
        if (key === CRUDKey.Read) {
          form.setFieldsValue(data);
        } else if (key === CRUDKey.Update) {
          createNotification({
            key: 'productUpdated',
            message: 'Product saved successfully.',
            type: NotificationType.Success,
            duration: 6,
          });
        } else if (key === CRUDKey.Delete) {
          createNotification({
            key: 'productDeleted',
            message: 'Product deleted successfully.',
            type: NotificationType.Success,
            duration: 6,
          });

          navigate(`/creator/character/${params.characterId}/products`);
        }
      },
      onError: formRequestErrorHandler(form),
    },
  });

  useEffect(() => {
    if (params.productId) request.read();
  }, [params.productId]);

  return (
    <Loader
      error={requestKey === CRUDKey.Read ? error : undefined}
      isLoading={isLoading}
    >
      <Form
        form={form}
        onFinish={request.update}
        initialValues={response}
        layout="vertical"
        requiredMark={false}
        style={{ padding: '20px' }}
      >
        <Row>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: '40px' }}
            size="small"
          >
            <Title style={{ marginBottom: 0 }}>{response?.name}</Title>
          </Space>
        </Row>

        <Row align="top">
          <Col flex="200px" style={{ marginRight: '40px' }}>
            <Form.Item label="Thumbnail (260x260)">
              <AvatarUploader
                templateId={
                  process.env.REACT_APP_TRANSLOADIT_TEMPLATE_PRODUCT_THUMBNAIL!
                }
                fields={{ product_id: response?.id }}
                initialValue={response?.thumbnailUrl}
                size={260}
              />
            </Form.Item>
          </Col>

          <Col flex="1" style={{ minWidth: 'min(450px, 100%)' }}>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: true, message: 'Name is required' },
                  {
                    max: 60,
                    message: 'Name must be maximum 60 characters',
                  },
                ]}
              >
                <Input
                  placeholder="Ex: Epomaker TH80 Pro"
                  style={{ maxWidth: '500px' }}
                />
              </Form.Item>

              <Form.Item
                name="category"
                label="Category"
                rules={[
                  { required: true, message: 'Category is required' },
                  {
                    max: 30,
                    message: 'Category must be maximum 30 characters',
                  },
                ]}
              >
                <Input
                  placeholder="Ex: Keyboard"
                  style={{ maxWidth: '500px' }}
                />
              </Form.Item>

              <Form.Item
                name="price"
                label="Price"
                tooltip="The price in US dollars. Specify 0 if not applicable."
                rules={[
                  {
                    required: true,
                    message: 'Price is required',
                  },
                ]}
              >
                <InputNumber min={0} style={{ maxWidth: '500px' }} />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    max: 2200,
                    message: 'Description must be maximum 2000 characters',
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 6, maxRows: 10 }}
                  style={{ maxWidth: '800px' }}
                />
              </Form.Item>

              <Form.Item
                name="specifications"
                label="Specifications"
                rules={[
                  {
                    max: 3200,
                    message: 'Specifications must be maximum 3000 characters',
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 6, maxRows: 10 }}
                  style={{ maxWidth: '800px' }}
                />
              </Form.Item>

              <Form.Item
                name="url"
                label="URL"
                rules={[
                  { required: true, message: 'URL is required' },
                  {
                    max: 300,
                    message: 'URL must be maximum 300 characters',
                  },
                ]}
              >
                <Input style={{ maxWidth: '800px' }} />
              </Form.Item>

              <Space>
                <WideButton
                  label="Save"
                  disabled={isLoading}
                  onClick={form.submit}
                  style={{ width: '160px' }}
                  accent
                >
                  <SaveOutlined />
                </WideButton>

                <WideButton
                  label="Delete"
                  disabled={isLoading}
                  onClick={() =>
                    confirmDangerousAction({
                      action: 'Delete',
                      name: 'this product',
                      onConfirm: request.delete,
                    })
                  }
                  style={{ width: '160px' }}
                  light
                >
                  <DeleteOutlined />
                </WideButton>
              </Space>
            </Space>
          </Col>
        </Row>
      </Form>
    </Loader>
  );
};

export default Product;
