import { RouteData } from '../../utils';
import { AuthLayout } from '../../layouts/auth-layout/auth-layout.component';
import ConfirmEmail from './confirm-email/confirm-email.component';
import Onboarding from './onboarding/onboarding.component';

// all onboarding must use an AuthLayout or otherwise a layout that doesn't internally use useLayoutChildren to redirect
// otherwise it can end up in a redirect loop
export const RequiredRoutes: RouteData[] = [
  {
    path: 'confirm-email',
    component: ConfirmEmail,
    isAuthRoute: true,
    authRequired: true,
    layout: AuthLayout,
  },
  {
    path: 'onboarding',
    component: Onboarding,
    isAuthRoute: true,
    authRequired: true,
    layout: AuthLayout,
  },
];
