import { Typography } from 'antd';

import { textToLines } from '../../utils';

const { Paragraph } = Typography;

export const CardParagraph: React.FC<{
  text: string;
  rows?: number;
  maxLength?: number;
  style?: React.CSSProperties;
  [key: string]: any;
}> = ({ text, rows = 0, maxLength, style, ...props }) => {
  const lines = textToLines(text, {
    maxLength,
    props: { className: 'ant-card-meta-description' },
  });

  let ellipsis: any = undefined;

  if (rows) {
    ellipsis = { rows };

    while (lines.length < rows) {
      lines.push(
        <div
          className="ant-card-meta-description"
          style={{ visibility: 'hidden' }}
          key={`line_${lines.length}`}
        >
          ;
        </div>,
      );
    }
  }

  return (
    <Paragraph ellipsis={ellipsis} style={style} {...props}>
      {lines}
    </Paragraph>
  );
};
