import { AuthDrawerState, WideButton } from '../../../../common';
import { useMiscStore } from '../../../../stores';

import './join.component.css';

const Join: React.FC<{
  style?: React.CSSProperties;
}> = ({ style }) => {
  const { setAuthOpen } = useMiscStore();

  return (
    <div className="join-container" style={style}>
      <div className="title" style={{ marginBottom: '10px' }}>
        Join the fastest
        <br />
        growing creator
        <br />
        platform
      </div>

      <div
        className="description"
        style={{
          paddingLeft: '20px',
          paddingRight: '20px',
          marginBottom: '20px',
        }}
      >
        Discover why Swoons is the fastest growing creator platform in the world
      </div>

      <WideButton
        label="Get Started"
        onClick={() => setAuthOpen(true, AuthDrawerState.SignUp)}
        accent
        style={{ height: '60px', maxWidth: '120px', marginTop: '16px' }}
      />
    </div>
  );
};

export default Join;
