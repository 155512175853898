import { AxiosRequestConfig } from 'axios';

import { ChatParams } from './chat-params.interface';

export const ChatListRequest = (): AxiosRequestConfig => {
  return {
    url: 'v1/chat/list',
    method: 'get',
    transformResponse: (response: string) => {
      const { data }: { data: ChatParams[] } = JSON.parse(response);

      return {
        data: data.map((item) => ({
          ...item,
          lastMessageDate: new Date(item.lastMessageDate),
          lastUserMessageDate: new Date(item.lastUserMessageDate),
        })),
      };
    },
  };
};
