import { AxiosRequestConfig } from 'axios';
import { useState, useEffect } from 'react';

import { apiRequestLifecycle } from '../api-request';
import { APIRequestOptions } from '../interfaces';

export function useAPIRequest<T = any>(
  configFactory: (...rest: any[]) => AxiosRequestConfig<T>,
  options?: APIRequestOptions<T>,
) {
  if (!options) options = {};
  if (options.immediate == undefined) options.immediate = true;

  const {
    immediate,
    onStart,
    onComplete,
    onSuccess,
    onError,
    shouldRetry,
    retryInterval,
  } = options;

  const [response, setResponseInner] = useState<T | undefined>(undefined);

  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);

  function setResponse(value: T | undefined) {
    setResponseInner(value);
    setError(undefined);
  }

  function mergeResponse(value: Partial<T | undefined>) {
    setResponse({ ...response, ...value } as any);
  }

  const request = (...rest: any[]) => {
    apiRequestLifecycle({
      config: configFactory(...rest),
      onSuccess: (data) => {
        setResponse(data);

        if (shouldRetry?.(data, ...rest)) {
          setTimeout(() => request(...rest), retryInterval || 1000);
        }

        onSuccess?.(data, ...rest);
      },
      onError: (e) => {
        setError((e.message as string) || e.error);
        onError?.(e, ...rest);
      },
      onStart: () => {
        setLoading(true);
        onStart?.(rest);
      },
      onComplete: () => {
        setLoading(false);
        onComplete?.(rest);
      },
    });
  };

  useEffect(() => {
    if (immediate) request();
  }, []);

  return {
    response,
    setResponse,
    mergeResponse,
    error,
    setError,
    isLoading,
    setLoading,
    request,
  };
}
