import { StatefulLink } from '../stateful-link.component';

import './policies-links.component.css';

export const PoliciesLinks: React.FC<{
  disabled?: boolean;
  style?: React.CSSProperties;
  action?: string;
}> = ({ disabled, style, action = 'clicking Sign Up' }) => {
  return (
    <div className="policies-links" style={style}>
      By {action}, you agree to our
      <StatefulLink
        to="/tos"
        target="_blank"
        disabled={disabled}
        className="link"
        style={{ marginLeft: '4px', marginRight: '4px' }}
      >
        Terms
      </StatefulLink>
      and
      <StatefulLink
        to="/privacy"
        target="_blank"
        disabled={disabled}
        className="link"
        style={{ marginLeft: '4px' }}
      >
        Privacy Policy
      </StatefulLink>
    </div>
  );
};
