import { Select } from 'antd';
import { PropsWithChildren, useRef } from 'react';

export const SelectAutoClose: React.FC<
  PropsWithChildren<{
    onChange?: (value: any) => void;
    [key: string]: any;
  }>
> = ({ children, onChange, ...props }) => {
  const ref = useRef<any>();

  return (
    <Select
      ref={ref}
      onChange={(e) => {
        onChange?.(e);

        ref.current?.blur();
      }}
      {...props}
    >
      {children}
    </Select>
  );
};
