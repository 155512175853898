import { CRUDKey } from '../../../common';
import { Requests } from '../../hooks';
import { CharacterParams } from '../character';
import { ReadCharacterPersonalityRequest } from './read-character-personality.request';
import { UpdateCharacterPersonalityRequest } from './update-character-personality.request';

export function getCharacterPersonalityRequests(
  id: string,
): Requests<CharacterParams> {
  return {
    [CRUDKey.Read]: () => ReadCharacterPersonalityRequest(id),
    [CRUDKey.Update]: (params) => UpdateCharacterPersonalityRequest(id, params),
  };
}
