import Icon from '@ant-design/icons';
import {
  useAPIRequest,
  UserParams,
  UpdateResponseType,
  ResponseType,
} from '../../../../../api';
import { ClassicModal, WideButton } from '../../../../../common';
import { MenuSvg } from '../../../../../layouts/layout-header-svg.component';
import { useInitStore } from '../../../../../stores';

import './initial-chat-settings-modal.component.css';

const InitialChatSettingsModal: React.FC = () => {
  const { user, setResponseType } = useInitStore();

  const { request, isLoading } = useAPIRequest<UserParams>(UpdateResponseType, {
    immediate: false,
    onSuccess: (response) => {
      setResponseType(response.responseType);
    },
  });

  return (
    <ClassicModal
      isOpen={user && !user.responseType}
      width="90%"
      maxWidth="600px"
    >
      <div className="initial-chat-settings-modal-container">
        <div
          style={{
            textAlign: 'center',
            marginBottom: '30px',
            fontSize: '30px',
            color: 'var(--white)',
          }}
        >
          Would you like to receive text or voice responses?
        </div>

        <WideButton
          label="Text"
          accent
          onClick={() => request(ResponseType.Text)}
          disabled={isLoading}
          style={{ marginBottom: '10px', maxWidth: '200px' }}
        />

        <WideButton
          label="Voice"
          accent
          onClick={() => request(ResponseType.Voice)}
          disabled={isLoading}
          style={{ marginBottom: '10px', maxWidth: '200px' }}
        />

        <div
          style={{
            textAlign: 'center',
            marginTop: '10px',
            fontSize: '16px',
            color: 'var(--grey6)',
          }}
        >
          You can always change this later in settings{' '}
          <Icon
            component={MenuSvg}
            style={{
              color: 'var(--white)',
              width: '34px',
              verticalAlign: 'middle',
            }}
          />
        </div>
      </div>
    </ClassicModal>
  );
};

export default InitialChatSettingsModal;
