import { FC, PropsWithChildren, HTMLAttributeAnchorTarget } from 'react';
import { Link, Path } from 'react-router-dom';

export const StatefulLink: FC<
  PropsWithChildren<{
    onClick?: () => void;
    to?: string | Partial<Path>;
    target?: HTMLAttributeAnchorTarget;
    style?: React.CSSProperties;
    className?: string;
    disabled?: boolean;
  }>
> = ({ onClick, to, target, children, style, className, disabled = false }) => {
  if (disabled) {
    return (
      <span className={className} style={{ cursor: 'default', ...style }}>
        {children}
      </span>
    );
  } else {
    if (to) {
      return (
        <Link to={to} target={target} className={className} style={style}>
          {children}
        </Link>
      );
    } else {
      return (
        <span
          onClick={onClick}
          className={className}
          style={{ cursor: 'pointer', ...style }}
        >
          {children}
        </span>
      );
    }
  }
};
