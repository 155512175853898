import { Size } from './size.interface';
import { useWindowSize } from './use-window-size.hook';

export function useFitSize(objectSize: Size): Size {
  const { width, height } = useWindowSize();

  const result: Size = { width: 0, height: 0 };

  const aspectRatio = objectSize.width / objectSize.height;

  result.width = Math.min(objectSize.width, width * 0.9);
  result.height = result.width / aspectRatio;

  result.height = Math.min(result.height, height * 0.9);
  result.width = result.height * aspectRatio;

  return result;
}
