import { AxiosRequestConfig } from 'axios';

export const GoogleAuthRequest = (
  token: string,
  allowedEmail?: string,
): AxiosRequestConfig => {
  return {
    url: 'v1/oauth/google',
    method: 'post',
    data: { token, allowedEmail },
  };
};
