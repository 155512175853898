import {
  AuthDrawer,
  MenuDrawer,
  SubscriptionModal,
  WalletDrawer,
} from './common';

const Global: React.FC = () => {
  return (
    <>
      <MenuDrawer />
      <WalletDrawer />
      <SubscriptionModal />
      <AuthDrawer />
    </>
  );
};

export default Global;
