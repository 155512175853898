import { AppLayout } from '../../../layouts/app/app-layout.component';
import CharacterHeader from '../../../layouts/character/character-header.component';
import ChatFooter from '../../../layouts/chat/chat-footer/chat-footer.component';
import ChatHeader from '../../../layouts/chat/chat-header/chat-header.component';
import { RouteData } from '../../../utils';
import Character from './character/character.component';
import Chat from './chat/chat.component';

export const CharacterRoutes: RouteData[] = [
  {
    path: ':handle/chat',
    component: Chat,
    layout: AppLayout,
    header: ChatHeader,
    footer: ChatFooter,
    authRequired: false,
    flatHeader: true,
    headerHeight: '134px', // 96px base + 38px chat tab height
    backPathFactory: (params, auth) => (auth ? '/messages' : '/'),
  },
  {
    path: ':handle',
    component: Character,
    layout: AppLayout,
    header: CharacterHeader,
    authRequired: false,
    flatHeader: true,
    backPathFactory: (params, auth) => (auth ? '/messages' : '/'),
  },
  {
    path: 'talent/:handle',
    component: Character,
    layout: AppLayout,
    header: CharacterHeader,
    authRequired: false,
    flatHeader: true,
    backPathFactory: (params, auth) => (auth ? '/messages' : '/'),
  },
];
