import { Typography, Space } from 'antd';

import { LegalItemContent } from '../interfaces';

const { Text } = Typography;

export const LegalContent: React.FC<{
  data?: LegalItemContent | LegalItemContent[];
  indexes: number[];
}> = ({ data, indexes }) => {
  if (!data) return <></>;

  if (Array.isArray(data)) {
    return (
      <Space direction="vertical">
        {data.map((value, index) => {
          const newIndexes = [...indexes, index + 1];

          return (
            <LegalContent
              data={value}
              indexes={newIndexes}
              key={'legalContent_' + newIndexes.join('-')}
            />
          );
        })}
      </Space>
    );
  }

  if (typeof data === 'string') return <Text>{data}</Text>;

  return data; // react element
};
