import { Card, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { CardParagraph } from './card-paragraph.component';

const { Meta } = Card;

export const NewCard: React.FC<{ name: string }> = ({ name }) => {
  return (
    <Card
      hoverable
      cover={
        <div
          style={{
            width: '260px',
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
          }}
        >
          <Space direction="vertical" align="center" wrap>
            <PlusCircleOutlined style={{ fontSize: '30px' }} />
            <h1 style={{ margin: 0 }}>New {name}</h1>
          </Space>
        </div>
      }
      actions={[<h1 style={{ margin: 0, visibility: 'hidden' }}>;</h1>]}
    >
      <Meta
        title=";"
        description={
          <CardParagraph text={':'} rows={3} style={{ maxWidth: '200px' }} />
        }
        style={{ visibility: 'hidden' }}
      />
    </Card>
  );
};
