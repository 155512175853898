import { Form, InputNumber, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import Uppy from '@uppy/core';
import Transloadit, {
  COMPANION_URL,
  COMPANION_ALLOWED_HOSTS,
} from '@uppy/transloadit';
import Dropbox from '@uppy/dropbox';
import GoogleDrive from '@uppy/google-drive';
import { Dashboard } from '@uppy/react';

import { useInitStore } from '../../../../stores';
import { WideButton } from '../../../../common';

import './add-media.component.css';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

const AddMedia: React.FC<{
  characterId?: string;
  afterUploadRef?: React.MutableRefObject<
    (characterId: string, assemblyIds: string[], price: number) => void
  >;
  style?: React.CSSProperties;
}> = ({ characterId, afterUploadRef, style }) => {
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState(0);

  const user = useInitStore((state) => state.user);

  const assemblyId = useRef<string[]>([]);
  const characterIdRef = useRef<string | undefined>(characterId);
  const priceRef = useRef<number>(price);

  characterIdRef.current = characterId;

  const getCompanionConfig = (credentialsName: string): any => ({
    companionUrl: COMPANION_URL,
    companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    companionKeysParams: {
      key: process.env.REACT_APP_TRANSLOADIT_AUTH_KEY!,
      credentialsName,
    },
  });

  useEffect(() => {
    priceRef.current = price;
  }, [price]);

  const [uppy] = useState(() => {
    return new Uppy({
      restrictions: {
        allowedFileTypes: ['image/*', 'video/*'],
      },
    })
      .use(Transloadit, {
        assemblyOptions: (file) => {
          return {
            params: {
              auth: { key: process.env.REACT_APP_TRANSLOADIT_AUTH_KEY! },
              template_id: file?.type?.includes('image')
                ? process.env.REACT_APP_TRANSLOADIT_TEMPLATE_PICTURE
                : process.env.REACT_APP_TRANSLOADIT_TEMPLATE_VIDEO,
            },
            fields: {
              user_id: user?.id || '',
              character_id: characterIdRef.current!,
            },
          };
        },
      })
      .use(Dropbox, getCompanionConfig('dropbox'))
      .use(GoogleDrive, getCompanionConfig('google-drive'))
      .on('upload-success', (value: any) => {
        // called once per file, not per assembly

        const assembly = value?.transloadit?.assembly;

        if (!assemblyId.current.includes(assembly)) {
          assemblyId.current.push(assembly);
        }
      })
      .on('complete', () => {
        if (assemblyId.current?.length > 0) {
          afterUploadRef?.current?.(
            characterIdRef.current!,
            assemblyId.current,
            priceRef.current,
          );
        }

        assemblyId.current = [];
      });
  });

  return (
    <>
      <WideButton
        label="Add Media"
        onClick={() => setOpen(true)}
        style={{ width: '160px', ...style }}
        light
      >
        <PlusOutlined />
      </WideButton>

      <Modal
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        maskClosable={false}
        width="90%"
        style={{ maxWidth: '800px' }}
        className="add-media"
      >
        <Form.Item
          label="Bulk Price"
          tooltip="The price in US dollars that the user will be asked to pay to unlock the media."
        >
          <InputNumber
            value={price}
            onChange={(value) => setPrice(value || 0)}
            min={0}
            style={{ maxWidth: '500px' }}
          />
        </Form.Item>

        <Dashboard uppy={uppy} width="100%" />
      </Modal>
    </>
  );
};

export default AddMedia;
