import { Avatar, Space, Typography } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import {
  CharacterBrowseRequest,
  ChatCharacter,
  useAPIRequest,
} from '../../../api';
import { CenteredContainer, Loader } from '../../../common';
import { limitTextLength } from '../../../utils';

const { Title } = Typography;

const Browse: React.FC = () => {
  const { response, error, isLoading } = useAPIRequest<{
    data: ChatCharacter[];
  }>(() => CharacterBrowseRequest());

  return (
    <CenteredContainer style={{ width: '80%', height: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }} size={0}>
        <Title
          style={{
            marginTop: '40px',
            marginBottom: '20px',
          }}
        >
          Browse
        </Title>

        <Loader error={error} isLoading={isLoading}>
          <Space size="large" style={{ width: '100%' }} wrap>
            {response?.data?.map((item) => (
              <Link
                to={`/${item.handle}`}
                key={item.id}
                style={{ maxWidth: '100%' }}
              >
                <Space size="middle" direction="vertical" align="center">
                  <Avatar
                    size={160}
                    src={item.avatarUrl}
                    icon={<QuestionOutlined />}
                  />

                  <div>{limitTextLength(item.name, 25)}</div>
                </Space>
              </Link>
            ))}
          </Space>
        </Loader>
      </Space>
    </CenteredContainer>
  );
};

export default Browse;
