import { ReactNode } from 'react';

import './highlight.component.css';

const Highlight: React.FC<{
  title: ReactNode | string;
  description: ReactNode | string;
  imageUrl: string;
}> = ({ title, description, imageUrl }) => {
  return (
    <div className="highlight">
      <div
        className="background"
        style={{
          backgroundImage: `url(/highlight/${imageUrl})`,
          marginBottom: '10px',
        }}
      />

      <div style={{ padding: '20px' }}>
        <div className="title" style={{ marginBottom: '10px' }}>
          {title}
        </div>

        <div className="description">{description}</div>
      </div>
    </div>
  );
};

export default Highlight;
