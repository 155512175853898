import { Space } from 'antd';

const ImagePicker: React.FC<{
  value?: number;
  onChange?: (index: number) => void;
  data?: string[];
}> = ({ value, onChange, data }) => {
  return (
    <Space direction="horizontal" wrap>
      {data?.map((url, index) => {
        const isSelected = index === value;

        return (
          <img
            src={url}
            style={{
              width: '60px',
              height: '60px',
              objectFit: 'cover',
              cursor: isSelected ? 'default' : 'pointer',
              border: isSelected ? 'solid 2px var(--orange1)' : undefined,
            }}
            key={url}
            onClick={() => onChange?.(index)}
          />
        );
      })}
    </Space>
  );
};

export default ImagePicker;
