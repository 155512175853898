import AccountHeader from '../../../layouts/account/account-header.component';
import { AppLayout } from '../../../layouts/app/app-layout.component';
import { RouteData } from '../../../utils';
import Account from './account.component';

export const AccountRoutes: RouteData[] = [
  {
    path: 'account',
    title: 'Account',
    component: Account,
    layout: AppLayout,
    header: AccountHeader,
    authRequired: true,
  },
];
