import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';

import {
  AuthResponse,
  formRequestErrorHandler,
  useAPIRequest,
  ChangePasswordRequest,
} from '../../../api';
import { useAuthStore, useInitStore } from '../../../stores';
import { CenteredContainer } from '../../../common';
import { createNotification, NotificationType } from '../../../utils';

import '../../../common/form.css';

const ChangePassword: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const setToken = useAuthStore((state) => state.setToken);

  const { queryValues, forwardedToken } = useInitStore();

  useEffect(() => {
    if (queryValues) {
      if (!forwardedToken) navigate('/');
    }
  }, [queryValues, forwardedToken]);

  const { request, isLoading } = useAPIRequest<AuthResponse>(
    (params: { password: string; confirmPassword: string }) =>
      ChangePasswordRequest({
        token: forwardedToken as string,
        password: params.password,
      }),
    {
      immediate: false,
      onSuccess: (response: AuthResponse) => {
        setToken(response.token);

        createNotification({
          key: 'changePassword',
          message: 'Password has been changed successfully.',
          type: NotificationType.Success,
          duration: 6,
        });
      },
      onError: formRequestErrorHandler(form, (error) => {
        if (!error.fields) navigate('/forgot-password');
      }),
    },
  );

  return (
    <CenteredContainer className="form" vertical style={{ width: '100%' }}>
      <div className="title" style={{ marginBottom: '24px' }}>
        Change Password
      </div>

      <Form
        form={form}
        onFinish={request}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        disabled={isLoading}
        style={{ width: '90%', maxWidth: '400px' }}
      >
        <div className="description">Please enter a new password below...</div>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Password must not be empty' }]}
        >
          <Input type="password" placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="confirm_password"
          rules={[
            { required: true, message: 'Confirm password must not be empty' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Passwords don't match"));
              },
            }),
          ]}
        >
          <Input type="password" placeholder="Confirm password" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="form-button light"
          >
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </CenteredContainer>
  );
};

export default ChangePassword;
