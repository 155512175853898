import { FC, PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { cssObjectToString } from '../../../utils';

import './navigation-button.component.css';

export const NavigationButton: FC<
  PropsWithChildren<{
    label?: string;
    large?: boolean;
    to?: string;
    target?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
  }>
> = ({ children, label, large, to, target, onClick, style }) => {
  const location = useLocation();

  const active = location.pathname === to;

  const className = cssObjectToString({
    'navigation-button': true,
    active,
    large,
  });

  if (to) {
    return (
      <Link
        className={className}
        style={style}
        to={to}
        target={target}
        onClick={onClick}
      >
        <div className="icon-circle">{children}</div>

        {label && <div style={{ marginLeft: '10px' }}>{label}</div>}
      </Link>
    );
  }

  return (
    <div
      className={className}
      style={style}
      onClick={active ? undefined : onClick}
    >
      <div className="icon-circle">{children}</div>

      {label && <div style={{ marginLeft: '10px' }}>{label}</div>}
    </div>
  );
};
