import { AppLayout } from '../../../layouts/app/app-layout.component';
import { RouteData } from '../../../utils';
import Wallet from './wallet.component';

export const WalletRoutes: RouteData[] = [
  {
    path: 'wallet',
    title: 'Wallet',
    component: Wallet,
    authRequired: true,
    flatHeader: true,
    layout: AppLayout,
  },
];
