import { AxiosRequestConfig } from 'axios';

import { KnowledgeEntryParams } from './knowledge-entry-params.interface';

export const KnowledgeEntryTransloaditAssemblyListRequest = (
  characterId: string,
): AxiosRequestConfig => {
  return {
    url: `v1/knowledge/transloadit/assembly/list`,
    method: 'get',
    params: { characterId },
    transformResponse: (response: string) => {
      const {
        data,
        ...rest
      }: {
        data: { knowledgeEntries: KnowledgeEntryParams[]; itemData: any }[];
      } = JSON.parse(response);

      return {
        ...rest,
        data: data.map(({ knowledgeEntries, ...item }) => {
          return {
            ...item,
            knowledgeEntries: knowledgeEntries.map((knowledgeEntry) => {
              return {
                ...knowledgeEntry,
                created: new Date(knowledgeEntry.created),
              };
            }),
          };
        }),
      };
    },
  };
};
