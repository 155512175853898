import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { NextSvg } from './menu-drawer-link-svg.component';

import './menu-drawer-link.component.css';

export const MenuDrawerLink: FC<{
  icon:
    | React.ComponentType<
        CustomIconComponentProps | React.SVGProps<SVGSVGElement>
      >
    | React.ForwardRefExoticComponent<CustomIconComponentProps>;
  label: string;
  to?: string;
  target?: string;
  onClick?: () => void;
  iconWidth?: string;
}> = ({ icon, label, to, target, onClick, iconWidth }) => {
  if (to) {
    return (
      <Link
        className="menu-drawer-link"
        to={to}
        target={target}
        onClick={onClick}
      >
        <Space direction="horizontal" size={0}>
          <Icon
            component={icon}
            style={{ color: 'white', marginRight: '12px', width: iconWidth }}
          />

          <div>{label}</div>
        </Space>

        <Icon component={NextSvg} style={{ color: 'white' }} />
      </Link>
    );
  }

  return (
    <button className="menu-drawer-link" onClick={onClick}>
      <Space direction="horizontal" size={0}>
        <Icon
          component={icon}
          style={{ color: 'white', marginRight: '12px', width: iconWidth }}
        />

        <div>{label}</div>
      </Space>

      <Icon component={NextSvg} style={{ color: 'white' }} />
    </button>
  );
};
