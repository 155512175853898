import { Avatar, Space } from 'antd';
import { Link } from 'react-router-dom';

import { If, Loader } from '../../../common';
import { useAPIRequest, ChatListRequest, ChatParams } from '../../../api';
import { limitTextLength } from '../../../utils';

import './chat-list.component.css';

const ChatList: React.FC<{
  style?: React.CSSProperties;
  [key: string]: any;
}> = ({ style }) => {
  const { response, isLoading, error } = useAPIRequest<{ data: ChatParams[] }>(
    () => ChatListRequest(),
  );

  const hasChats = (response?.data?.length || 0) > 0;

  return (
    <div className="chat-list-container" style={style}>
      <Loader isLoading={isLoading} error={error}>
        <If condition={hasChats}>
          <Space direction="vertical" size={0} className="chat-list">
            {response?.data.map((chat: ChatParams) => {
              return (
                <Link
                  to={`/${chat.character.handle}/chat`}
                  key={'chat-' + chat.id}
                >
                  <Space direction="horizontal" size={12} className="chat-item">
                    <Avatar size={48} src={chat.character.avatarUrl} />

                    <Space direction="vertical">
                      <div className="character-name">
                        {chat.character.name}
                      </div>
                      <div className="message">
                        {limitTextLength(chat.lastMessage?.response, 32)}
                      </div>
                    </Space>
                  </Space>
                </Link>
              );
            })}
          </Space>
        </If>

        <If condition={response?.data && !hasChats}>
          <div className="no-chats">
            <div>No messages yet...</div>
          </div>
        </If>
      </Loader>
    </div>
  );
};

export default ChatList;
