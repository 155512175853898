import { Space } from 'antd';

const MediaItemNote: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Space
      direction="horizontal"
      align="center"
      size={2}
      style={{
        justifyContent: 'center',
        backgroundColor: '#000000CC',
        padding: '5px 10px',
        borderRadius: '10px',
      }}
    >
      <span style={{ fontSize: '12px', color: '#1677ff' }}>{text}</span>
    </Space>
  );
};

export default MediaItemNote;
