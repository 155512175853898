import { useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import Icon, { ManOutlined, WomanOutlined } from '@ant-design/icons';

import { If, ProfileSvg, SelectAutoClose } from '../../../common';
import { useInitStore } from '../../../stores';
import { NotificationType, createNotification } from '../../../utils';
import {
  useAPIRequest,
  formRequestErrorHandler,
  UpdateUserRequest,
  UserParams,
  Gender,
} from '../../../api';

import '../../../common/form.css';

const { Option } = Select;

const ProfileDetails: React.FC = () => {
  const [form] = Form.useForm();

  const { user, setUser } = useInitStore();

  const [gender, setGender] = useState<Gender>();

  const { request, isLoading } = useAPIRequest<UserParams>(UpdateUserRequest, {
    immediate: false,
    onSuccess: (data, key) => {
      form.setFieldsValue(data);

      if (data.gender) setGender(data.gender);

      createNotification({
        key,
        message: 'Changes saved successfully',
        type: NotificationType.Success,
      });

      setUser(data);
    },
    onError: formRequestErrorHandler(form),
  });

  return (
    <div className="form" style={{ width: '100%', padding: 0 }}>
      <Form
        form={form}
        onFinish={request}
        initialValues={user}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        disabled={isLoading}
        style={{ width: '100%', maxWidth: '400px' }}
      >
        <Form.Item
          name="name"
          label={
            <>
              <Icon
                component={ProfileSvg}
                style={{ width: '24px', marginRight: '5px' }}
              />

              <div>Name</div>
            </>
          }
          rules={[
            { required: true, message: 'Name is required' },
            {
              max: 30,
              message: 'Name must be maximum 30 characters',
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item
          name="gender"
          label={
            <>
              <If condition={(gender || user?.gender) === Gender.Male}>
                <ManOutlined style={{ width: '24px', marginRight: '5px' }} />
              </If>

              <If condition={(gender || user?.gender) === Gender.Female}>
                <WomanOutlined style={{ width: '24px', marginRight: '5px' }} />
              </If>

              <div>Gender</div>
            </>
          }
          rules={[{ required: true, message: 'Gender is required' }]}
        >
          <SelectAutoClose placeholder="Select gender" onChange={setGender}>
            <Option value={Gender.Male}>Male</Option>
            <Option value={Gender.Female}>Female</Option>
          </SelectAutoClose>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="form-button"
            style={{ marginTop: '8px' }}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProfileDetails;
