import { FC } from 'react';

import MessageTextItems from './message-text-items.component';
import { TextType } from './text-type.enum';

const MessageTextParser: FC<{
  value?: string;
  style?: React.CSSProperties;
}> = ({ value, style }) => {
  const parseMarkdown = (input: string) => {
    return input
      .split(/(\*\*.*?\*\*)/g)
      .filter((item) => item)
      .map((item) => {
        if (item.charAt(0) === '*') {
          return {
            text: item.substring(2, item.length - 2),
            type: TextType.Bold,
          };
        } else {
          return { text: item, type: TextType.Normal };
        }
      });
  };

  const parseText = (value?: string) => {
    if (!value) return [];

    const regex = /```(\w+)?\s*([\s\S]+?)```|`([^`]+)`|([^`]+)/g;
    const result = [];

    let match;

    while ((match = regex.exec(value))) {
      if (match[2]) {
        // code block
        result.push({
          language: match[1]?.trim(),
          text: match[2]?.trim(),
          type: TextType.Code,
        });
      } else if (match[3]) {
        // inline code
        result.push({
          text: match[3].trim(),
          type: TextType.InlineCode,
        });
      } else if (match[4] && match[4].trim() !== '') {
        result.push(...parseMarkdown(match[4].trim()));
      }
    }

    return result;
  };

  return (
    <MessageTextItems
      items={parseText(value)}
      style={{
        ...style,
        whiteSpace: 'pre-wrap',
        wordWrap: 'normal',
      }}
    />
  );
};

export default MessageTextParser;
