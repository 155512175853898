import { AxiosRequestConfig } from 'axios';

import { ChangePasswordParams } from './change-password-params.interface';

export const ChangePasswordRequest = (
  params: ChangePasswordParams,
): AxiosRequestConfig => {
  return {
    url: 'v1/user/password',
    method: 'put',
    data: params,
  };
};
