import create from 'zustand';
import createVanilla from 'zustand/vanilla';

import { UserParams, SubscriptionPlan, ResponseType } from '../api';
import { isLocal } from '../common';
import { QueryValues } from '../utils';
import {
  clearDatadogLogsUser,
  setDatadogLogsUser,
} from '../third-party/libraries/datadog-logs';
import {
  clearMixpanelUser,
  setMixpanelUser,
} from '../third-party/libraries/mixpanel';

interface InitStoreState {
  initialized: boolean;
  user?: UserParams;
  setUser: (user: UserParams) => void;
  setEmail: (email: string, emailConfirmed?: boolean) => void;
  setBalance: (balance: number) => void;
  setResponseType: (responseType: ResponseType) => void;
  subscriptionPlans: SubscriptionPlan[];
  setSubscriptionPlans: (plans: SubscriptionPlan[]) => void;
  resetInitData: () => void;
  setInitialized: () => void;
  forwardedToken?: string; // invitation or forgot password
  setForwardedToken: (token: string) => void;
  queryValues?: QueryValues;
  setQueryValues: (queryValues: QueryValues) => void;
}

export const initStore = createVanilla<InitStoreState>((set) => ({
  initialized: false,
  subscriptionPlans: [],
  setUser: (user: UserParams) => {
    set({ user });

    if (!isLocal()) {
      // setDatadogRumUser(user);
      setDatadogLogsUser(user);
    }

    setMixpanelUser(user);
  },
  setEmail: (email: string, emailConfirmed?: boolean) => {
    set((prev) => ({
      user: { ...(prev.user as UserParams), email, emailConfirmed },
    }));
  },
  setBalance: (balance: number) => {
    set((prev) => ({
      user: { ...(prev.user as UserParams), balance },
    }));
  },
  setResponseType: (responseType: ResponseType) => {
    set((prev) => ({
      user: { ...(prev.user as UserParams), responseType },
    }));
  },
  setSubscriptionPlans: (subscriptionPlans: SubscriptionPlan[]) => {
    set({ subscriptionPlans });
  },
  resetInitData: () => {
    set({ initialized: false, user: undefined });

    if (!isLocal()) {
      // clearDatadogRumUser();
      clearDatadogLogsUser();
    }

    clearMixpanelUser();
  },
  setInitialized: () => set({ initialized: true }),
  setForwardedToken: (token: string) => {
    set({ forwardedToken: token });
  },
  setQueryValues: (queryValues: QueryValues) => set({ queryValues }),
}));

export const useInitStore = create<InitStoreState>(initStore);
