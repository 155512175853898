import { Space } from 'antd';

import Highlight from './highlight.component';

import './highlights.component.css';

const Highlights: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <div className="highlights-container" style={style}>
      <div style={{ marginBottom: '34px' }}>
        <div className="title">
          Unlimited earnings.
          <br />
          Zero effort.
        </div>

        <div
          className="description"
          style={{ marginTop: '10px', padding: '20px' }}
        >
          We'll create a fully customized AI version of you that will multiply
          your reach, and earn money while you sleep
        </div>
      </div>

      <Space
        direction="horizontal"
        align="center"
        size="large"
        wrap
        style={{ justifyContent: 'center' }}
      >
        <Highlight
          imageUrl="chat.png"
          title="Chat with your fans 24/7"
          description={
            <>
              Authentic conversations with all of
              <br />
              your fans on autopilot
            </>
          }
        />

        <Highlight
          imageUrl="call.png"
          title={
            <>
              Voice calls in 30+
              <br />
              languages
            </>
          }
          description={
            <>
              Your voice and personality, accessible anytime, anywhere, in any
              language
            </>
          }
        />

        <Highlight
          imageUrl="microphone.png"
          title="Sell content & products"
          description={
            <>Personalized, contextualized, content and product upsells</>
          }
        />
      </Space>
    </div>
  );
};

export default Highlights;
