import { Form } from 'antd';
import { useState } from 'react';

import { Step } from './step.interface';
import {
  CreatorOnboardingParams,
  formRequestErrorHandler,
  getCreatorOnboardingRequests,
  useRequests,
} from '../../../../api';
import { CRUDKey } from '../../../../common';
import { StepProps } from './step-props.interface';

export const useSteps = (steps: Step[]): StepProps => {
  const [form] = Form.useForm();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [latestStep, setLatestStep] = useState<number>(0);

  const getLatestStep = (data: CreatorOnboardingParams) => {
    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];

      let params: string[] = [];

      if (step.params) params = params.concat(step.params);

      const stepComplete = step.isStepComplete
        ? step.isStepComplete(data)
        : step.params?.every((param) => (data as any)[param]);

      if (!stepComplete) return i;
    }

    return steps.length - 1;
  };

  const hasPrevious = currentStep > 0 && !steps[currentStep - 1].disableRevisit;
  const hasNext = currentStep <= latestStep;

  const previous = () => {
    if (hasPrevious) setCurrentStep(currentStep - 1);
  };

  const next = () => {
    if (hasNext) setCurrentStep(currentStep + 1);
  };

  const { request, response, setResponse, isLoading, error } = useRequests({
    requests: getCreatorOnboardingRequests(),
    callbacks: {
      onSuccess: (data, key) => {
        form.setFieldsValue(data);

        const latestStep = getLatestStep(data);

        setLatestStep(latestStep);

        if (key === CRUDKey.Read) {
          setCurrentStep(latestStep);
        } else if (key === CRUDKey.Update) next();
      },
      onError: formRequestErrorHandler(form),
    },
  });

  const submit = (values: any) => {
    request.update(values);
  };

  return {
    steps,
    form,
    response,
    setResponse,
    isLoading,
    error,
    submit,
    currentStep,
    currentStepObject: steps[currentStep],
    previous,
    next,
    hasPrevious,
    hasNext,
  };
};
