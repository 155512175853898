import { AxiosRequestConfig } from 'axios';
import { CharacterParams } from '../character';

export const UpdateCharacterProfileRequest = (
  id: string,
  params: Omit<CharacterParams, 'id'>,
): AxiosRequestConfig => {
  return {
    url: `v1/character/${id}/profile`,
    method: 'put',
    data: params,
  };
};
