import { AxiosRequestConfig } from 'axios';

export const VoiceSampleTransloaditAssemblyListRequest = (
  userId: string,
): AxiosRequestConfig => {
  return {
    url: `v1/creator/voice-sample/transloadit/assembly/list`,
    method: 'get',
    params: { userId },
  };
};
