import { FC, PropsWithChildren } from 'react';

import { cssObjectToString } from '../../../utils';

import './title-icon.component.css';

export const TitleIcon: FC<
  PropsWithChildren<{
    label: string;
    center?: boolean;
    style?: React.CSSProperties;
  }>
> = ({ children, label, center, style }) => {
  return (
    <div
      className={cssObjectToString({ 'title-icon': true, center })}
      style={style}
    >
      {children && <div style={{ marginRight: '6px' }}>{children}</div>}

      <div>{label}</div>
    </div>
  );
};
