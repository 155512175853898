import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import ChatList from './character-chat-list.component';
import CharacterChat from './character-chat.component';
import { If, useLayoutDisplay } from '../../../../common';
import { CharacterChatParams } from '../../../../api';

import './character-messages.component.css';

export const CharacterMessages: FC<{ style?: React.CSSProperties }> = ({
  style,
}) => {
  const params = useParams();

  const [selectedChat, setSelectedChat] = useState<CharacterChatParams>();

  const { isMobile } = useLayoutDisplay();

  return (
    <div className="character-messages-container" style={style}>
      <ChatList
        value={selectedChat}
        onChange={setSelectedChat}
        style={{
          width: '100%',
          maxWidth: isMobile ? '100%' : '500px',
          marginRight: isMobile ? '0px' : '20px',
          display:
            !isMobile || (isMobile && !selectedChat) ? undefined : 'none',
        }}
      />

      <If condition={!isMobile || (isMobile && selectedChat)}>
        <CharacterChat
          chat={selectedChat}
          viewMedia={(media) =>
            window.open(
              `/creator/character/${params.characterId}/media/${media.id}`,
              '_blank',
            )
          }
          resetChat={() => setSelectedChat(undefined)}
          style={{ flex: 1, maxWidth: '800px' }}
        />
      </If>
    </div>
  );
};
