import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ConfigProvider, Space, Spin, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

import {
  CharacterParams,
  useAPIRequest,
  TransloaditAssemblyParams,
  TransloaditAssemblyStatus,
  KnowledgeEntryParams,
  KnowledgeEntryListRequest,
  KnowledgeEntryTransloaditAssemblyListRequest,
  CreateKnowledgeEntryTransloaditAssembliesRequest,
  DeleteKnowledgeEntryRequest,
  ReadCharacterRequest,
} from '../../../../api';
import { EmptyTable, If, Loader, WideButton } from '../../../../common';
import AddKnowledge from './add-knowledge.component';
import {
  NotificationType,
  confirmDangerousAction,
  createNotification,
  formatDate,
} from '../../../../utils';

const Knowledge: React.FC = () => {
  const params = useParams();
  const list = useRef<KnowledgeEntryParams[]>([]);

  const { response: characterResponse } = useAPIRequest<CharacterParams>(() =>
    ReadCharacterRequest(params.characterId!),
  );

  const { request, response, setResponse, error, isLoading } = useAPIRequest<{
    data: KnowledgeEntryParams[];
  }>(KnowledgeEntryListRequest, {
    immediate: false,
    onSuccess: (value) => {
      list.current = value.data;
    },
  });

  const {
    request: getTransloaditAssemblyList,
    isLoading: isLoadingTransloaditAssemblyList,
    response: transloaditAssemblyListResponse,
  } = useAPIRequest<{
    totalFilesProcessing: number;
    data: TransloaditAssemblyParams[];
  }>(KnowledgeEntryTransloaditAssemblyListRequest, {
    immediate: false,
    shouldRetry: (value) => value.data.length > 0,
    retryInterval: 3000,
    onSuccess: (value) => {
      let newKnowledge: KnowledgeEntryParams[] = [];

      value.data.map((assembly) => {
        if (assembly.status === TransloaditAssemblyStatus.Completed) {
          newKnowledge = newKnowledge.concat(assembly.knowledgeEntries);
        } else if (assembly.status === TransloaditAssemblyStatus.Error) {
          createNotification({
            key: 'serverError',
            message: assembly.error || 'Upload error',
            type: NotificationType.Error,
          });
        }
      });

      if (newKnowledge.length > 0) {
        // setResponse is problematic, different TransloaditAssemblyListRequest responses can override each other
        // as the request keeps retrying with the same js context until all the assemblies are completed
        // using a ref as a helper workaround

        list.current = list.current.concat(newKnowledge);

        setResponse({ data: list.current });
      }
    },
  });

  const { request: createAssemblies } =
    useAPIRequest<TransloaditAssemblyParams>(
      CreateKnowledgeEntryTransloaditAssembliesRequest,
      {
        immediate: false,
        onSuccess: () => {
          if (!isLoadingTransloaditAssemblyList) {
            getTransloaditAssemblyList(characterResponse?.id);
          }
        },
      },
    );

  const { request: deleteEntry } = useAPIRequest<KnowledgeEntryParams>(
    DeleteKnowledgeEntryRequest,
    {
      immediate: false,
      onSuccess: (response) => {
        const index = list.current.findIndex((item) => item.id === response.id);

        if (index !== -1) {
          const newKnowledge: KnowledgeEntryParams[] = list.current.concat();

          newKnowledge.splice(index, 1);

          list.current = newKnowledge;

          setResponse({ data: list.current });
        }
      },
    },
  );

  useEffect(() => {
    if (characterResponse) {
      request(characterResponse.id);
      getTransloaditAssemblyList(characterResponse.id);
    }
  }, [characterResponse]);

  const afterUploadRef = useRef(createAssemblies);

  afterUploadRef.current = createAssemblies;

  const totalFilesProcessing =
    transloaditAssemblyListResponse?.totalFilesProcessing || 0;

  const columns: ColumnsType<KnowledgeEntryParams> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, { url }: { url: string }) => {
        return (
          <a
            href={url}
            target="_blank"
            style={{ color: 'var(--white)', textDecoration: 'underline' }}
          >
            {name}
          </a>
        );
      },
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Date Created',
      dataIndex: 'created',
      key: 'created',
      render: (created: Date) => {
        return <span>{formatDate(created)}</span>;
      },
    },
    {
      title: 'Delete',
      dataIndex: 'created',
      key: 'delete',
      render: (created: Date, { id }: { id: string }) => {
        return (
          <WideButton
            label="Delete"
            style={{ width: '100%', maxWidth: '80px' }}
            red
            onClick={() =>
              confirmDangerousAction({
                action: 'Delete',
                name: 'this file',
                onConfirm: () => deleteEntry(id),
              })
            }
          />
        );
      },
    },
  ];

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', padding: '20px' }}
      size={0}
    >
      <Loader error={error} isLoading={isLoading}>
        <AddKnowledge
          characterId={characterResponse?.id}
          afterUploadRef={afterUploadRef}
          style={{ marginBottom: '20px' }}
        />

        <ConfigProvider renderEmpty={EmptyTable}>
          <Table
            dataSource={response?.data}
            columns={columns}
            rowKey={(row) => row.id}
            style={{ padding: '20px' }}
          />
        </ConfigProvider>

        <If condition={totalFilesProcessing > 0}>
          <Space
            direction="horizontal"
            size="middle"
            style={{ color: 'var(--white)', marginTop: '40px' }}
          >
            <div>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
            {`Processing ${totalFilesProcessing} file${
              totalFilesProcessing !== 1 ? 's' : ''
            }...`}
          </Space>
        </If>
      </Loader>
    </Space>
  );
};

export default Knowledge;
