import { Drawer, Space } from 'antd';
import Icon from '@ant-design/icons';
import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import WalletDrawerTitle from './wallet-drawer-title.component';
import {
  useAPIRequest,
  BundlesRequest,
  TopUpCheckoutRequest,
  Bundle,
} from '../../../api';
import { useChatStore, useInitStore, useMiscStore } from '../../../stores';
import { useLayoutDisplay } from '../../hooks';
import { Loader } from '../loader';
import { CloseSvg } from '../common-svg.component';
import { cssObjectToString } from '../../../utils';

import './wallet-drawer.component.css';

export const WalletDrawer: FC = () => {
  const location = useLocation();

  const user = useInitStore((state) => state.user);
  const character = useChatStore((state) => state.character);

  const { walletInsufficientBalance, walletOpen, setWalletOpen } =
    useMiscStore();

  const { isMobile } = useLayoutDisplay();

  const [selected, setSelected] = useState<Bundle>();

  const { response: bundles, isLoading: isLoadingBundles } = useAPIRequest<{
    data: Bundle[];
  }>(BundlesRequest);

  const { request } = useAPIRequest<{
    url: string;
  }>(TopUpCheckoutRequest, {
    immediate: false,
    onSuccess: ({ url }) => {
      if (url) window.location.href = url;
    },
  });

  const confirm = () => {
    if (!selected) return;

    request({
      characterId: character?.id,
      priceId: selected?.id,
      route: location.pathname,
    });
  };

  let height: number | undefined = undefined;

  if (isMobile) height = walletInsufficientBalance ? 437 : 393;

  return (
    <Drawer
      open={walletOpen}
      placement={isMobile ? 'bottom' : 'right'}
      closeIcon={null}
      onClose={() => setWalletOpen(false)}
      height={height}
    >
      <div className="wallet-drawer-container">
        <div className="button-circle" onClick={() => setWalletOpen(false)}>
          <Icon component={CloseSvg} />
        </div>

        <WalletDrawerTitle
          insufficientBalance={!!walletInsufficientBalance}
          style={{ marginBottom: '28px' }}
        />

        <Space
          direction="vertical"
          align="center"
          size={4}
          style={{ width: '100%', marginBottom: '24px' }}
        >
          <div className="balance-value">${user?.balance?.toFixed(2)}</div>

          <div className="balance-title">Balance</div>
        </Space>

        <Loader isLoading={isLoadingBundles}>
          <Space
            direction="horizontal"
            align="center"
            size="middle"
            className={cssObjectToString({
              'bundles-container': true,
              mobile: isMobile,
            })}
            wrap={!isMobile}
          >
            {bundles?.data.map((bundle: Bundle) => {
              return (
                <div
                  className={cssObjectToString({
                    bundle: true,
                    active: bundle.id === selected?.id,
                  })}
                  key={bundle.id}
                  onClick={() => setSelected(bundle)}
                >
                  ${bundle.price}
                </div>
              );
            })}
          </Space>
        </Loader>

        <button
          className={cssObjectToString({
            'confirm-button': true,
            active: selected,
          })}
          onClick={confirm}
          disabled={!selected}
        >
          Confirm
        </button>
      </div>
    </Drawer>
  );
};
