import { AxiosRequestConfig } from 'axios';

import { SignUpParams } from './sign-up-params.interface';

export const SignUpRequest = (params: SignUpParams): AxiosRequestConfig => {
  return {
    url: 'v1/user/signup',
    method: 'post',
    data: params,
  };
};
