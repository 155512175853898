import { useRef, useState } from 'react';
import { Avatar } from 'antd';
import Icon from '@ant-design/icons';
import { DashboardModal } from '@uppy/react';
import Uppy from '@uppy/core';
import Transloadit from '@uppy/transloadit';

import { If } from '../if.component';
import { ProfileSvg, UploadSvg } from '../common-svg.component';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import './avatar-uploader.component.css';
import { cssObjectToString } from '../../../utils';

export const AvatarUploader: React.FC<{
  initialValue?: string;
  templateId: string;
  fields?: { [key: string]: any };
  size?: number;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}> = ({ initialValue, templateId, fields, size, width, height, style }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [preview, setPreview] = useState<string>();

  const templateIdRef = useRef<string>(templateId);
  const fieldsRef = useRef<{ [key: string]: any } | undefined>(fields);

  templateIdRef.current = templateId;
  fieldsRef.current = fields;

  const [uppy] = useState(() => {
    return new Uppy({
      allowMultipleUploads: false,
      allowMultipleUploadBatches: false,
      restrictions: { maxNumberOfFiles: 1 },
    })
      .use(Transloadit, {
        assemblyOptions: () => ({
          params: {
            auth: { key: process.env.REACT_APP_TRANSLOADIT_AUTH_KEY! },
            template_id: templateIdRef.current,
          },
          fields: fieldsRef.current,
        }),
      })
      .on('upload-success', (value) => {
        setPreview(value?.preview);
      })
      .on('complete', () => setOpen(false));
  });

  return (
    <div style={{ position: 'relative', width: 'fit-content', ...style }}>
      <If condition={size}>
        <Avatar
          src={preview || initialValue}
          icon={
            !(preview || initialValue) && (
              <Icon component={ProfileSvg} style={{ width: '45px' }} />
            )
          }
          size={size}
          className={cssObjectToString({
            ['avatar-uploader']: true,
            ['no-image']: preview || initialValue,
          })}
          onClick={() => setOpen(true)}
        />
      </If>

      <If condition={!size}>
        <If condition={preview || initialValue}>
          <img
            src={preview || initialValue}
            style={{ width: width + 'px', height: height + 'px' }}
            className="avatar-uploader"
            onClick={() => setOpen(true)}
          />
        </If>

        <If condition={!(preview || initialValue)}>
          <div
            style={{ width: width + 'px', height: height + 'px' }}
            className="avatar-uploader no-image"
            onClick={() => setOpen(true)}
          >
            <Icon component={ProfileSvg} style={{ width: '45px' }} />
          </div>
        </If>
      </If>

      <div className="upload-icon" onClick={() => setOpen(true)}>
        <Icon component={UploadSvg} style={{ width: '16px' }} />
      </div>

      <DashboardModal
        uppy={uppy}
        open={open}
        onRequestClose={() => setOpen(false)}
      />
    </div>
  );
};
