import { useEffect, useMemo } from 'react';

import {
  useAPIRequest,
  UserParams,
  ConfirmAgeWarning,
} from '../../../../../api';
import { ClassicModal, PoliciesLinks, WideButton } from '../../../../../common';
import {
  useChatStore,
  useInitStore,
  usePersistedStore,
} from '../../../../../stores';

import './age-warning-modal.component.css';

const AgeWarningModal: React.FC = () => {
  const { user } = useInitStore();
  const { character } = useChatStore();
  const {
    ageWarningConfirmed: warningConfirmed,
    setAgeWarningConfirmed: setWarningConfirmed,
  } = usePersistedStore();

  const isOpen = useMemo(() => {
    return character?.nsfw && !user?.ageWarningConfirmed && !warningConfirmed;
  }, [character, user, warningConfirmed]);

  const { request, isLoading } = useAPIRequest<UserParams>(ConfirmAgeWarning, {
    immediate: false,
  });

  // not directly on click so that it can persist async once the user signs in if not already signed in
  useEffect(() => {
    if (user && !user.ageWarningConfirmed && warningConfirmed) request();
  }, [user, warningConfirmed]);

  return (
    <ClassicModal isOpen={isOpen} width="90%" maxWidth="600px" opaque>
      <div className="age-warning-modal-container">
        <div
          style={{
            textAlign: 'center',
            marginBottom: '10px',
            fontSize: '30px',
            color: 'var(--white)',
          }}
        >
          Are you over 18 years old?
        </div>

        <div
          style={{
            textAlign: 'center',
            marginTop: '10px',
            fontSize: '18px',
            color: 'var(--grey6)',
            marginBottom: '30px',
          }}
        >
          Please verify your age to view this profile
        </div>

        <div className="buttons-container">
          <WideButton
            label="I am 18 or older - Enter"
            accent
            onClick={setWarningConfirmed}
            disabled={isLoading}
            style={{
              marginRight: '10px',
              marginBottom: '10px',
              maxWidth: '200px',
            }}
          />

          <WideButton
            label="I am under 18 - Exit"
            light
            to="/"
            disabled={isLoading}
            style={{ maxWidth: '200px' }}
          />
        </div>

        <PoliciesLinks
          action="viewing this profile"
          style={{ marginTop: '20px' }}
        />
      </div>
    </ClassicModal>
  );
};

export default AgeWarningModal;
