import { RouteData } from '../../utils';
import { LegalRoutes } from './legal/legal.routes';
import { CharacterRoutes } from './character/character.routes';
import { BrowseRoutes } from './browse/browse.routes';
import { WalletRoutes } from './wallet/wallet.routes';
import { AccountRoutes } from './account/account.routes';
import { MessagesRoutes } from './messages/messages.routes';
import { IndexRoutes } from './index/index.routes';
import { CreatorRoutes } from './creator/creator.routes';
// import { SubscriptionRoutes } from './subscription/subscription.routes';

export const AppRoutes: RouteData[] = [
  ...IndexRoutes,
  ...LegalRoutes,
  ...BrowseRoutes,
  // ...SubscriptionRoutes,
  ...WalletRoutes,
  ...AccountRoutes,
  ...MessagesRoutes,
  ...CreatorRoutes,
  ...CharacterRoutes, // must be last, route can only be a username if it's not any of the routes above
];
