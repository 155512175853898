import { AxiosRequestConfig } from 'axios';
import { CharacterParams } from '../character';

export const UpdateCharacterPersonalityRequest = (
  id: string,
  params: Omit<CharacterParams, 'id'>,
): AxiosRequestConfig => {
  return {
    url: `v1/character/${id}/personality`,
    method: 'put',
    data: params,
  };
};
