export const MicrophoneSvg = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.001 22.9999V18.9258"
      stroke="var(--grey2)"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 10.7783C20 12.9393 19.1571 15.0119 17.6569 16.5399C16.1566 18.068 14.1217 18.9265 12 18.9265C9.87827 18.9265 7.84344 18.068 6.34315 16.5399C4.84285 15.0119 4 12.9393 4 10.7783"
      stroke="var(--grey2)"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2992 10.7778C15.2992 11.6854 14.945 12.5504 14.323 13.1839C13.7019 13.8164 12.8655 14.1667 11.9992 14.1667C11.133 14.1667 10.2965 13.8164 9.67545 13.1839C9.05346 12.5504 8.69922 11.6854 8.69922 10.7778V5.88889C8.69922 4.98131 9.05346 4.11632 9.67545 3.48281C10.2965 2.85023 11.133 2.5 11.9992 2.5C12.8655 2.5 13.7019 2.85023 14.323 3.48281C14.945 4.11632 15.2992 4.98131 15.2992 5.88889V10.7778Z"
      stroke="var(--grey2)"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StopSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="3"
      width="18"
      height="18"
      rx="4"
      stroke="var(--grey2)"
      strokeWidth="3"
    />
  </svg>
);

export const SendSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0006 4L13.5006 22C13.4567 22.0957 13.3862 22.1769 13.2976 22.2338C13.209 22.2906 13.1059 22.3209 13.0006 22.3209C12.8952 22.3209 12.7921 22.2906 12.7035 22.2338C12.6149 22.1769 12.5444 22.0957 12.5006 22L9.00056 15L2.00056 11.5C1.90482 11.4561 1.82369 11.3857 1.7668 11.2971C1.70992 11.2084 1.67969 11.1053 1.67969 11C1.67969 10.8947 1.70992 10.7916 1.7668 10.7029C1.82369 10.6143 1.90482 10.5439 2.00056 10.5L20.0006 4Z"
      stroke="#2C2E30"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
