import { FC } from 'react';
import { Layout } from 'antd';
import Icon from '@ant-design/icons';

import { LogoIcon } from '../../common';
import { RouteData } from '../../utils';

const { Header } = Layout;

const AuthHeader: FC<{
  routeData: RouteData;
  backPath: string;
  width?: string;
  maxWidth?: string;
  style?: React.CSSProperties;
  [key: string]: any;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}> = ({ routeData, backPath, width, maxWidth, style, ...props }) => {
  return (
    <Header
      style={{
        width,
        maxWidth,
        borderRadius: routeData.flatHeader ? 0 : undefined,
        ...style,
      }}
      {...props}
    >
      <div className="header-container">
        <div className="logo">
          <Icon component={LogoIcon} style={{ marginRight: '10px' }} />
          Swoons
        </div>
      </div>
    </Header>
  );
};

export default AuthHeader;
