import { Space } from 'antd';

import { LegalItemUnderlined } from '../interfaces';
import { LegalContent } from './legal-content.component';

export const LegalUnderlined: React.FC<{
  data?: LegalItemUnderlined[];
  indexes: number[];
  style?: React.CSSProperties;
}> = ({ data, indexes, style }) => {
  if (!data) return <></>;

  return (
    <Space direction="vertical">
      {data.map((value, index) => {
        const newIndexes = [...indexes, index + 1];

        return (
          <Space
            direction="vertical"
            style={style}
            key={'legalUnderlined_' + newIndexes.join('-')}
          >
            <u>{value.title}</u>

            <LegalContent data={value.content} indexes={newIndexes} />
          </Space>
        );
      })}
    </Space>
  );
};
