import { AxiosRequestConfig } from 'axios';

export const CreateCharacterRequest = (params: {
  name: string;
  handle: string;
}): AxiosRequestConfig => {
  return {
    url: 'v1/character',
    method: 'post',
    data: params,
  };
};
