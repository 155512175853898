import { CRUDKey } from '../../../common';
import { ImmediateRequest, Requests } from '../../hooks';
import { CreatorOnboardingParams } from './creator-onboarding-params.interface';
import { ReadCreatorOnboardingRequest } from './read-creator-onboarding.request';
import { UpdateCreatorOnboardingRequest } from './update-creator-onboarding.request';

export function getCreatorOnboardingRequests(): Requests<CreatorOnboardingParams> {
  return {
    [CRUDKey.Read]: ImmediateRequest(() => ReadCreatorOnboardingRequest()),
    [CRUDKey.Update]: (params) => UpdateCreatorOnboardingRequest(params),
  };
}
