import { AuthDrawerState, WideButton } from '../../../../common';
import { useMiscStore } from '../../../../stores';

import './lander.component.css';

const Lander: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const { setAuthOpen } = useMiscStore();

  const backgroundImage: string[] = [
    'linear-gradient(rgba(0, 0, 0, 0) 1.4%, rgba(0, 0, 0, 0.39) 59.18%, rgb(0, 0, 0) 93%)',
    'url(/cover.jpg)',
  ];

  return (
    <div className="lander-container" style={style}>
      <div
        className="background"
        style={{ backgroundImage: backgroundImage.join(', ') }}
      >
        <div className="center">
          <div className="title">
            Create Your AI Clone
            <br />
            and Start Earning
          </div>

          <div className="description" style={{ marginTop: '10px' }}>
            Engage Your Fans with 24/7 Chats,
            <br />
            Unlimited Content, and Live Calls - on Autopilot
          </div>

          <WideButton
            label="Get Started"
            onClick={() => setAuthOpen(true, AuthDrawerState.SignUp)}
            accent
            style={{ height: '60px', maxWidth: '120px', marginTop: '16px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Lander;
