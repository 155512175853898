import { PromiseLifeCycleConfig } from './interfaces';

export function promiseLifecycle<T = any, E = any>({
  factory,
  onSuccess,
  onError,
  onStart: onStart,
  onComplete: onComplete,
}: PromiseLifeCycleConfig<T, E>) {
  return new Promise(async (resolve, reject) => {
    if (onStart) onStart();

    try {
      const result = await factory();

      if (onSuccess) onSuccess(result);

      resolve(result);
    } catch (e: any) {
      if (onError) onError(e);

      reject(e);
    }

    if (onComplete) onComplete();
  });
}
