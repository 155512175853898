import { FC } from 'react';
import { Drawer } from 'antd';
import Icon from '@ant-design/icons';

import { useMiscStore } from '../../../stores';
import { useLayoutDisplay } from '../../hooks';
import { If } from '../if.component';
import { CloseSvg } from '../common-svg.component';
import { AuthDrawerState } from './auth-drawer-state.enum';
import SignUp from './sign-up.component';
import SignIn from './sign-in.component';
import ForgotPassword from './forgot-password.component';

export const AuthDrawer: FC = () => {
  const { authOpen, setAuthOpen, authState } = useMiscStore();

  const { isMobile } = useLayoutDisplay();

  return (
    <Drawer
      open={authOpen}
      placement={isMobile ? 'bottom' : 'right'}
      closeIcon={null}
      onClose={() => setAuthOpen(false)}
      height={500}
      maskClosable={true}
    >
      <div className="button-circle" onClick={() => setAuthOpen(false)}>
        <Icon component={CloseSvg} />
      </div>

      <If condition={authState === AuthDrawerState.SignIn}>
        <SignIn />
      </If>

      <If condition={authState === AuthDrawerState.SignUp}>
        <SignUp />
      </If>

      <If condition={authState === AuthDrawerState.ForgotPassword}>
        <ForgotPassword />
      </If>
    </Drawer>
  );
};
