import { useMemo, useRef } from 'react';

import { MediaType, Message } from '../../../../../api';
import { MessageListItem } from './message-list-item.interface';
import { ParsedMessagePosition } from './parsed-message-position.enum';
import { ParsedMessageType } from './parsed-message-type.enum';
import { formatShortDate, isToday } from '../../../../../utils';

export const useParsedMessages = ({
  messages,
  onChange,
  alwaysShowText,
}: {
  messages?: Message[];
  onChange?: () => void;
  alwaysShowText?: boolean;
}) => {
  const previousParsedMessagesLength = useRef<number>(0);
  const previousLastMessageResponse = useRef<string>();

  return useMemo(() => {
    if (!messages) return [];

    let lastDate: string | undefined = undefined;
    let messagesFromTodayOnly = true; // only has messages from today

    // console.log(messages.map((m) => m.sent));

    const newParsedMessages = messages.flatMap((message) => {
      const result: MessageListItem[] = [];

      let dateItems = 0;
      let messageDate: string;

      if (isToday(message.sent)) messageDate = 'Today';
      else {
        messageDate = formatShortDate(message.sent);

        messagesFromTodayOnly = false;
      }

      if (lastDate != messageDate) {
        result.push({ date: messageDate });

        // makes sure the first message item (excluding date item) has messageItemIndex 0
        // it's used to scroll to the last top message after loading more paginated messages
        dateItems = 1;
      }

      lastDate = messageDate;

      const hasProducts = message.products && message.products.length > 0;

      if (message.messageVoiceUrl) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-message-voice',
            messageItemIndex: result.length - dateItems,
            position: ParsedMessagePosition.Right,
            type: ParsedMessageType.Voice,
            textValue: alwaysShowText ? message.message : undefined,
            value: message.messageVoiceUrl,
            sent: message.sent,
          },
        });
      } else if (message.message) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-message-text',
            messageItemIndex: result.length - dateItems,
            position: ParsedMessagePosition.Right,
            type: ParsedMessageType.Text,
            value: message.message,
            sent: message.sent,
          },
        });
      }

      if (message.media) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-' + message.media.type,
            messageItemIndex: result.length - dateItems,
            position: ParsedMessagePosition.Left,
            type:
              message.media.type === MediaType.Picture
                ? ParsedMessageType.Picture
                : ParsedMessageType.Video,
            sent: message.sent,
            media: message.media,
          },
        });
      }

      if (hasProducts) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-product',
            messageItemIndex: result.length - dateItems,
            position: ParsedMessagePosition.Left,
            type: ParsedMessageType.Product,
            sent: message.sent,
            products: message.products,
          },
        });
      }

      if (message.response) {
        if (message.responseVoiceUrl) {
          result.push({
            message: {
              id: message.id!,
              key: message.id + '-response-voice',
              messageItemIndex: result.length - dateItems,
              position: ParsedMessagePosition.Left,
              type: ParsedMessageType.Voice,
              textValue: alwaysShowText ? message.response : undefined,
              value: message.responseVoiceUrl,
              sent: message.sent,
            },
          });
        } else {
          result.push({
            message: {
              id: message.id!,
              key: message.id + '-response-text',
              messageItemIndex: result.length - dateItems,
              position: ParsedMessagePosition.Left,
              type: ParsedMessageType.Text,
              value: message.response,
              sent: message.sent,
            },
          });
        }
      } else if (!message.media && !hasProducts) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-response-loader',
            messageItemIndex: result.length - dateItems,
            position: ParsedMessagePosition.Left,
            type: ParsedMessageType.Text,
            value: message.response,
            sent: message.sent,
            loading: true,
          },
        });
      }

      return result;
    });

    // remove today label if only has messages from today
    if (messagesFromTodayOnly) {
      const todayIndex = newParsedMessages.findIndex((value) => value.date);

      newParsedMessages.splice(todayIndex, 1);
    }

    const lastMessageResponse =
      newParsedMessages[newParsedMessages.length - 1]?.message?.value;

    if (
      previousParsedMessagesLength.current !== newParsedMessages.length ||
      previousLastMessageResponse.current !== lastMessageResponse
    ) {
      onChange?.();
    }

    previousParsedMessagesLength.current = newParsedMessages.length;
    previousLastMessageResponse.current = lastMessageResponse;

    return newParsedMessages;
  }, [messages]);
};
