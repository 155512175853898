import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSteps } from './use-steps.hook';
import { If, Loader } from '../../../../common';
import { STEPS } from './steps';
import { CreatorOnboardingStatus } from '../../../../api';
import CreatorOnboardingSteps from './creator-onboarding-steps.component';

import '../../../../common/form.css';
import './creator-onboarding.component.css';

const CreatorOnboarding: React.FC = () => {
  const navigate = useNavigate();

  const stepProps = useSteps(STEPS);

  const { response, isLoading } = stepProps;

  useEffect(() => {
    if (
      response &&
      response.status === CreatorOnboardingStatus.Completed &&
      response.characterId
    ) {
      navigate(`/creator/character/${response.characterId}`);
    }
  }, [response]);

  return (
    <Loader isLoading={isLoading} style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div className="onboarding-container">
          <If
            condition={response?.status === CreatorOnboardingStatus.InProgress}
          >
            <CreatorOnboardingSteps {...stepProps} />
          </If>

          <If
            condition={
              response?.status === CreatorOnboardingStatus.PendingVerification
            }
          >
            <div className="title">
              <div style={{ marginBottom: '10px' }}>
                Thank you for your application.
              </div>

              <div>We'll review it and get back to you soon!</div>
            </div>
          </If>
        </div>
      </div>
    </Loader>
  );
};

export default CreatorOnboarding;
