import { FC, PropsWithChildren } from 'react';

import { If } from '../if.component';
import { cssObjectToString } from '../../../utils';

import './classic-modal.component.css';

export const ClassicModal: FC<
  PropsWithChildren<{
    isOpen?: boolean;
    opaque?: boolean;
    close?: () => void;
    width?: string;
    maxWidth?: string;
  }>
> = ({ isOpen, opaque, close, width, maxWidth, children }) => {
  return (
    <If condition={isOpen}>
      <div className="classic-modal-wrapper">
        <div
          className={cssObjectToString({
            'classic-modal-background': true,
            opaque,
          })}
          onClick={close}
        />

        <div className="classic-modal-content" style={{ width, maxWidth }}>
          {children}
        </div>
      </div>
    </If>
  );
};
