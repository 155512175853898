export function beautifyPrice(price?: number, toFixed = false) {
  return `US$${toFixed ? (price || 0).toFixed(2) : (price || 0).toString()}`;
}

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function formatCurrency(value = 0) {
  return USDollar.format(value);
}
