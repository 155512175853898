import Icon from '@ant-design/icons';

import { ChatCharacter } from '../../../api';
import { AudioPlayer, CreatorSvg, If } from '../../../common';

import './character-highlight.component.css';

export const CharacterHighlight: React.FC<{
  data?: ChatCharacter;
  nameTag?: boolean;
  popularTag?: boolean;
  style?: React.CSSProperties;
}> = ({ data, nameTag, popularTag, style }) => {
  return (
    <div
      className="character-highlight"
      style={{
        backgroundImage: `url('${data?.coverUrl}')`,
        ...style,
      }}
    >
      <div className="top-tags">
        <If condition={nameTag}>
          <div className="tag" style={{ margin: '10px' }}>
            <Icon
              component={CreatorSvg}
              style={{ width: '16px', marginRight: '4px' }}
            />
            {data?.name}
          </div>
        </If>

        <If condition={popularTag}>
          <div className="tag accent" style={{ margin: '10px' }}>
            <Icon
              component={CreatorSvg}
              style={{ width: '16px', marginRight: '4px' }}
            />
            Popular
          </div>
        </If>
      </div>

      <If condition={data?.introMessageVoiceUrl}>
        <div className="audio-player-container">
          <AudioPlayer
            waveColor="#BCBCBD"
            progressColor="#1F2123"
            buttonBackgroundColor="var(--grey1)"
            buttonIconColor="white"
            url={data?.introMessageVoiceUrl}
            marginRight="15px"
            barWidth={2}
            barRadius={40}
          />
        </div>
      </If>
    </div>
  );
};
