import { Form, Input } from 'antd';

import { RouteData } from '../../../../utils';

import './handle-form-item.component.css';

const HandleFormItem: React.FC<{
  rawRoutes?: { [key: string]: RouteData[] };
}> = ({ rawRoutes }) => {
  const isReservedRoute = (handle: string, routes?: RouteData[]): boolean => {
    if (!routes || !handle) return false;

    return (
      routes.some(
        (route) =>
          route.path === handle ||
          route.path.split('/').some((innerRoute) => innerRoute === handle) ||
          isReservedRoute(handle, route.routes),
      ) || false
    );
  };

  return (
    <div className="handle-container">
      <Form.Item
        name="handle"
        label="Handle"
        rules={[
          { required: true, message: 'Handle is required' },
          {
            max: 30,
            message: 'Handle must be maximum 30 characters',
          },
          {
            pattern: /^[a-z][a-z0-9]*$/,
            message:
              'Handle can only contain lower case letters and numbers and must start with a letter',
          },
          {
            validator: (_, value) =>
              isReservedRoute(value, rawRoutes?.all)
                ? Promise.reject(
                    new Error(`${value} is reserved for the system`),
                  )
                : Promise.resolve(),
          },
        ]}
      >
        <Input addonBefore="https://swoons.ai/" placeholder="handle" />
      </Form.Item>
    </div>
  );
};

export default HandleFormItem;
