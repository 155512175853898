import { AxiosRequestConfig } from 'axios';

import { MediaParams } from './media-params.interface';

export const UpdateMediaRequest = (
  id: string,
  params: MediaParams,
  rotation?: number,
): AxiosRequestConfig => {
  return {
    url: `v1/media/${id}`,
    method: 'put',
    data: { ...params, rotation },
  };
};
