import { AxiosRequestConfig } from 'axios';

import { transformMessageResponse } from './transform-message-response';

export const MessageRequest = ({
  id,
  ...data
}: {
  id: string;
  text?: string;
}): AxiosRequestConfig => {
  return {
    url: `v1/chat/${id}/message`,
    method: 'post',
    data,
    transformResponse: transformMessageResponse,
  };
};
