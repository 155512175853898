import { FC } from 'react';
import { Highlight } from 'prism-react-renderer';

import { cssObjectToString } from '../../../../../utils';

export const CodeBlock: FC<{
  code: string;
  language?: string;
  className?: string;
  style?: React.CSSProperties;
}> = ({ code, language, className: wrapperClassName, style: wrapperStyle }) => (
  <Highlight code={code} language={language || 'javascript'}>
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <pre
        className={cssObjectToString({
          [wrapperClassName!]: true,
          [className]: true,
        })}
        style={{ ...wrapperStyle, ...style }}
      >
        {tokens.map((line, i) => (
          <div key={i} {...getLineProps({ line })}>
            {line.map((token, key) => (
              <span key={key} {...getTokenProps({ token })} />
            ))}
          </div>
        ))}
      </pre>
    )}
  </Highlight>
);
