import { CRUDKey } from '../../../common';
import { Requests } from '../../hooks';
import { DeleteProductRequest } from './delete-product.request';
import { ProductParams } from './product-params.interface';
import { ReadProductRequest } from './read-product.request';
import { UpdateProductRequest } from './update-product.request';

export function getProductRequests(id: string): Requests<ProductParams> {
  return {
    [CRUDKey.Read]: () => ReadProductRequest(id),
    [CRUDKey.Update]: (params) => UpdateProductRequest(id, params),
    [CRUDKey.Delete]: () => DeleteProductRequest(id),
  };
}
