import { AxiosRequestConfig } from 'axios';

import { PayoutParams } from './payout-params.interface';

export const PayoutListRequest = (): AxiosRequestConfig => {
  return {
    url: 'v1/billing/payout/list',
    method: 'get',
    transformResponse: (response: string) => {
      const { data }: { data: PayoutParams[] } = JSON.parse(response);

      return {
        data: data.map((item) => ({
          ...item,
          created: new Date(item.created),
        })),
      };
    },
  };
};
