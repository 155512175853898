import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Layout } from 'antd';
import Icon from '@ant-design/icons';

import {
  AuthDrawerState,
  If,
  LogoIcon,
  useLayoutDisplay,
  useScroll,
  WideButton,
} from '../../common';
import { useAuthStore, useMiscStore } from '../../stores';
import { RouteData } from '../../utils';
import { MenuSvg } from '../layout-header-svg.component';

import './index-header.component.css';

const { Header } = Layout;

const IndexHeader: FC<{
  routeData: RouteData;
  backPath: string;
  width?: string;
  maxWidth?: string;
  setVisible: (value: boolean) => void;
  style?: React.CSSProperties;
  [key: string]: any;
}> = ({
  routeData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  backPath,
  width,
  maxWidth,
  setVisible,
  style,
  ...props
}) => {
  const token = useAuthStore((state) => state.token);

  const { setMenuOpen, setAuthOpen } = useMiscStore();

  const { isMobile } = useLayoutDisplay();

  const scroll = useScroll();

  const colored = (isMobile && scroll >= 220) || (!isMobile && scroll >= 340);

  useEffect(() => {
    setVisible(true);
  }, [routeData]);

  return (
    <Header
      style={{
        width,
        maxWidth,
        borderRadius: routeData.flatHeader ? 0 : undefined,
        backgroundColor: colored ? undefined : 'transparent',
        transition: 'background-color 0.5s ease',
        ...style,
      }}
      {...props}
    >
      <Helmet>
        <title>Swoons</title>
      </Helmet>

      <div className="header-container">
        <div className="transparent-logo">
          <Icon component={LogoIcon} style={{ marginRight: '10px' }} />
          Swoons
        </div>

        <If condition={token}>
          <button className="button-menu" onClick={() => setMenuOpen(true)}>
            <Icon component={MenuSvg} style={{ width: '24px' }} />
          </button>
        </If>

        <If condition={!token}>
          <WideButton
            label="Sign In"
            onClick={() => setAuthOpen(true, AuthDrawerState.SignIn)}
            accent={!isMobile}
            transparent={isMobile}
            style={{ maxWidth: '90px' }}
          />
        </If>
      </div>
    </Header>
  );
};

export default IndexHeader;
