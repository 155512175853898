import { Drawer, Space } from 'antd';
import Icon from '@ant-design/icons';
import { FC } from 'react';

import {
  useAPIRequest,
  UserParams,
  UpdateResponseType,
  ResponseType,
  ChatParams,
  ClearChatRequest,
  UserType,
} from '../../../../../api';
import {
  useLayoutDisplay,
  CloseSvg,
  VoiceSvg,
  MenuDrawerLink,
  SupportSvg,
  TermsSvg,
  MenuDrawerSwitch,
  WalletSvg,
  ResetSvg,
  If,
} from '../../../../../common';
import {
  useChatStore,
  useInitStore,
  useMiscStore,
} from '../../../../../stores';
import { confirmDangerousAction } from '../../../../../utils';

import './chat-drawer.component.css';

export const ChatDrawer: FC = () => {
  const { isMobile } = useLayoutDisplay();

  const {
    id,
    character,
    chatMenuOpen,
    setChatMenuOpen,
    setPricingMenuOpen,
    clear,
    setSendWelcomeMessageFlag,
  } = useChatStore();
  const { user, setResponseType } = useInitStore();
  const { setWalletOpen } = useMiscStore();

  const close = () => setChatMenuOpen(false);

  const { request: toggleResponseType, isLoading: isTogglingResponseType } =
    useAPIRequest<UserParams>(
      () =>
        UpdateResponseType(
          user?.responseType === ResponseType.Text
            ? ResponseType.Voice
            : ResponseType.Text,
        ),
      {
        immediate: false,
        onSuccess: (response) => {
          setResponseType(response.responseType);
        },
      },
    );

  const { request: clearChat } = useAPIRequest<ChatParams>(ClearChatRequest, {
    immediate: false,
    onSuccess: (chat) => {
      clear();

      if (chat.character.hasWelcomeMessage && chat.totalMessages === 0) {
        setTimeout(() => setSendWelcomeMessageFlag(true), 1000);
      }
    },
  });

  return (
    <Drawer
      open={chatMenuOpen}
      placement={isMobile ? 'bottom' : 'right'}
      closeIcon={null}
      onClose={close}
      height={280}
    >
      <div className="chat-drawer-container">
        <div className="button-circle" onClick={close}>
          <Icon component={CloseSvg} />
        </div>

        <div className="title">Chat Settings</div>

        <Space direction="vertical" size={18} style={{ padding: '0px 20px' }}>
          <MenuDrawerSwitch
            icon={VoiceSvg}
            iconWidth="24px"
            label="Voice Responses"
            value={user?.responseType === ResponseType.Voice}
            onClick={toggleResponseType}
            disabled={isTogglingResponseType}
          />

          <MenuDrawerLink
            icon={WalletSvg}
            iconWidth="24px"
            label={`Balance: $${user?.balance?.toFixed(2)}`}
            onClick={() => {
              setWalletOpen(true);
              close();
            }}
          />

          <MenuDrawerLink
            icon={TermsSvg}
            label="Pricing"
            onClick={() => {
              setPricingMenuOpen(true);
              close();
            }}
          />

          <If condition={user?.type === UserType.Admin || character?.owner}>
            <MenuDrawerLink
              icon={ResetSvg}
              label="Reset Chat"
              onClick={() => {
                confirmDangerousAction({
                  action: 'reset',
                  actionText: 'Yes, delete',
                  cancelText: 'No',
                  content:
                    'Are you sure you want to delete your entire conversation history? This cannot be undone.',
                  dangerColor: true,
                  onConfirm: () => {
                    clearChat(id);
                    close();
                  },
                });
              }}
            />
          </If>

          <MenuDrawerLink
            icon={SupportSvg}
            label="Support"
            to={'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL}
            target="_blank"
            onClick={close}
          />
        </Space>
      </div>
    </Drawer>
  );
};
