import { FC } from 'react';
import { Space, Switch } from 'antd';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

import '../menu-drawer/menu-drawer-link.component.css';

export const MenuDrawerSwitch: FC<{
  icon:
    | React.ComponentType<
        CustomIconComponentProps | React.SVGProps<SVGSVGElement>
      >
    | React.ForwardRefExoticComponent<CustomIconComponentProps>;
  label: string;
  value?: boolean;
  onClick?: () => void;
  iconWidth?: string;
  disabled?: boolean;
}> = ({ icon, label, value, onClick, iconWidth, disabled }) => {
  return (
    <div className="menu-drawer-link" onClick={disabled ? undefined : onClick}>
      <Space direction="horizontal" size={0}>
        <Icon
          component={icon}
          style={{ color: 'white', marginRight: '12px', width: iconWidth }}
        />

        <div>{label}</div>
      </Space>

      <Switch value={value} disabled={disabled} />
    </div>
  );
};
