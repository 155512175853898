import { useParams } from 'react-router-dom';
import { Form, Input, Select, Space } from 'antd';

import {
  formRequestErrorHandler,
  Gender,
  WaitlistRequest,
  useAPIRequest,
} from '../../../../api';
import { Loader, WideButton, SelectAutoClose, If } from '../../../../common';

import './waitlist.component.css';

const { Option } = Select;

const Waitlist: React.FC = () => {
  const params = useParams();

  const [form] = Form.useForm();

  const { request, response, isLoading, error } = useAPIRequest<{
    url: string;
  }>((data) => WaitlistRequest({ ...data, promo: params.promo }), {
    immediate: false,
    onError: formRequestErrorHandler(form),
  });

  return (
    <Loader error={error} isLoading={isLoading}>
      <If condition={!!response}>
        <div className="form waitlist-container">
          <div style={{ color: 'var(--white)', textAlign: 'center' }}>
            Thanks for joining the waitlist! We'll get back to you as soon as we
            can.
          </div>

          <WideButton
            label="Back to Swoons"
            to="/"
            style={{ width: '160px', marginTop: '24px' }}
            accent
          />
        </div>
      </If>

      <If condition={!response}>
        <Form
          form={form}
          onFinish={request}
          initialValues={response}
          layout="vertical"
          requiredMark={false}
          className="form"
          style={{
            width: '100%',
            marginTop: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <div className="waitlist-container">
            <Space
              direction="vertical"
              style={{ width: '100%', maxWidth: 'min(600px, 90%)' }}
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: true, message: 'Name is required' },
                  {
                    max: 30,
                    message: 'Name must be maximum 30 characters',
                  },
                ]}
              >
                <Input placeholder="Jane Doe" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Email must not be empty',
                  },
                  {
                    max: 100,
                    message: 'Email must be maximum 100 characters',
                  },
                  {
                    type: 'email',
                    message: 'Email is invalid',
                  },
                ]}
              >
                <Input placeholder="jane.doe@gmail.com" />
              </Form.Item>

              <Form.Item
                name="gender"
                label="Gender"
                rules={[{ required: true, message: 'Gender is required' }]}
              >
                <SelectAutoClose placeholder="Select gender">
                  <Option value={Gender.Male}>Male</Option>
                  <Option value={Gender.Female}>Female</Option>
                </SelectAutoClose>
              </Form.Item>

              <Form.Item
                name="url"
                label="Instagram URL"
                rules={[
                  { required: true, message: 'Instagram URL is required' },
                  {
                    max: 100,
                    message: 'Instagram URL must be maximum 100 characters',
                  },
                  {
                    pattern:
                      /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9._]+\/?$/,
                    message: 'Please specify a valid Instagram URL',
                  },
                ]}
              >
                <Input placeholder="https://www.instagram.com/nickname" />
              </Form.Item>

              <Form.Item
                name="followers"
                label="How many followers do you have?"
                rules={[{ required: true, message: 'Followers is required' }]}
              >
                <SelectAutoClose placeholder="Select followers">
                  <Option value="0-100k">0-100k</Option>
                  <Option value="100k-500k">100k-500k</Option>
                  <Option value="500k-1M">500k-1M</Option>
                  <Option value="1M-5M">1M-5M</Option>
                  <Option value="5M+">5M+</Option>
                </SelectAutoClose>
              </Form.Item>

              <Form.Item>
                <WideButton
                  label="Join Waitlist"
                  disabled={isLoading}
                  onClick={form.submit}
                  style={{ width: '160px', marginTop: '10px' }}
                  accent
                />
              </Form.Item>
            </Space>
          </div>
        </Form>
      </If>
    </Loader>
  );
};

export default Waitlist;
