import { forwardRef, useEffect, useRef } from 'react';
import Icon, { PlayCircleFilled } from '@ant-design/icons';

import { AudioPlayer, If } from '../../../../../common';
import { ParsedMessageType } from './parsed-message-type.enum';
import { ParsedMessagePosition } from './parsed-message-position.enum';
import { ParsedMessage } from './parsed-message.interface';
import { MessageSpinnerSvg } from './message-box-svg.component';
import { MediaType } from '../../../../../api';
import { capitalizeFirstLetter, cssObjectToString } from '../../../../../utils';
import MessageTextParser from './message-text-parser.component';

import './message-box.component.css';

const MessageBox = forwardRef<
  any,
  {
    data: ParsedMessage;
    unlockMessageMedia?: (data: ParsedMessage) => any;
    viewMedia?: (data: ParsedMessage) => any;
  }
>(({ data, unlockMessageMedia, viewMedia }, ref) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
    }
  }, [videoRef]);

  const getTime = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return hours + ':' + minutes;
  };

  const isTextOrVoice =
    data.type === ParsedMessageType.Text ||
    data.type === ParsedMessageType.Voice;

  const hasProducts = data.products && data.products.length > 0;

  const wrapperClassName = cssObjectToString({
    'message-box-wrapper': true,
    'no-margin': hasProducts,
    [data.position]: true,
  });

  const voiceOnly = data.type === ParsedMessageType.Voice && !data.textValue;

  const className = cssObjectToString({
    'message-box': true,
    [data.position]: true,
    ['text-or-voice']: isTextOrVoice,
    voice: voiceOnly,
    ['non-voice']: !voiceOnly,
  });

  let waveColor: string;
  let buttonBackgroundColor: string;
  let buttonIconColor: string;

  if (data.position === ParsedMessagePosition.Left) {
    waveColor = '#FF8B4A';
    buttonBackgroundColor = 'var(--white)';
    buttonIconColor = 'var(--orange1)';
  } else {
    waveColor = '#000000';
    buttonBackgroundColor = '#1D1D1F';
    buttonIconColor = 'var(--white)';
  }

  const linearBackground =
    'linear-gradient(rgba(0, 0, 0, 0) 1.4%, rgba(0, 0, 0, 0.39) 59.18%, rgb(0, 0, 0) 93%)';

  return (
    <div className={wrapperClassName} key={data.key} ref={ref}>
      <div className={className}>
        <If condition={!!data.value}>
          <If condition={data.type === ParsedMessageType.Text}>
            <MessageTextParser value={data.value} />
          </If>

          <If condition={data.type === ParsedMessageType.Voice}>
            <AudioPlayer
              waveColor={waveColor}
              progressColor="white"
              buttonBackgroundColor={buttonBackgroundColor}
              buttonIconColor={buttonIconColor}
              url={data.value}
              marginRight="5px"
              barWidth={3}
              barRadius={20}
            />

            <If condition={data.textValue}>
              <MessageTextParser
                value={data.textValue}
                style={{ marginTop: '20px' }}
              />
            </If>
          </If>
        </If>

        <If condition={data.media}>
          <If condition={data.media?.unlocked}>
            <div className="media-wrapper">
              <div className="pointer" onClick={() => viewMedia?.(data)}>
                <If condition={data.media?.type === MediaType.Video}>
                  <PlayCircleFilled
                    className="center icon"
                    style={{ fontSize: '50px' }}
                  />

                  <div className="top-right unlock duration">
                    {data?.media?.duration}
                  </div>
                </If>

                <div
                  style={{
                    backgroundImage: `url('${data.media?.previewUrl}')`,
                  }}
                  className="media"
                />
              </div>

              {/*<If condition={!data.media?.welcome}>
              <div className="text">{data.media?.description}</div>
            </If>*/}
            </div>
          </If>

          <If condition={!data.media?.unlocked}>
            <div className="media-wrapper">
              <div
                className="pointer"
                onClick={() => unlockMessageMedia?.(data)}
              >
                <div
                  style={{
                    backgroundImage: `url('${data.media?.previewUrl}')`,
                  }}
                  className="media"
                />

                <div className="center unlock price">${data.media?.price}</div>

                <div className="bottom-center unlock instructions">
                  Unlock {capitalizeFirstLetter(data.media?.type || '')}
                </div>

                <If condition={data.media?.type === MediaType.Video}>
                  <div className="top-right unlock duration">
                    {data?.media?.duration}
                  </div>
                </If>
              </div>

              {/*<If condition={!data.media?.welcome}>
              <div className="text">{data.media?.description}</div>
            </If>*/}
            </div>
          </If>
        </If>

        <If condition={hasProducts}>
          {data.products?.map((product) => {
            return (
              <a
                href={product.url}
                target="_blank"
                key={'product-' + product.id}
              >
                <div
                  className="pointer"
                  style={{ marginRight: '10px', marginBottom: '10px' }}
                >
                  <div className="product-wrapper">
                    <div
                      style={{
                        backgroundImage: [
                          linearBackground,
                          `url('${product.thumbnailUrl}')`,
                        ].join(', '),
                      }}
                      className="product"
                    />

                    <If condition={product.price}>
                      <div
                        className="top-right unlock price"
                        style={{ color: 'var(--grey1)' }}
                      >
                        ${product.price}
                      </div>
                    </If>

                    <div className="bottom-center text">
                      <div className="name" style={{ marginTop: '10px' }}>
                        {product.name}
                      </div>

                      <div className="category" style={{ marginTop: '10px' }}>
                        {product.category}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </If>

        <If condition={data.loading}>
          <Icon
            component={MessageSpinnerSvg}
            style={{ marginLeft: '10px', marginRight: '10px' }}
          />
        </If>

        <If condition={isTextOrVoice}>
          <div className="notch" />
        </If>
      </div>

      <If condition={isTextOrVoice && !data.loading}>
        <div className="time">{getTime(data.sent)}</div>
      </If>
    </div>
  );
});

export default MessageBox;
