import { AxiosRequestConfig } from 'axios';

import { Message } from './message.interface';

export const MessagesRequest = (
  chatId: string,
  perPage: number,
  paginationToken?: string,
): AxiosRequestConfig => {
  return {
    url: `v1/chat/${chatId}/messages`,
    method: 'get',
    params: { perPage, paginationToken },
    transformResponse: (response: string) => {
      if (response.includes('"error"')) return JSON.parse(response);

      const { messages, ...data }: { messages?: Message[] } =
        JSON.parse(response);

      return {
        ...data,
        messages: messages?.map((message) => ({
          ...message,
          sent: new Date(message.sent),
        })),
      };
    },
  };
};
