import { useState } from 'react';
import { Button, Form, Input } from 'antd';
import Icon from '@ant-design/icons';

import { EmailSvg } from '../../../common';
import { useInitStore } from '../../../stores';
import {
  useAPIRequest,
  formRequestErrorHandler,
  UpdateEmailRequest,
  EmailParams,
} from '../../../api';

import '../../../common/form.css';

const AccountDetails: React.FC = () => {
  const [form] = Form.useForm();

  const { user, setEmail } = useInitStore();

  // form.getFieldValue('email') needs a state refresh to work and takes time to become available on page load
  const [emailField, setEmailField] = useState<string>(user?.email as string);

  const { request, isLoading } = useAPIRequest<EmailParams>(
    UpdateEmailRequest,
    {
      immediate: false,
      onSuccess: (response) => {
        form.setFieldsValue(response);

        setEmail(response.email, response.emailConfirmed);
      },
      onError: formRequestErrorHandler(form),
    },
  );

  return (
    <div className="form" style={{ width: '100%', padding: 0 }}>
      <Form
        form={form}
        onFinish={request}
        initialValues={user}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        disabled={isLoading}
        style={{ width: '100%', maxWidth: '400px' }}
      >
        <Form.Item
          name="email"
          label={
            <>
              <Icon
                component={EmailSvg}
                style={{ width: '24px', marginRight: '5px' }}
              />

              <div>Email</div>
            </>
          }
          rules={[
            {
              required: true,
              message: 'Email must not be empty',
            },
            {
              type: 'email',
              message: 'Email is invalid',
            },
          ]}
        >
          <Input
            placeholder="Email"
            onChange={(e) => setEmailField(e.currentTarget.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={user?.email === emailField}
            className="form-button"
            style={{ marginTop: '8px' }}
          >
            Change and Confirm
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AccountDetails;
