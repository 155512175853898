import { CRUDKey } from '../../../common';
import { Requests } from '../../hooks';
import { CharacterParams } from '../character';
import { DeleteCharacterRequest } from './delete-character.request';
import { ReadCharacterProfileRequest } from './read-character-profile.request';
import { UpdateCharacterProfileRequest } from './update-character-profile.request';

export function getCharacterProfileRequests(
  id: string,
): Requests<CharacterParams> {
  return {
    [CRUDKey.Read]: () => ReadCharacterProfileRequest(id),
    [CRUDKey.Update]: (params) => UpdateCharacterProfileRequest(id, params),
    [CRUDKey.Delete]: () => DeleteCharacterRequest(id),
  };
}
