import IndexHeader from '../../../layouts/index/index-header.component';
import { IndexLayout } from '../../../layouts/index/index-layout.component';
import { RouteData } from '../../../utils';
import Index from './index.component';

export const IndexRoutes: RouteData[] = [
  {
    path: '/',
    component: Index,
    layout: IndexLayout,
    header: IndexHeader,
    authRequired: false,
    flatHeader: true,
  },
];
