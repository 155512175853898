import { FileUpload } from '../../../api';

import './attachment.component.css';

export const Attachment: React.FC<{
  data?: FileUpload;
}> = ({ data }) => {
  if (!data) return <></>;

  if (data.thumbnailUrl) {
    return (
      <a href={data.url} target="_blank">
        <img
          src={data.thumbnailUrl}
          className="file-preview file-preview-image"
        />
      </a>
    );
  } else {
    return (
      <a href={data.url} target="_blank">
        <div className="file-preview file-preview-other">
          <div className="file-preview-other-contained">
            {data.name}.{data.extension}
          </div>
        </div>
      </a>
    );
  }
};
