import { useState, useEffect } from 'react';

import { areArraysEqual } from '../../utils';
import { AsyncPipelineItem, AsyncPipelineOptions } from '../interfaces';

export function useAsyncPipeline(
  items: AsyncPipelineItem[],
  options?: AsyncPipelineOptions,
) {
  if (!options) options = {};
  if (options.immediate == undefined) options.immediate = true;

  const { immediate, onSuccess: onRootSuccess, onError: onRootError } = options;

  const [response, setResponseInner] = useState<any | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [executedItems, setExecutedItems] = useState<AsyncPipelineItem[]>([]);

  function setResponse(value: any | undefined) {
    setResponseInner(value);
    setError(undefined);
  }

  const request = async () => {
    let promise: Promise<any> = Promise.resolve();

    const results: any[] = [];
    const resultsDictionary: { [key: string]: any } = {};

    items.forEach(
      ({
        key,
        factory,
        onStart,
        onComplete,
        onError,
        onSuccess,
        shouldExecute,
      }) => {
        promise = promise.then(async () => {
          onStart?.();

          try {
            if (shouldExecute && !shouldExecute(resultsDictionary)) {
              return;
            }

            const result = await factory(resultsDictionary);

            results.push(result);
            resultsDictionary[key] = result;

            onSuccess?.(result, resultsDictionary);
          } catch (e) {
            onError?.(e);

            throw e;
          }

          onComplete?.();
        });
      },
    );

    setExecutedItems(items);
    setLoading(true);

    try {
      await promise;

      setResponse(results);
      onRootSuccess?.(results);
    } catch (e: any) {
      setError((e.message as string) || e.error);
      onRootError?.(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (
      immediate &&
      !areArraysEqual(
        items.map((item) => item.key),
        executedItems.map((item) => item.key),
      )
    )
      request();
  }, [items, executedItems]);

  return {
    response,
    error,
    setError,
    isLoading,
    setLoading,
    request,
  };
}
