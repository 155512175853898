export function formatDate(date?: Date) {
  return date?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

export const formatShortDate = (date: Date) => {
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();

  return month + ' ' + day;
};

export function getRemainingDays(date?: Date) {
  if (!date) return -1;

  const diffTime = date.valueOf() - new Date().valueOf();

  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export function isToday(date: Date) {
  try {
    const today = new Date();

    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  } catch {
    console.log(typeof date, date);

    return false;
  }
}
