import { Typography, Space } from 'antd';
import { isValidElement } from 'react';

import { If } from '../../../../common';
import { LegalItemLevel, LegalItem } from '../interfaces';
import { LegalContent } from './legal-content.component';
import { LegalPoints } from './legal-points.component';
import { LegalUnderlined } from './legal-underlined.component';

import './legal-renderer.component.css';

const { Title, Text } = Typography;

const TITLE_LEVELS: LegalItemLevel[] = [1, 2, 4, 5];

export const LegalRenderer: React.FC<{
  data: LegalItem;
  indexes?: number[];
  lastUpdate?: string;
  style?: React.CSSProperties;
}> = ({ data, indexes = [], lastUpdate, style }) => {
  function getTitleIndex(indexes: number[]): string {
    let titleIndex = '';

    if (indexes.length > 0) {
      titleIndex = indexes.join('.');

      if (indexes.length === 1) titleIndex += '.';

      titleIndex += ' ';
    }

    return titleIndex;
  }

  return (
    <Space
      direction="vertical"
      style={style}
      size={0}
      className="legal-renderer"
    >
      <If condition={data.title}>
        <Title
          level={TITLE_LEVELS[indexes.length]}
          style={{
            marginTop:
              indexes.length > 0
                ? Math.max(3 - indexes.length, 1) * 20 + 'px'
                : 0,
          }}
        >
          {getTitleIndex(indexes)}
          {data.title}
        </Title>
      </If>

      <If condition={lastUpdate}>
        <div style={{ marginBottom: '20px' }}>
          <Text style={{ color: 'var(--white50)' }}>
            Last updated {lastUpdate}
          </Text>
        </div>
      </If>

      <Space align="start">
        {!data.title && (
          <>
            <b>{getTitleIndex(indexes)}</b>
          </>
        )}

        <div>
          <If condition={data.content}>
            <LegalContent data={data.content} indexes={indexes} />
          </If>

          <If condition={data.underlined}>
            <LegalUnderlined
              data={data.underlined}
              indexes={indexes}
              style={{ marginTop: '10px' }}
            />
          </If>

          <If condition={data.points}>
            <LegalPoints
              data={data.points}
              indexes={indexes}
              style={{ marginTop: '10px' }}
            />
          </If>

          <If condition={data.contentAfterPoints}>
            <LegalContent data={data.contentAfterPoints} indexes={indexes} />
          </If>

          <If condition={data.children}>
            <Space direction="vertical">
              {data.children?.map((child, index) => {
                const isChildContent =
                  typeof child === 'string' || isValidElement(child);

                const newIndexes = [...indexes, index + 1];

                return (
                  <LegalRenderer
                    data={
                      isChildContent ? { content: child } : (child as LegalItem)
                    }
                    indexes={newIndexes}
                    key={'legal_' + newIndexes.join('-')}
                  />
                );
              })}
            </Space>
          </If>
        </div>
      </Space>
    </Space>
  );
};
