import { useEffect } from 'react';
import { To, useLocation, Navigate } from 'react-router-dom';

import { usePersistedStore } from '../../../stores';

export const RedirectAndPersistPreviousPath = ({ to }: { to: To }) => {
  const location = useLocation();

  const setDefaultPath = usePersistedStore((state) => state.setDefaultPath);

  useEffect(() => {
    if (location.pathname) setDefaultPath(location.pathname);
  }, [location.pathname]);

  return <Navigate to={to} replace />;
};
