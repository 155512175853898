import { AxiosRequestConfig } from 'axios';

export const CreateTransloaditAssembliesRequest = (
  characterId: string,
  assemblyIds: string[],
  price: number,
): AxiosRequestConfig => {
  return {
    url: `v1/media/transloadit/assemblies`,
    method: 'post',
    data: { characterId, assemblyIds, price },
  };
};
