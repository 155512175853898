import { AxiosRequestConfig } from 'axios';

import { ProductParams } from './product-params.interface';

export const UpdateProductRequest = (
  id: string,
  params: Omit<ProductParams, 'id'>,
  rotation?: number,
): AxiosRequestConfig => {
  return {
    url: `v1/product/${id}`,
    method: 'put',
    data: { ...params, rotation },
  };
};
