import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ConfigProvider } from 'antd';

import reportWebVitals from './reportWebVitals';

import Router from './routes/router.component';
import { initializeThirdPartyLibraries, ThirdPartyTags } from './third-party';
import { isLocal } from './common';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      {!isLocal() && <ThirdPartyTags />}
      <ThirdPartyTags />

      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'MatterSQTRIAL',
            fontSize: 16,
            colorBgContainer: 'var(--grey1)',
          },
          components: {
            Table: {
              colorText: 'var(--white)',
              headerColor: 'var(--grey6)',
              headerBg: 'var(--grey2)',
              headerSplitColor: 'var(--grey4)',
              borderColor: 'var(--grey4)',
              rowHoverBg: 'var(--grey4)',
            },
            Pagination: {
              itemActiveBg: 'var(--grey3)',
              colorText: 'var(--grey6)',
            },
            Modal: {
              contentBg: 'var(--grey3)',
            },
            Empty: {
              colorTextDescription: 'var(--white)',
            },
          },
        }}
      >
        <Router />
      </ConfigProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

initializeThirdPartyLibraries();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
