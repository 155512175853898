import { AxiosRequestConfig } from 'axios';

import { ChatListSort } from './chat-list-sort.enum';
import { CharacterChatParams } from './character-chat-params.interface';

export const CharacterChatListRequest = (
  characterId: string,
  sort: ChatListSort,
  perPage: number,
  page: number,
): AxiosRequestConfig => {
  return {
    url: 'v1/chat/list/admin',
    method: 'get',
    params: { characterId, sort, perPage, page },
    transformResponse: (response: string) => {
      const {
        chats,
        perPage,
        page,
        pages,
      }: {
        chats: CharacterChatParams[];
        perPage: number;
        page: number;
        pages: number;
      } = JSON.parse(response);

      return {
        chats: chats.map((item) => ({
          ...item,
          lastMessageDate: new Date(item.lastMessageDate),
          lastUserMessageDate: new Date(item.lastUserMessageDate),
        })),
        perPage,
        page,
        pages,
      };
    },
  };
};
