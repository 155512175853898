import { FC } from 'react';

import { useAuthStore } from '../stores';
import { navigateToDefaultPath, RouteData, RoutesRenderer } from '../utils';
import { AuthRoutes } from './auth/auth.routes';
import { AppRoutes } from './app/app.routes';
import { RequiredRoutes } from './required/required.routes';

import './router.component.css';

const Router: FC = () => {
  const token = useAuthStore((state) => state.token);

  let routes: RouteData[] = [...AppRoutes, ...RequiredRoutes];

  if (token) routes = routes.concat(navigateToDefaultPath(AuthRoutes));
  else routes = routes.concat(AuthRoutes);

  return (
    <RoutesRenderer
      layoutProps={{ withInit: !!token }}
      routes={routes}
      isAuth={!!token}
    />
  );
};

export default Router;
