import { Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useRef, useState } from 'react';
import Uppy from '@uppy/core';
import Transloadit, {
  COMPANION_URL,
  COMPANION_ALLOWED_HOSTS,
} from '@uppy/transloadit';
import Dropbox from '@uppy/dropbox';
import GoogleDrive from '@uppy/google-drive';
import { Dashboard } from '@uppy/react';

import { useInitStore } from '../../../../stores';
import { WideButton } from '../../../../common';

import './add-knowledge.component.css';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

const AddKnowledge: React.FC<{
  characterId?: string;
  afterUploadRef?: React.MutableRefObject<
    (characterId: string, assemblyIds: string[]) => void
  >;
  style?: React.CSSProperties;
}> = ({ characterId, afterUploadRef, style }) => {
  const [open, setOpen] = useState(false);

  const user = useInitStore((state) => state.user);

  const assemblyId = useRef<string[]>([]);
  const characterIdRef = useRef<string | undefined>(characterId);

  characterIdRef.current = characterId;

  const getCompanionConfig = (credentialsName: string): any => ({
    companionUrl: COMPANION_URL,
    companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    companionKeysParams: {
      key: process.env.REACT_APP_TRANSLOADIT_AUTH_KEY!,
      credentialsName,
    },
  });

  const [uppy] = useState(() => {
    return new Uppy({
      restrictions: {
        allowedFileTypes: [
          'text/plain',
          'text/richtext',
          'application/rtf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ],
      },
    })
      .use(Transloadit, {
        assemblyOptions: () => {
          return {
            params: {
              auth: { key: process.env.REACT_APP_TRANSLOADIT_AUTH_KEY! },
              template_id: process.env.REACT_APP_TRANSLOADIT_TEMPLATE_KNOWLEDGE,
            },
            fields: {
              user_id: user?.id || '',
              character_id: characterIdRef.current!,
            },
          };
        },
      })
      .use(Dropbox, getCompanionConfig('dropbox'))
      .use(GoogleDrive, getCompanionConfig('google-drive'))
      .on('upload-success', (value: any) => {
        // called once per file, not per assembly

        const assembly = value?.transloadit?.assembly;

        if (!assemblyId.current.includes(assembly)) {
          assemblyId.current.push(assembly);
        }
      })
      .on('complete', () => {
        if (assemblyId.current?.length > 0) {
          afterUploadRef?.current?.(
            characterIdRef.current!,
            assemblyId.current,
          );
        }

        assemblyId.current = [];
      });
  });

  return (
    <>
      <WideButton
        label="Add Knowledge"
        onClick={() => setOpen(true)}
        style={{ width: '160px', ...style }}
        light
      >
        <PlusOutlined />
      </WideButton>

      <Modal
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        maskClosable={false}
        width="90%"
        style={{ maxWidth: '800px' }}
        className="add-knowledge"
      >
        <div
          style={{
            color: 'var(--white)',
            textAlign: 'center',
            marginBottom: '10px',
          }}
        >
          Supported file types:{' '}
          <span style={{ color: 'var(--grey6)' }}>txt, doc, docx, rtf</span>
        </div>

        <Dashboard uppy={uppy} width="100%" />
      </Modal>
    </>
  );
};

export default AddKnowledge;
