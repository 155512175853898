import { Form, Input, Select, Space } from 'antd';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { RuleObject } from 'antd/es/form';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  useRequests,
  formRequestErrorHandler,
  UserType,
  getCharacterPersonalityRequests,
} from '../../../../api';
import { Loader, CRUDKey, WideButton, If } from '../../../../common';
import {
  capitalizeFirstLetter,
  confirmDangerousAction,
  createNotification,
  NotificationType,
  valuesToOptions,
} from '../../../../utils';
import { useInitStore } from '../../../../stores';

import './personality.component.css';

const { TextArea } = Input;

const DEFAULT_PERSONALITY_TRAITS = [
  'accessible',
  'adaptable',
  'friendly',
  'kind',
  'methodical',
  'patient',
  'trusting',
  'abrasive',
  'careless',
  'compulsive',
  'cynical',
  'disobedient',
  'egocentric',
  'lazy',
  'casual',
  'obedient',
  'reserved',
  'predictable',
  'surprising',
  'ambitious',
];

const DEFAULT_DIALOGUE_STYLE = [
  'anxious',
  'contemplative',
  'curt',
  'expressive',
  'hilarious',
  'inquisitive',
  'intense',
  'mischievous',
  'snarky',
  'sympathetic',
  'talkative',
  'unflinching',
  'aggressive',
  'cruel',
  'defiant',
  'creative',
  'passionate',
  'confident',
  'optimistic',
  'pessimistic',
  'sneaky',
];

function tagsValidator(label: string) {
  return (rule: RuleObject, value: string[]) => {
    const regex = /^[A-Za-z\s]*$/;

    const invalidItems = value.filter((item) => !item.match(regex));

    if (invalidItems.length > 0) {
      return Promise.reject(
        new Error(
          `${capitalizeFirstLetter(
            label,
          )}s can only contain letters and spaces. Invalid ${label}${
            invalidItems.length > 1 ? 's' : ''
          }: ${invalidItems.join(', ')}`,
        ),
      );
    }

    return Promise.resolve();
  };
}

const dialogueExampleTip = `An example of a back and forth dialogue between you and a User in the following format:\nUser: Hello!\nMe: Hi, it's so great to meet you!\nUser: ABC\nMe: XYZ`;

const Personality: React.FC = () => {
  const params = useParams();

  const [form] = Form.useForm();

  const user = useInitStore((state) => state.user);

  const { request, requestKey, response, isLoading, error } = useRequests({
    requests: getCharacterPersonalityRequests(params.characterId!),
    callbacks: {
      onSuccess: (data, key) => {
        if (key === CRUDKey.Read) {
          form.setFieldsValue(data);
        } else if (key === CRUDKey.Update) {
          createNotification({
            key: 'characterUpdated',
            message: 'Saved successfully.',
            type: NotificationType.Success,
            duration: 6,
          });
        }
      },
      onError: formRequestErrorHandler(form),
    },
  });

  useEffect(() => {
    if (params.characterId) request.read();
  }, [params.characterId]);

  return (
    <Loader
      error={requestKey === CRUDKey.Read ? error : undefined}
      isLoading={isLoading}
    >
      <Form
        form={form}
        onFinish={request.update}
        initialValues={response}
        layout="vertical"
        requiredMark={false}
        className="form"
        style={{ width: '100%' }}
      >
        <div className="personality-container">
          <Space
            direction="vertical"
            size="middle"
            style={{
              width: '100%',
              maxWidth: 'min(600px, 90%)',
              marginTop: '20px',
            }}
          >
            <Form.Item
              name="background"
              label="About"
              rules={[
                {
                  max: 2200,
                  message: 'About must be maximum 2000 characters',
                },
              ]}
            >
              <TextArea
                autoSize={{ minRows: 6, maxRows: 10 }}
                style={{ maxWidth: '800px' }}
              />
            </Form.Item>

            <Form.Item
              name="role"
              label="Instructions"
              tooltip="Role, goal, what to talk about, what not to talk about."
              rules={[
                {
                  max: 2200,
                  message: 'Role must be maximum 2000 characters',
                },
              ]}
            >
              <TextArea
                placeholder="Talk about the games you play or help the user learn about the products you have. If any other topic or games come up, redirect the conversation to the games you play."
                autoSize={{ minRows: 6, maxRows: 10 }}
                style={{ maxWidth: '800px' }}
              />
            </Form.Item>

            <Form.Item
              name="personalityTraits"
              label="Personality Traits"
              rules={[{ validator: tagsValidator('personality trait') }]}
            >
              <Select
                mode="tags"
                allowClear
                placeholder="Enter or select a personality trait"
                options={valuesToOptions(DEFAULT_PERSONALITY_TRAITS)}
                style={{ maxWidth: '800px' }}
              />
            </Form.Item>

            <Form.Item
              name="dialogueStyle"
              label="Dialogue Style"
              rules={[{ validator: tagsValidator('personality trait') }]}
            >
              <Select
                mode="tags"
                allowClear
                placeholder="Enter or select a personality trait"
                options={valuesToOptions(DEFAULT_DIALOGUE_STYLE)}
                style={{ maxWidth: '800px' }}
              />
            </Form.Item>

            <Form.Item
              name="dialogueExample"
              label="Dialogue Example"
              tooltip={dialogueExampleTip}
              rules={[
                {
                  max: 3200,
                  message: 'Dialogue Example must be maximum 3000 characters',
                },
              ]}
            >
              <TextArea
                placeholder={dialogueExampleTip}
                autoSize={{ minRows: 6, maxRows: 10 }}
                style={{ maxWidth: '800px' }}
              />
            </Form.Item>

            <Space>
              <WideButton
                label="Save"
                disabled={isLoading}
                onClick={form.submit}
                style={{ width: '160px' }}
                accent
              >
                <SaveOutlined />
              </WideButton>

              <If condition={user?.type === UserType.Admin}>
                <WideButton
                  label="Delete"
                  disabled={isLoading}
                  onClick={() =>
                    confirmDangerousAction({
                      action: 'Delete',
                      name: 'this profile',
                      onConfirm: request.delete,
                    })
                  }
                  style={{ width: '160px' }}
                  light
                >
                  <DeleteOutlined />
                </WideButton>
              </If>
            </Space>
          </Space>
        </div>
      </Form>
    </Loader>
  );
};

export default Personality;
