import { FC } from 'react';

import { MediaType, MessageMedia } from '../../../../../api';
import { ClassicModal, If } from '../../../../../common';
import Picture from './picture.component';
import Video from './video.component';

const MediaViewer: FC<{
  media?: MessageMedia;
  close: () => void;
}> = ({ media, close }) => {
  return (
    <ClassicModal isOpen={!!media} close={close}>
      <If condition={media}>
        <If condition={media?.type === MediaType.Picture}>
          <Picture media={media} />
        </If>

        <If condition={media?.type === MediaType.Video}>
          <Video media={media} />
        </If>
      </If>
    </ClassicModal>
  );
};

export default MediaViewer;
