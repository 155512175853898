import { Space } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const SubscriptionBenefit: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Space direction="horizontal" style={{ color: 'var(--white' }}>
      <CheckCircleOutlined />

      <div>{label}</div>
    </Space>
  );
};

export default SubscriptionBenefit;
