import { Avatar, Image, Upload } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RcFile, UploadProps } from 'antd/lib/upload';
import type { UploadFile, UploadListType } from 'antd/es/upload/interface';
import type { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { apiRequest, FileUpload, FileUploadRequest } from '../../../api';
import { DragableUploadListItem } from './draggable-upload-list-item.component';
import { promiseLifecycle } from '../../promise-lifecycle';

import './file-uploader.component.css';

const { confirm } = Modal;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const FileUploader: React.FC<{
  onChange?: (value?: UploadFile[]) => any;
  afterUpload?: (value: FileUpload) => any;
  value?: UploadFile[];
  allowedTypes?: string[];
  name?: string;
  maxCount?: number;
  multiple?: boolean;
  avatar?: boolean;
  listType?: UploadListType;
  requestUrl: string;
  [key: string]: any;
}> = ({
  onChange,
  afterUpload,
  value,
  allowedTypes,
  name,
  maxCount,
  multiple = false,
  avatar = false,
  listType = 'picture-card',
  requestUrl,
  ...props
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  useEffect(() => {
    setFileList(value || []);

    // if (value && (!fileList || fileList.length === 0)) console.log('fresh');
  }, [value]);

  const isUploading = useMemo(
    () => fileList?.some((file) => file.status === 'uploading'),
    [fileList],
  );

  const readableAllowedTypes = useMemo(() => {
    if (!allowedTypes) return undefined;

    return allowedTypes
      .map((allowedType) => allowedType.split('/')[1])
      .join(', ');
  }, [allowedTypes]);

  function onBeforeUpload(file: RcFile) {
    if (
      allowedTypes &&
      !allowedTypes?.some((allowedType) => file.type === allowedType)
    ) {
      confirm({
        icon: null,
        centered: true,
        maskClosable: true,
        okText: 'Confirm',
        cancelButtonProps: { style: { display: 'none' } },
        content: <span>{`Allowed file types: ${readableAllowedTypes}`}</span>,
      });

      return false;
    }

    return true;
  }

  function emitValue(value?: UploadFile[]) {
    if (onChange) onChange(value);
  }

  function handleUpload(options: RcCustomRequestOptions) {
    const { file, onSuccess, onError, onProgress } = options;

    const factory = () => {
      let result = apiRequest<FileUpload>(
        FileUploadRequest(requestUrl, file as RcFile, (value) =>
          onProgress?.({ percent: value }),
        ),
      );

      if (afterUpload) result = result.then(afterUpload);

      return result;
    };

    return promiseLifecycle({
      factory,
      onSuccess,
      onError: (error) => {
        onError?.(
          {
            name: error.error,
            message: Array.isArray(error.message)
              ? error.message.join(', ')
              : error.message,
            status: error.statusCode,
          },
          error,
        );
      },
    });
  }

  /*function handleUpload(options: RcCustomRequestOptions) {
    const { file, onSuccess, onError, onProgress } = options;

    return apiRequestLifecycle<FileUpload>({
      config: FileUploadRequest(requestUrl, file as RcFile, (value) =>
        onProgress?.({ percent: value }),
      ),
      onSuccess,
      onError: (error) => {
        onError?.(
          {
            name: error.error,
            message: Array.isArray(error.message)
              ? error.message.join(', ')
              : error.message,
            status: error.statusCode,
          },
          error,
        );
      },
    });
  }*/

  const onInnerChange: UploadProps['onChange'] = ({
    fileList: newFileList,
  }) => {
    // set thumbUrl from recently uploaded file, overrides local thumbnail created before upload
    const newFileListCopy = newFileList.map((file) => ({
      ...file,
      thumbUrl: file.response?.thumbnailUrl || file.thumbUrl,
    }));

    setFileList(newFileListCopy);
    emitValue(newFileListCopy);
  };

  const getSingleUrl = function () {
    return value && (value[0]?.response?.thumbnailUrl || value[0]?.thumbUrl);
  };

  const handlePreview = async (file: UploadFile) => {
    const previewImage =
      file.url || file.response?.url || (file.preview as string);

    if (!previewImage)
      file.preview = await getBase64(file.originFileObj as RcFile);

    setPreviewImage(previewImage);
    setPreviewOpen(true);
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = fileList[dragIndex];

      const fileListCopy = [...fileList];

      fileListCopy.splice(dragIndex, 1);
      fileListCopy.splice(hoverIndex, 0, dragRow);

      onInnerChange({ fileList: fileListCopy } as any);
    },
    [fileList],
  );

  const uploadedSingleFile =
    !multiple && value && value.length > 0 && getSingleUrl() && !isUploading;

  // single image preview - <img onClick={() => handlePreview(value[0])} />

  // withCredentials={true}

  return (
    <DndProvider backend={HTML5Backend}>
      <Upload
        listType={listType}
        fileList={fileList}
        beforeUpload={onBeforeUpload}
        customRequest={handleUpload}
        onChange={onInnerChange}
        disabled={isUploading}
        accept={allowedTypes?.join(', ')}
        showUploadList={multiple}
        multiple={multiple}
        maxCount={multiple ? maxCount : 1}
        onPreview={handlePreview}
        itemRender={(
          originNode: React.ReactElement,
          file: UploadFile,
          currFileList: UploadFile[],
        ) => (
          <DragableUploadListItem
            originNode={originNode}
            file={file}
            fileList={currFileList}
            moveRow={moveRow}
            highlight={currFileList.indexOf(file) === 0}
          />
        )}
        {...props}
      >
        <div className="file-uploader-wrapper">
          {uploadedSingleFile ? (
            avatar ? (
              <Avatar size={260} src={getSingleUrl()} />
            ) : (
              <img src={getSingleUrl()} className="file-uploader-image" />
            )
          ) : (
            <div>
              {isUploading ? (
                <>
                  <LoadingOutlined />

                  <div style={{ marginTop: 8 }}>Uploading...</div>
                </>
              ) : (
                <>
                  <UploadOutlined />

                  <div style={{ marginTop: 8 }}>
                    Upload{name ? ' ' + name : ''}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Upload>

      <Modal
        open={isPreviewOpen}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        width="80%"
      >
        <Image style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </DndProvider>
  );
};
