import { useEffect, useRef } from 'react';
import { Space, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  CharacterParams,
  CreateTransloaditAssembliesRequest,
  TransloaditAssemblyListRequest,
  MediaListRequest,
  MediaParams,
  useAPIRequest,
  TransloaditAssemblyParams,
  TransloaditAssemblyStatus,
  ReadCharacterRequest,
} from '../../../../api';
import { If, Loader } from '../../../../common';
import AddMedia from './add-media.component';
import { NotificationType, createNotification } from '../../../../utils';
import MediaItem from './media-item.component';
import { useParams } from 'react-router-dom';

const Medias: React.FC = () => {
  const params = useParams();
  const list = useRef<MediaParams[]>([]);

  const { response: characterResponse } = useAPIRequest<CharacterParams>(() =>
    ReadCharacterRequest(params.characterId!),
  );

  const { request, response, setResponse, error, isLoading } = useAPIRequest<{
    data: MediaParams[];
  }>(MediaListRequest, {
    immediate: false,
    onSuccess: (value) => {
      list.current = value.data;
    },
  });

  const {
    request: getTransloaditAssemblyList,
    isLoading: isLoadingTransloaditAssemblyList,
    response: transloaditAssemblyListResponse,
  } = useAPIRequest<{
    totalFilesProcessing: number;
    data: TransloaditAssemblyParams[];
  }>(TransloaditAssemblyListRequest, {
    immediate: false,
    shouldRetry: (value) => value.data.length > 0,
    retryInterval: 3000,
    onSuccess: (value) => {
      let newMedias: MediaParams[] = [];

      value.data.map((assembly) => {
        if (assembly.status === TransloaditAssemblyStatus.Completed) {
          newMedias = newMedias.concat(assembly.medias);
        } else if (assembly.status === TransloaditAssemblyStatus.Error) {
          createNotification({
            key: 'serverError',
            message: assembly.error || 'Upload error',
            type: NotificationType.Error,
          });
        }
      });

      if (newMedias.length > 0) {
        // setResponse is problematic, different TransloaditAssemblyListRequest responses can override each other
        // as the request keeps retrying with the same js context until all the assemblies are completed
        // using a ref as a helper workaround

        list.current = list.current.concat(newMedias);

        setResponse({ data: list.current });
      }
    },
  });

  const { request: createAssemblies } =
    useAPIRequest<TransloaditAssemblyParams>(
      CreateTransloaditAssembliesRequest,
      {
        immediate: false,
        onSuccess: () => {
          if (!isLoadingTransloaditAssemblyList) {
            getTransloaditAssemblyList(characterResponse?.id);
          }
        },
      },
    );

  useEffect(() => {
    if (characterResponse) {
      request(characterResponse.id);
      getTransloaditAssemblyList(characterResponse.id);
    }
  }, [characterResponse]);

  const afterUploadRef = useRef(createAssemblies);

  afterUploadRef.current = createAssemblies;

  const totalFilesProcessing =
    transloaditAssemblyListResponse?.totalFilesProcessing || 0;

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', padding: '20px' }}
      size={0}
    >
      <Loader error={error} isLoading={isLoading}>
        <AddMedia
          characterId={characterResponse?.id}
          afterUploadRef={afterUploadRef}
          style={{ marginBottom: '20px' }}
        />

        <Space size="large" style={{ width: '100%' }} wrap>
          {response?.data?.map((item) => (
            <MediaItem
              characterId={params.characterId!}
              data={item}
              key={item.id}
            />
          ))}
        </Space>

        <If condition={totalFilesProcessing > 0}>
          <Space
            direction="horizontal"
            size="middle"
            style={{ color: 'var(--white)', marginTop: '40px' }}
          >
            <div>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
            {`Processing ${totalFilesProcessing} file${
              totalFilesProcessing !== 1 ? 's' : ''
            }...`}
          </Space>
        </If>
      </Loader>
    </Space>
  );
};

export default Medias;
