import { AxiosRequestConfig } from 'axios';

export const ReadCharacterPersonalityRequest = (
  id: string,
): AxiosRequestConfig => {
  return {
    url: `v1/character/${id}/personality`,
    method: 'get',
  };
};
