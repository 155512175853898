export const MessageSpinnerSvg = () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: 'white' }}
  >
    <circle className="spinner_b2T7" cx="4" cy="12" r="3" />
    <circle className="spinner_b2T7 spinner_YRVV" cx="12" cy="12" r="3" />
    <circle className="spinner_b2T7 spinner_c9oY" cx="20" cy="12" r="3" />
  </svg>
);
