import { AxiosRequestConfig } from 'axios';

import { Message } from '../chat';

export const FindCharacterRequest = (handle: string): AxiosRequestConfig => {
  return {
    url: 'v1/character/find',
    method: 'get',
    params: { handle },
    transformResponse: (response: string) => {
      if (response.includes('"error"')) return JSON.parse(response);

      const { guestWelcomeMessage, ...data }: { guestWelcomeMessage: Message } =
        JSON.parse(response);

      return {
        ...data,
        guestWelcomeMessage: guestWelcomeMessage
          ? {
              ...guestWelcomeMessage,
              sent: new Date(guestWelcomeMessage.sent),
            }
          : undefined,
      };
    },
  };
};
