import { AxiosRequestConfig } from 'axios';

import { AcceptInvitationGoogleParams } from './accept-invitation-google-params.interface';

export const AcceptInvitationGoogleRequest = (
  params: AcceptInvitationGoogleParams,
): AxiosRequestConfig => {
  return {
    url: 'v1/invitation/accept/oauth/google',
    method: 'post',
    data: params,
  };
};
