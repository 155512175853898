import create from 'zustand';

import { ChatCharacter } from '../api';
import { CallState } from '../common';

interface CallStoreState {
  character?: ChatCharacter;
  state?: CallState;
  inProgress: boolean;
  requestEnd: boolean;
  secondsElapsed?: number;
  reset: () => void;
  setCharacter: (value?: ChatCharacter) => void;
  setState: (value: CallState) => void;
  setInProgress: (value: boolean) => void;
  setRequestEnd: (value: boolean) => void;
  setSecondsElapsed: (value: number) => void;
}

export const useCallStore = create<CallStoreState>((set) => ({
  character: undefined,
  state: undefined,
  inProgress: false,
  requestEnd: false,
  secondsElapsed: 0,
  reset: () => {
    set({
      character: undefined,
      state: undefined,
      inProgress: false,
      requestEnd: false,
      secondsElapsed: 0,
    });
  },
  setCharacter: (value?: ChatCharacter) => set({ character: value }),
  setState: (value: CallState) => set({ state: value }),
  setInProgress: (value: boolean) => set({ inProgress: value }),
  setRequestEnd: (value: boolean) => set({ requestEnd: value }),
  setSecondsElapsed: (value: number) => set({ secondsElapsed: value }),
}));
