import { Space } from 'antd';
import Icon from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { DiscordSvg, InstagramSvg, LogoIcon, XSvg } from '../../../../common';

import './index-footer.component.css';

const IndexFooter: React.FC<{
  style?: React.CSSProperties;
}> = ({ style }) => {
  return (
    <div className="index-footer-container" style={style}>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <div
          className="transparent-logo"
          style={{ marginBottom: '16px', userSelect: 'none' }}
        >
          <Icon component={LogoIcon} style={{ marginRight: '10px' }} />
          Swoons
          <br />
        </div>

        <Space
          direction="horizontal"
          size="middle"
          style={{
            justifyContent: 'center',
            width: '100%',
            fontSize: '14px',
          }}
        >
          <Link to="tos" style={{ color: 'var(--white)' }}>
            Terms of Service
          </Link>

          <Link to="privacy" style={{ color: 'var(--white)' }}>
            Privacy Policy
          </Link>
        </Space>
      </div>

      <div className="social-media-container">
        <hr className="divider" style={{ marginBottom: '20px' }} />

        <div style={{ marginBottom: '20px', fontSize: '14px' }}>
          Follow Us On Social Media
        </div>

        <Space
          direction="horizontal"
          size="middle"
          style={{
            justifyContent: 'center',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <Link to={process.env.REACT_APP_INSTAGRAM!} target="_blank">
            <div className="social-media">
              <Icon component={InstagramSvg} style={{ width: '20px' }} />
            </div>
          </Link>

          <Link to={process.env.REACT_APP_X!} target="_blank">
            <div className="social-media">
              <Icon component={XSvg} style={{ width: '20px' }} />
            </div>
          </Link>

          <Link to={process.env.REACT_APP_DISCORD!} target="_blank">
            <div className="social-media">
              <Icon component={DiscordSvg} style={{ width: '20px' }} />
            </div>
          </Link>
        </Space>

        <hr className="divider" style={{ marginBottom: '30px' }} />
      </div>

      <div style={{ marginBottom: '20px', fontSize: '14px' }}>
        ©2024 SmartNPC Inc.
      </div>
    </div>
  );
};

export default IndexFooter;
