import { Space } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CharacterParams,
  useAPIRequest,
  CharacterListRequest,
  UserType,
} from '../../../../api';
import { If, Loader } from '../../../../common';
import CreateCharacter from './create-character.component';
import CharacterItem from './character-item.component';
import { useInitStore } from '../../../../stores';

const Characters: React.FC = () => {
  const navigate = useNavigate();

  const { response, error, isLoading } = useAPIRequest<{
    data: CharacterParams[];
  }>(CharacterListRequest);

  const user = useInitStore((state) => state.user);

  useEffect(() => {
    if (response) {
      if (response.data.length === 0) {
        navigate('/creator/onboarding');
      }
    }
  }, [response]);

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', padding: '20px' }}
      size={0}
    >
      <Loader error={error} isLoading={isLoading}>
        <If condition={user?.type === UserType.Admin}>
          <CreateCharacter style={{ marginBottom: '20px' }} />
        </If>

        <Space size="large" style={{ width: '100%' }} wrap>
          {response?.data?.map((item) => (
            <CharacterItem data={item} key={item.id} />
          ))}
        </Space>
      </Loader>
    </Space>
  );
};

export default Characters;
