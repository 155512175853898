import { FC, useState } from 'react';
import Icon from '@ant-design/icons';
import WavesurferPlayer from '@wavesurfer/react';
import WaveSurfer from 'wavesurfer.js';

import { PauseSvg, PlaySvg } from './audio-player-svg.component';

import './audio-player.component.css';

export const AudioPlayer: FC<{
  url?: string;
  waveColor?: string;
  progressColor?: string;
  buttonBackgroundColor?: string;
  buttonIconColor?: string;
  marginRight?: string;
  barWidth?: number;
  barRadius?: number;
  style?: React.CSSProperties;
}> = ({
  url,
  waveColor,
  progressColor,
  buttonBackgroundColor,
  buttonIconColor,
  marginRight,
  barWidth,
  barRadius,
  style,
}) => {
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const onReady = (value: WaveSurfer) => {
    setWavesurfer(value);
    setIsPlaying(false);
  };

  const onClick = () => {
    if (wavesurfer) wavesurfer.playPause();
  };

  return (
    <div className="audio-player" style={style}>
      <button
        onClick={onClick}
        style={{
          backgroundColor: buttonBackgroundColor,
          color: buttonIconColor,
          marginRight: '15px',
        }}
      >
        <Icon component={isPlaying ? PauseSvg : PlaySvg} />
      </button>

      <div style={{ flex: 1, marginRight }}>
        <WavesurferPlayer
          height={30}
          normalize={true}
          cursorWidth={0}
          barGap={3}
          barWidth={barWidth}
          barRadius={barRadius}
          waveColor={waveColor}
          progressColor={progressColor}
          url={url}
          onReady={onReady}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
      </div>
    </div>
  );
};
