import { Space } from 'antd';
import { FC } from 'react';

import { If } from '../if.component';

import './wallet-drawer-title.component.css';

const WalletDrawerTitle: FC<{
  insufficientBalance: boolean;
  style?: React.CSSProperties;
}> = ({ insufficientBalance, style }) => {
  return (
    <Space
      direction="vertical"
      align="center"
      style={{ justifyContent: 'center', width: '100%', ...style }}
      size={24}
    >
      <label className="wallet-drawer-title">
        {insufficientBalance
          ? `You're out of credits!`
          : `Add credits to your wallet`}
      </label>

      <If condition={insufficientBalance}>
        <label className="wallet-drawer-subtitle">
          top up credits to continue talking
        </label>
      </If>
    </Space>
  );
};

export default WalletDrawerTitle;
