import { green, red } from '@ant-design/colors';
import { notification } from 'antd';

export enum NotificationType {
  Success = 'success',
  Error = 'error',
}

export interface NotificationOptions {
  key: string;
  message: string | string[];
  duration?: number;
  type: NotificationType;
}

export function createNotification({
  key,
  message,
  duration,
  type,
}: NotificationOptions) {
  let color: string | undefined = undefined;

  if (type === NotificationType.Success) {
    color = green.primary;
  } else if (type === NotificationType.Error) {
    color = red.primary;
  }

  notification.open({
    key,
    message: <span style={{ color }}>{message}</span>,
    placement: 'topRight',
    duration: duration || 3,
    style: { top: '74px' },
  });
}
