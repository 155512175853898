import create from 'zustand';

import { AuthDrawerState } from '../common';

interface MiscStoreState {
  siderWidth: string;
  menuOpen: boolean;
  authOpen: boolean;
  authState: AuthDrawerState;
  walletOpen: boolean;
  walletInsufficientBalance: boolean;
  subscriptionModalOpen: boolean;
  setWalletOpen: (open: boolean, insufficientFunds?: boolean) => void;
  setSubscriptionModalOpen: (open: boolean) => void;
  setMenuOpen: (open: boolean) => void;
  setAuthOpen: (open: boolean, state?: AuthDrawerState) => void;
}

export const useMiscStore = create<MiscStoreState>((set) => ({
  siderWidth: '250px',
  menuOpen: false,
  authOpen: false,
  walletOpen: false,
  walletInsufficientBalance: false,
  subscriptionModalOpen: false,
  authState: AuthDrawerState.SignUp,
  setWalletOpen: (open: boolean, insufficientBalance = false) => {
    if (open) {
      set({ walletOpen: open, walletInsufficientBalance: insufficientBalance });
    } else set({ walletOpen: open });
  },
  setSubscriptionModalOpen: (open: boolean) => {
    set({ subscriptionModalOpen: open });
  },
  setMenuOpen: (open: boolean) => set({ menuOpen: open }),
  setAuthOpen: (
    open: boolean,
    state: AuthDrawerState = AuthDrawerState.SignUp,
  ) => {
    if (!open) set({ authOpen: open });
    else set({ authOpen: open, authState: state });
  },
}));
