import { AxiosRequestConfig } from 'axios';

import { SignInParams } from './sign-in-params.interface';

export const SignInRequest = (params: SignInParams): AxiosRequestConfig => {
  return {
    url: 'v1/user/signin',
    method: 'post',
    data: params,
  };
};
