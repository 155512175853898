import { Row, Col, Card, Statistic, ConfigProvider } from 'antd';
import { useParams } from 'react-router-dom';

import { Loader } from '../../../../common';
import {
  CharacterOverviewRequest,
  OverviewBillingPeriod,
  OverviewParams,
  useAPIRequest,
} from '../../../../api';
import { formatCurrency } from '../../../../utils';

import './character-overview.component.css';

const CharacterOverview: React.FC = () => {
  const params = useParams();

  const { response, isLoading, error } = useAPIRequest<OverviewParams>(() =>
    CharacterOverviewRequest(params.characterId!),
  );

  return (
    <Loader isLoading={isLoading} error={error} style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div className="character-overview-container">
          <div className="title">Earnings</div>

          <ConfigProvider
            theme={{ token: { colorBgContainer: 'var(--grey3)' } }}
          >
            <Row
              gutter={16}
              style={{ marginTop: '20px', width: '100%', maxWidth: '800px' }}
            >
              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title={<div style={{ color: 'var(--grey6)' }}>Current</div>}
                    value={formatCurrency(
                      response?.[OverviewBillingPeriod.Current] || 0,
                    )}
                    valueStyle={{ color: 'var(--white)' }}
                  />
                </Card>
              </Col>

              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title={
                      <div style={{ color: 'var(--grey5)' }}>All Time</div>
                    }
                    value={formatCurrency(
                      response?.[OverviewBillingPeriod.AllTime] || 0,
                    )}
                    valueStyle={{ color: 'var(--grey6)' }}
                  />
                </Card>
              </Col>
            </Row>
          </ConfigProvider>
        </div>
      </div>
    </Loader>
  );
};

export default CharacterOverview;
