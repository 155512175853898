import { Modal } from 'antd';

const { confirm } = Modal;

export function confirmDangerousAction({
  action,
  actionText,
  cancelText,
  dangerColor = true,
  name,
  content,
  onConfirm,
  onCancel,
}: {
  action: string;
  actionText?: string;
  cancelText?: string;
  dangerColor?: boolean;
  name?: string;
  content?: string;
  onConfirm: () => any;
  onCancel?: () => any;
}) {
  return confirm({
    icon: null,
    centered: true,
    maskClosable: true,
    okText: actionText || action,
    cancelText,
    okButtonProps: { danger: dangerColor },
    content: (
      <span>
        {content
          ? content
          : `Are you sure you want to ${action.toLowerCase()} ${name}?`}
      </span>
    ),
    onOk: onConfirm,
    onCancel: onCancel,
  });
}
