import { AxiosRequestConfig } from 'axios';

import { transformMessageResponse } from './transform-message-response';

export const WelcomeMessageRequest = (id: string): AxiosRequestConfig => {
  return {
    url: `v1/chat/${id}/welcome-message`,
    method: 'post',
    transformResponse: transformMessageResponse,
  };
};
