import { AppLayout } from '../../../layouts/app/app-layout.component';
import { RouteData } from '../../../utils';
import Browse from './browse.component';

export const BrowseRoutes: RouteData[] = [
  {
    path: 'browse',
    component: Browse,
    authRequired: true,
    layout: AppLayout,
  },
];
