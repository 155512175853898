import { Form, Input, Modal, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useAPIRequest,
  formRequestErrorHandler,
  CharacterParams,
  CreateCharacterRequest,
} from '../../../../api';
import {
  createNotification,
  NotificationType,
  RouteData,
} from '../../../../utils';
import { WideButton } from '../../../../common';
import HandleFormItem from '../character-profile/handle-form-item.component';

import './create-character.component.css';

const { Title } = Typography;

const CreateCharacter: React.FC<{
  rawRoutes?: { [key: string]: RouteData[] };
  style?: React.CSSProperties;
}> = ({ rawRoutes, style }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const { request, response, isLoading } = useAPIRequest<CharacterParams>(
    CreateCharacterRequest,
    {
      immediate: false,
      onSuccess: (response) => {
        createNotification({
          key: 'characterCreated',
          message: 'Profile created successfully.',
          type: NotificationType.Success,
          duration: 6,
        });

        setOpen(false);

        navigate(`/creator/character/${response.id}`);
      },
      onError: formRequestErrorHandler(form),
    },
  );

  return (
    <>
      <WideButton
        label="New Profile"
        onClick={() => setOpen(true)}
        style={{ width: '160px', ...style }}
        light
      >
        <PlusOutlined />
      </WideButton>

      <Modal
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        closable={!isLoading}
        maskClosable={false}
        width="80%"
        style={{ maxWidth: '500px' }}
        className="create-character-container"
      >
        <Title
          style={{
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          New Profile
        </Title>

        <Form
          form={form}
          onFinish={request}
          initialValues={response}
          layout="vertical"
          requiredMark={false}
          disabled={isLoading}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: 'Name is required' },
              {
                max: 30,
                message: 'Name must be maximum 30 characters',
              },
            ]}
          >
            <Input placeholder="Ex: Jane Doe" style={{ maxWidth: '500px' }} />
          </Form.Item>

          <HandleFormItem rawRoutes={rawRoutes} />

          <div className="buttons-container" style={{ marginTop: '20px' }}>
            <WideButton
              label="Cancel"
              disabled={isLoading}
              onClick={() => setOpen(false)}
              style={{ flex: 1, marginRight: '10px' }}
              light
            />

            <WideButton
              label="Create"
              disabled={isLoading}
              onClick={form.submit}
              style={{ flex: 1 }}
              accent
            />
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CreateCharacter;
