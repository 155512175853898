import { AxiosRequestConfig } from 'axios';
import { KnowledgeEntryParams } from './knowledge-entry-params.interface';

export const KnowledgeEntryListRequest = (
  characterId: string,
): AxiosRequestConfig => {
  return {
    url: `v1/knowledge/list`,
    method: 'get',
    params: { characterId },
    transformResponse: (response: string) => {
      const { data }: { data: KnowledgeEntryParams[] } = JSON.parse(response);

      return {
        data: data.map((item) => ({
          ...item,
          created: new Date(item.created),
        })),
      };
    },
  };
};
