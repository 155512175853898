import { ConfigProvider, Progress } from 'antd';

import { formatDuration } from '../../../../../utils';

const VoiceSampleProgress: React.FC<{
  durationSeconds: number;
  requiredSeconds: number;
}> = ({ durationSeconds, requiredSeconds }) => {
  const progress = Math.min(
    Math.floor((durationSeconds / requiredSeconds) * 100),
    100,
  );

  const duration = formatDuration(durationSeconds);

  const progressColor = progress < 100 ? 'var(--grey6)' : 'var(--white)';
  const trailColor = progress < 100 ? 'var(--grey4)' : 'var(--grey5)';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Progress: {
              circleTextColor: progressColor,
            },
          },
        }}
      >
        <Progress
          type="circle"
          percent={progress}
          strokeColor={progressColor}
          trailColor={trailColor}
          status="normal"
          success={{ percent: progress, strokeColor: progressColor }}
          format={() => duration}
          style={{ marginTop: '20px' }}
        />
      </ConfigProvider>

      <div style={{ color: progressColor, marginTop: '10px' }}>
        Minutes Uploaded
      </div>
    </div>
  );
};

export default VoiceSampleProgress;
