import { useEffect, useRef } from 'react';

import { useAuthStore } from '../../stores';
import {
  AuthResponse,
  ChatCharacter,
  CreateGuestRequest,
  useAPIRequest,
} from '../../api';

export function useGuestMode(character?: ChatCharacter) {
  const lastGuestModeEnabled = useRef<boolean>(false);
  const lastToken = useRef<string>();

  const { token, setToken, clearToken, guestToken, setGuestToken } =
    useAuthStore();

  const { request } = useAPIRequest<AuthResponse>(CreateGuestRequest, {
    immediate: false,
    onSuccess: (response) => {
      setToken(response.token);
      setGuestToken(response.token);
    },
  });

  useEffect(() => {
    if (
      character &&
      (character.guestModeEnabled !== lastGuestModeEnabled.current ||
        token !== lastToken.current)
    ) {
      if (token) {
        if (!character.guestModeEnabled && token === guestToken) {
          clearToken();
        }
      } else {
        if (character.guestModeEnabled) {
          if (guestToken) setToken(guestToken);
          else request();
        }
      }

      lastGuestModeEnabled.current = character.guestModeEnabled;
      lastToken.current = token;
    }
  }, [character, token, guestToken, lastGuestModeEnabled, lastToken]);
}
