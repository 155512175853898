import { Typography } from 'antd';

import { LegalItemContent } from '../interfaces';

const { Text } = Typography;

export const LegalPoints: React.FC<{
  data?: LegalItemContent[];
  indexes: number[];
  style?: React.CSSProperties;
}> = ({ data, indexes, style }) => {
  if (!data) return <></>;

  return (
    <ul style={style}>
      {data.map((value, index) => {
        const newIndexes = [...indexes, index + 1];

        return (
          <li key={'legalPoint_' + newIndexes.join('-')}>
            {typeof value === 'string' ? <Text>{value}</Text> : value}
          </li>
        );
      })}
    </ul>
  );
};
