import { AxiosRequestConfig } from 'axios';

import { UserParams } from './user-params.interface';

export const UpdateUserRequest = (
  params: Omit<UserParams, 'id'>,
): AxiosRequestConfig => {
  return {
    url: 'v1/user',
    method: 'put',
    data: params,
  };
};
