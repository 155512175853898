import { AxiosRequestConfig } from 'axios';

export const CreateProductRequest = (
  characterId: string,
  params: { name: string },
): AxiosRequestConfig => {
  return {
    url: 'v1/product',
    method: 'post',
    data: { characterId, ...params },
  };
};
