import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePersistedStore } from '../../../stores';
import { useLayoutRequired } from '../../../layouts/use-layout-children.hook';

export const PersistDefaultPath = ({ element }: { element: JSX.Element }) => {
  const location = useLocation();

  const setDefaultPath = usePersistedStore((state) => state.setDefaultPath);

  // currently rendering a "required" route, e.g. confirm-email. used to not reset defaultPath when rendering required routes
  const layoutRequired = useLayoutRequired();

  useEffect(() => {
    if (!layoutRequired && location.pathname) setDefaultPath(location.pathname);
  }, [location.pathname]);

  return element;
};
