import { FC } from 'react';
import { Space } from 'antd';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

import './feature.component.css';

export const Feature: FC<{
  icon:
    | React.ComponentType<
        CustomIconComponentProps | React.SVGProps<SVGSVGElement>
      >
    | React.ForwardRefExoticComponent<CustomIconComponentProps>;
  label: string;
  price?: string;
  style?: React.CSSProperties;
}> = ({ icon, label, price, style }) => {
  const words = label.split(' ');

  return (
    <Space direction="vertical" size={8} align="center">
      <div className="feature" style={style}>
        {icon && (
          <div style={{ marginBottom: '6px' }}>
            <Icon component={icon} style={{ width: '32px' }} />
          </div>
        )}

        {words[0] && <div>{words[0]}</div>}
        {words[1] && <div>{words[1]}</div>}
      </div>

      {price && <div className="feature-price">{price}</div>}
    </Space>
  );
};
