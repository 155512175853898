import { Space } from 'antd';
import Icon from '@ant-design/icons';

import { useChatStore, useInitStore, useMiscStore } from '../../../stores';
import { ClassicModal } from '../classic-modal/classic-modal.component';
import { CloseSvg, SubscriptionSvg } from '../common-svg.component';
import { WideButton } from '../wide-button/wide-button.component';
import SubscriptionBenefit from '../../../routes/app/subscription/subscription-benefit.component';
import { useAPIRequest, SubscriptionCheckoutRequest } from '../../../api';

import './subscription-modal.component.css';

export const SubscriptionModal: React.FC = () => {
  const { subscriptionPlans } = useInitStore();
  const { subscriptionModalOpen, setSubscriptionModalOpen } = useMiscStore();
  const character = useChatStore((state) => state.character);

  const plan = subscriptionPlans[0];

  const { request } = useAPIRequest<{
    url: string;
  }>(SubscriptionCheckoutRequest, {
    immediate: false,
    onSuccess: ({ url }) => {
      if (url) window.location.href = url;
    },
  });

  const confirm = () => {
    request({
      characterId: character?.id,
      priceId: plan?.id,
      route: location.pathname,
    });
  };

  return (
    <ClassicModal
      isOpen={subscriptionModalOpen}
      close={() => setSubscriptionModalOpen(false)}
      width="90%"
      maxWidth="400px"
    >
      <div
        className="button-circle"
        onClick={() => setSubscriptionModalOpen(false)}
      >
        <Icon component={CloseSvg} />
      </div>

      <div className="subscription-modal-container">
        <Space direction="vertical" size={40} style={{ marginBottom: '40px' }}>
          <div
            style={{
              textAlign: 'center',
              fontSize: '28px',
              color: 'var(--white)',
            }}
          >
            Upgrade to Unlock
            <br />
            <span style={{ fontWeight: 'bold' }}>Premium Features!</span>
          </div>

          <Space direction="vertical" size="middle">
            <SubscriptionBenefit label="Voice messages" />
            <SubscriptionBenefit label="Pictures" />
            <SubscriptionBenefit label="Videos" />
            <SubscriptionBenefit label="Voice calls (coming soon)" />
            <SubscriptionBenefit
              label={`$${plan?.monthlyCredits} free credits / month`}
            />
            <SubscriptionBenefit label="Cancel anytime" />
          </Space>
        </Space>

        <WideButton label="Upgrade to Premium" accent onClick={confirm}>
          <Icon component={SubscriptionSvg} style={{ width: '18px' }} />
        </WideButton>

        <div
          style={{
            fontSize: '14px',
            textWrap: 'nowrap',
            color: 'var(--grey6)',
            marginTop: '10px',
          }}
        >
          ${plan?.monthlyPrice} / month
        </div>
      </div>
    </ClassicModal>
  );
};
