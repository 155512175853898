import { FC } from 'react';

import { MessageMedia } from '../../../../../api';
import { useFitSize } from '../../../../../common';

import './picture.component.css';

const Picture: FC<{ media?: MessageMedia }> = ({ media }) => {
  const { width, height } = useFitSize({
    width: media?.width || 0,
    height: media?.height || 0,
  });

  return (
    <div
      style={{
        backgroundImage: `url('${media?.url}')`,
        width: width + 'px',
        height: height + 'px',
      }}
      className="picture"
    />
  );
};

export default Picture;
