import { AxiosRequestConfig } from 'axios';

import { WaitlistParams } from './waitlist-params.interface';

export const WaitlistRequest = (params: WaitlistParams): AxiosRequestConfig => {
  return {
    url: `v1/creator/waitlist`,
    method: 'post',
    data: params,
  };
};
