import { Button, Col, Input, Row, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';

import { createNotification, NotificationType } from '../../utils';

const { Text } = Typography;

export const CopyableText: React.FC<{
  label: string;
  value?: string;
  labelMinWidth?: string;
  valueMaxWidth?: string;
  style?: React.CSSProperties;
}> = ({
  label,
  value,
  labelMinWidth = '60px',
  valueMaxWidth = '600px',
  style,
}) => {
  function copyValue() {
    if (!value) return;

    copy(value);

    createNotification({
      key: 'copyKeyText-' + value,
      message: 'Copied to clipboard',
      type: NotificationType.Success,
      duration: 6,
    });
  }
  return (
    <Row style={style}>
      <Col
        span={2}
        style={{
          minWidth: labelMinWidth,
          display: 'flex',
          alignItems: 'center',
          marginRight: '10px',
        }}
      >
        <Text type="secondary">{label}</Text>
      </Col>

      <Col flex={1} style={{ maxWidth: valueMaxWidth, marginRight: '10px' }}>
        <Input value={value} readOnly />
      </Col>

      <Col>
        <Button icon={<CopyOutlined />} onClick={copyValue} />
      </Col>
    </Row>
  );
};
