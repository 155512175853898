import { CRUDKey } from '../../../common';
import { Requests } from '../../hooks';
import { DeleteMediaRequest } from './delete-media.request';
import { ReadMediaRequest } from './read-media.request';
import { UpdateMediaRequest } from './update-media.request';
import { MediaParams } from './media-params.interface';

export function getMediaRequests(id: string): Requests<MediaParams> {
  return {
    [CRUDKey.Read]: () => ReadMediaRequest(id),
    [CRUDKey.Update]: (params, rotation) =>
      UpdateMediaRequest(id, params, rotation),
    [CRUDKey.Delete]: () => DeleteMediaRequest(id),
  };
}
