import { AxiosRequestConfig } from 'axios';

import { AcceptInvitationParams } from './accept-invitation-params.interface';

export const AcceptInvitationRequest = (
  params: AcceptInvitationParams,
): AxiosRequestConfig => {
  return {
    url: 'v1/invitation/accept',
    method: 'post',
    data: params,
  };
};
