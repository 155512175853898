import { isLocal } from '../../common';
// import { initializeDatadogRum } from './datadog-rum';
import { initializeDatadogLogs } from './datadog-logs';
import { initializeGoogleTagManager } from './google-tag-manager';
import { initializeMixpanel } from './mixpanel';

export function initializeThirdPartyLibraries() {
  if (!isLocal()) {
    // initializeDatadogRum();
    initializeDatadogLogs();
    initializeGoogleTagManager();
  }

  initializeMixpanel();
}
