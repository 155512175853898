import { AxiosRequestConfig } from 'axios';

import { SubscriptionParams } from './subscription-params.interface';

export const ReadUserRequest = (): AxiosRequestConfig => {
  return {
    url: 'v1/user',
    method: 'get',
    transformResponse: (response: string) => {
      if (response.includes('"error"')) return JSON.parse(response);

      const { subscription, ...data }: { subscription: SubscriptionParams } =
        JSON.parse(response);

      return {
        ...data,
        subscription: {
          ...subscription,
          periodEnd: subscription.periodEnd
            ? new Date(subscription.periodEnd)
            : null,
        },
      };
    },
  };
};
