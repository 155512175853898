export enum Env {
  Development = 'development',
  Production = 'production',
}

export function getEnv(): Env {
  return process.env.NODE_ENV as Env;
}

export function isDevelopment(): boolean {
  return getEnv() === Env.Development;
}

export function isProduction(): boolean {
  return getEnv() === Env.Production;
}

export function isLocal(): boolean {
  return process.env.REACT_APP_LOCAL === 'true';
}
