import { RcFile } from 'antd/lib/upload';
import { AxiosRequestConfig } from 'axios';

import { transformMessageResponse } from './transform-message-response';

export const VoiceMessageRequest = ({
  id,
  file,
  onUploadProgress,
}: {
  id: string;
  file: RcFile;
  onUploadProgress?: (value: number) => void;
}): AxiosRequestConfig => {
  const formData = new FormData();

  formData.append('file', file);

  return {
    url: `v1/chat/${id}/voice-message`,
    method: 'post',
    data: formData,
    headers: { 'content-type': 'multipart/form-data' },
    transformResponse: transformMessageResponse,
    onUploadProgress: (event) =>
      onUploadProgress?.((event.loaded / event.total) * 100),
  };
};
