import { AxiosRequestConfig } from 'axios';

export const SubscriptionCheckoutRequest = (data: {
  characterId?: string;
  priceId: string;
  route: string;
}): AxiosRequestConfig => {
  return {
    url: 'v1/billing/subscription/checkout',
    method: 'post',
    data,
  };
};
