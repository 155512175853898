import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { cssObjectToString } from '../../../utils';

import './wide-button.component.css';

export const WideButton: FC<
  PropsWithChildren<{
    label: string;
    accent?: boolean;
    light?: boolean;
    faded?: boolean;
    red?: boolean;
    transparent?: boolean;
    disabled?: boolean;
    to?: string;
    target?: string;
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
  }>
> = ({
  children,
  label,
  accent,
  light,
  faded,
  red,
  transparent,
  disabled,
  to,
  target,
  onClick,
  className,
  style,
}) => {
  const joinedClassName = cssObjectToString({
    [className!]: true,
    'wide-button': true,
    accent,
    light,
    faded,
    red,
    transparent,
    disabled,
  });

  if (to) {
    return (
      <Link
        className={joinedClassName}
        style={style}
        to={to}
        target={target}
        onClick={disabled ? undefined : onClick}
      >
        {children && <div style={{ marginRight: '6px' }}>{children}</div>}

        <div>{label}</div>
      </Link>
    );
  }

  return (
    <div
      className={joinedClassName}
      style={style}
      onClick={disabled ? undefined : onClick}
    >
      {children && <div style={{ marginRight: '6px' }}>{children}</div>}

      <div>{label}</div>
    </div>
  );
};
