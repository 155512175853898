import { Features, WideButton } from '../../../common';
import { useInitStore, useMiscStore } from '../../../stores';

import './wallet.component.css';

const Wallet: React.FC = () => {
  const user = useInitStore((state) => state.user);

  const { setWalletOpen } = useMiscStore();

  /*const { setWalletOpen, setSubscriptionModalOpen } = useMiscStore();

  useEffect(() => {
    if (!user?.subscription.active) setSubscriptionModalOpen(true);
  }, []);*/

  return (
    <div className="wallet-page-container">
      <div className="wallet-container">
        <div className="balance-container">
          <div className="balance-title">Balance</div>

          <div className="balance-value">${user?.balance?.toFixed(2)}</div>

          <WideButton
            label="Top Up"
            accent
            onClick={() => setWalletOpen(true)}
          />

          {/*<If condition={user?.subscription.active}>
            <WideButton
              label="Top Up"
              accent
              onClick={() => setWalletOpen(true)}
            />
          </If>

          <If condition={!user?.subscription.active}>
            <WideButton
              label="Subscribe for more"
              accent
              onClick={() => setSubscriptionModalOpen(true)}
            />
          </If>*/}
        </div>
      </div>

      <div className="wallet-note" style={{ marginTop: '1px' }}>
        Powered by Stripe
      </div>

      <Features
        style={{
          padding: '0px 20px 20px 20px',
          marginTop: '32px',
          justifyContent: 'center',
        }}
      />
    </div>
  );
};

export default Wallet;
