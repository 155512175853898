import { AxiosRequestConfig } from 'axios';

export const CharacterOverviewRequest = (
  characterId: string,
): AxiosRequestConfig => {
  return {
    url: 'v1/billing/overview/creator/character',
    method: 'get',
    params: { characterId },
  };
};
