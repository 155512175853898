import { SupportEmail, LegalRenderer, SimpleUrl } from './components';
import { LegalItem } from './interfaces';

const data: LegalItem = {
  content: [
    `SmartNPC (referred to as "The Company") offers a platform called "Swoons" enabling users to engage with digital personas ("Characters") powered by artificial intelligence (AI). This policy outlines the rules and guidelines for using the platform. By accessing and interacting with digital personas on Swoons, you agree to comply with these terms.`,
  ],
  children: [
    {
      title: 'Terms',
      content:
        'When interacting with a digital persona on Swoons, you must ensure that your actions are legal and comply with our Terms of Service.',
    },
    {
      title: 'Age',
      content:
        'You confirm that you are at least 18 years old or the age of majority in your jurisdiction, and that you are fully capable of agreeing to and complying with the terms outlined in these Terms of Service.',
    },
    {
      title: `Use of Real Persons' Identity`,
      children: [
        `The digital personas modeled after real influencers, celebrities, or models have been created with their full legal consent. However, it is essential to interact with these personas in a non-defamatory manner that respects the rights of the individuals they represent.`,
        `The Platform must not be used to deceive others into thinking that a digital persona is the real individual.`,
      ],
    },
    {
      title: 'Payments',
      content:
        'Payments are processed through secure third-party payment gateways. We do not store or manage any payment information directly; all payment data is securely handled by the payment gateway provider. The cost of paid interactions and any related fees are clearly shown in the "Wallet" section of the app.',
    },
    {
      title: 'Refunds and Cancellations',
      children: [
        'Refunds will be evaluated individually for valid reasons, such as technical issues or failures on our part.',
        <>
          If you experience any payment issues or wish to request a refund,
          please contact our support team at <SupportEmail /> or through our
          Discord server: <SimpleUrl url={process.env.REACT_APP_DISCORD!} />.
        </>,
        'We reserve the right to refuse refund requests if we suspect fraud or misuse of our services.',
      ],
    },
    {
      title: 'Non-commercial Use',
      content:
        'Digital personas on Swoons are intended for non-commercial use only, unless explicitly authorized by The Company. This means you cannot use digital personas to sell or promote products or services, or to generate any form of revenue, without explicit consent from The Company.',
    },
    {
      title: 'Entertainment Purposes',
      content:
        'Digital personas on Swoons are intended solely for entertainment purposes. They must not be used to spread misinformation, hate speech, or to harm or deceive others. By using the platform, you acknowledge that you are interacting with AI and not a real person.',
    },
    {
      title: 'Responsibility',
      content:
        'The Company is not liable for the actions or statements of any digital persona. Users should exercise caution and use their best judgment when interacting with digital personas.',
    },
    {
      title: 'Violations',
      content: `Failure to comply with this policy may lead to the suspension or termination of your account and access to Swoons' services. The Company retains the right to suspend or terminate any user at its discretion.`,
    },
    {
      title: 'Reporting',
      content: (
        <>
          If you suspect that a digital persona breaches this policy or
          infringes upon your rights, please reach out to us at <SupportEmail />{' '}
          with comprehensive details regarding the matter. We will investigate
          your report and respond accordingly.
        </>
      ),
    },
    {
      title: 'Updates to This Policy',
      content:
        'We reserve the right to revise this policy periodically. Any changes will be communicated to you by posting the updated policy on this page.',
    },

    {
      title: 'Contact Us',
      content: [
        <>
          For questions or comments about our Terms of Use, or to exercise your
          legal rights, contact us at <SupportEmail />
        </>,
      ],
    },
  ],
};

const Terms: React.FC = () => {
  return (
    <LegalRenderer
      data={data}
      lastUpdate="June 5, 2024"
      style={{ padding: '40px' }}
    />
  );
};

export default Terms;
