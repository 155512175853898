import { Form, Input, Select } from 'antd';

import { Gender } from '../../../../../api';
import { SelectAutoClose } from '../../../../../common';
import { StepProps } from '../step-props.interface';

import '../../../../../common/form.css';

const { Option } = Select;

const AICloneInformation: React.FC<StepProps> = () => {
  return (
    <>
      <Form.Item
        name="displayName"
        label="Display Name"
        rules={[
          { required: true, message: 'Display name is required' },
          {
            max: 30,
            message: 'Display name must be maximum 30 characters',
          },
        ]}
      >
        <Input placeholder="Display Name" />
      </Form.Item>

      <Form.Item
        name="handle"
        label="Handle"
        rules={[
          { required: true, message: 'Handle is required' },
          {
            max: 30,
            message: 'Handle must be maximum 30 characters',
          },
        ]}
      >
        <Input
          addonBefore="https://swoons.ai/"
          placeholder="handle"
          style={{ borderRadius: '24px' }}
        />
      </Form.Item>

      <Form.Item
        name="gender"
        label="Gender"
        rules={[{ required: true, message: 'Gender is required' }]}
      >
        <SelectAutoClose placeholder="Select gender">
          <Option value={Gender.Male}>Male</Option>
          <Option value={Gender.Female}>Female</Option>
        </SelectAutoClose>
      </Form.Item>
    </>
  );
};

export default AICloneInformation;
