import { AxiosRequestConfig } from 'axios';

export const CreateVoiceSampleTransloaditAssembliesRequest = (
  userId: string,
  assemblyIds: string[],
): AxiosRequestConfig => {
  return {
    url: `v1/creator/voice-sample/transloadit/assemblies`,
    method: 'post',
    data: { userId, assemblyIds },
  };
};
