import { useEffect, useRef, useState } from 'react';
// import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';

import { useInitStore } from '../stores';
import { createNotification, NotificationType } from './notification.utils';

export type QueryValues = { [key: string]: string | null | undefined };

export function useQueryEffect(
  keys: { [key: string]: (key: string, value?: string) => void },
  callback?: (value: QueryValues) => void,
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState<QueryValues>();
  const invokedCallback = useRef<boolean>(false);

  const result: QueryValues = {};

  useEffect(() => {
    if (invokedCallback.current) return;

    for (const [key, keyCallback] of Object.entries(keys)) {
      const value = searchParams.get(key) || undefined;

      result[key] = value;

      keyCallback(key, value);

      searchParams.delete(key);
    }

    setSearchParams(searchParams);

    setQuery(result);
    callback?.(result);

    invokedCallback.current = true;
  }, [keys, searchParams]);

  return query;
}

// load notification message from query and display it
export function useAppQueryEffect() {
  // const [, setCookie] = useCookies();

  const setForwardedToken = useInitStore((state) => state.setForwardedToken);

  const setQueryValues = useInitStore((state) => state.setQueryValues);

  /*function setQueryCookie(key: string, value?: string) {
    if (value) {
      setCookie(key, value, {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
      });
    }
  }*/

  useQueryEffect(
    {
      // load error notification message from query and display it
      error: (key: string, message?: string) => {
        if (message) {
          createNotification({
            key: 'error',
            message,
            type: NotificationType.Error,
          });
        }
      },
      // load success notification message from query and display it
      success: (key: string, message?: string) => {
        if (message) {
          createNotification({
            key: 'success',
            message,
            type: NotificationType.Success,
          });
        }
      },
      // load token and set it in store
      token: (key: string, token?: string) => {
        if (token) setForwardedToken(token);
      },
      // load source data from query and persist to cookie, attached to new users on sign up
      /*referrer: setQueryCookie,
      code: setQueryCookie,
      c: setQueryCookie,*/
    },
    setQueryValues,
  );
}
