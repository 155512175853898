import mixpanel, { Dict } from 'mixpanel-browser';

import { UserParams } from '../../api';

export function initializeMixpanel() {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!, {
    track_pageview: 'url-with-path',
    persistence: 'localStorage',
  });
}

export function setMixpanelUser(user: UserParams) {
  mixpanel.identify(user.id);

  mixpanel.people.set({
    $name: user.name,
    $email: user.email,
    gender: user.gender,
    type: user.type,
  });
}

export function clearMixpanelUser() {
  mixpanel.reset();
}

export function trackMixpanelEvent(eventName: string, properties?: Dict) {
  mixpanel.track(eventName, properties);
}
