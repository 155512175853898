import { AxiosRequestConfig } from 'axios';

import { EmailParams } from './email-params.interface';

export const UpdateEmailRequest = (params: EmailParams): AxiosRequestConfig => {
  return {
    url: 'v1/user/email',
    method: 'put',
    data: params,
  };
};
