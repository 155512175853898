import Icon from '@ant-design/icons';

import { CaretSvg, If } from '../../../../common';
import { cssObjectToString } from '../../../../utils';

import './faq-item.component.css';

const FAQItem: React.FC<{
  question: string;
  answer: string;
  isOpen: boolean;
  toggle: () => void;
  style?: React.CSSProperties;
}> = ({ question, answer, isOpen, toggle, style }) => {
  return (
    <div
      className={cssObjectToString({
        'faq-item-container': true,
        open: isOpen,
      })}
      style={style}
    >
      <div
        className={cssObjectToString({
          question: true,
          open: isOpen,
        })}
        onClick={toggle}
      >
        <div>{question}</div>

        <Icon
          component={CaretSvg}
          style={{ width: '24px' }}
          className={cssObjectToString({
            caret: true,
            open: isOpen,
          })}
        />
      </div>

      <If condition={isOpen}>
        <div className="answer" style={{ marginTop: '20px' }}>
          {answer}
        </div>
      </If>
    </div>
  );
};

export default FAQItem;
