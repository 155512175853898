import { AppLayout } from '../../../layouts/app/app-layout.component';
import { RouteData } from '../../../utils';
import PrivacyPolicy from './privacy-policy';
import Terms from './terms';

export const LegalRoutes: RouteData[] = [
  {
    path: 'privacy',
    title: 'Privacy Policy',
    component: PrivacyPolicy,
    layout: AppLayout,
    allowBackToMain: true,
  },
  {
    path: 'tos',
    title: 'Terms of Service',
    component: Terms,
    layout: AppLayout,
    allowBackToMain: true,
  },
];
